import { forwardRef, lazy } from 'react'
import { SchedulerProps } from './BigScheduler/types'

const BigScheduler = lazy(() => import('./BigScheduler'))

const KBScheduler = forwardRef<HTMLDivElement, SchedulerProps>((props, ref) => (
  <BigScheduler {...props} forwardedRef={ref} />
))

export default KBScheduler

// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { RoleAssignmentFindRolesByUserQueryResponse, RoleAssignmentFindRolesByUser401 } from "../models/RoleAssignmentFindRolesByUser";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type RoleAssignmentFindRolesByUserClient = typeof client<RoleAssignmentFindRolesByUserQueryResponse, RoleAssignmentFindRolesByUser401, never>;
type RoleAssignmentFindRolesByUser = {
    data: RoleAssignmentFindRolesByUserQueryResponse;
    error: RoleAssignmentFindRolesByUser401;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: RoleAssignmentFindRolesByUserQueryResponse;
    client: {
        parameters: Partial<Parameters<RoleAssignmentFindRolesByUserClient>[0]>;
        return: Awaited<ReturnType<RoleAssignmentFindRolesByUserClient>>;
    };
};
export const roleAssignmentFindRolesByUserQueryKey = () => ["api", "v5", "role-assignments"] as const;
export type RoleAssignmentFindRolesByUserQueryKey = ReturnType<typeof roleAssignmentFindRolesByUserQueryKey>;
export function roleAssignmentFindRolesByUserQueryOptions(options: RoleAssignmentFindRolesByUser["client"]["parameters"] = {}) {
    const queryKey = roleAssignmentFindRolesByUserQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<RoleAssignmentFindRolesByUser["data"], RoleAssignmentFindRolesByUser["error"]>({
                method: "get",
                url: `/api/v5/role-assignments`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取当前用户的角色列表,包括角色的权限列表
 * @link /api/v5/role-assignments
 */
export function useRoleAssignmentFindRolesByUser<TData = RoleAssignmentFindRolesByUser["response"], TQueryData = RoleAssignmentFindRolesByUser["response"], TQueryKey extends QueryKey = RoleAssignmentFindRolesByUserQueryKey>(options: {
    query?: Partial<QueryObserverOptions<RoleAssignmentFindRolesByUser["response"], RoleAssignmentFindRolesByUser["error"], TData, TQueryData, TQueryKey>>;
    client?: RoleAssignmentFindRolesByUser["client"]["parameters"];
} = {}): UseQueryResult<TData, RoleAssignmentFindRolesByUser["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? roleAssignmentFindRolesByUserQueryKey();
    const query = useQuery({
        ...roleAssignmentFindRolesByUserQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, RoleAssignmentFindRolesByUser["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const roleAssignmentFindRolesByUserSuspenseQueryKey = () => ["api", "v5", "role-assignments"] as const;
export type RoleAssignmentFindRolesByUserSuspenseQueryKey = ReturnType<typeof roleAssignmentFindRolesByUserSuspenseQueryKey>;
export function roleAssignmentFindRolesByUserSuspenseQueryOptions(options: RoleAssignmentFindRolesByUser["client"]["parameters"] = {}) {
    const queryKey = roleAssignmentFindRolesByUserSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<RoleAssignmentFindRolesByUser["data"], RoleAssignmentFindRolesByUser["error"]>({
                method: "get",
                url: `/api/v5/role-assignments`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取当前用户的角色列表,包括角色的权限列表
 * @link /api/v5/role-assignments
 */
export function useRoleAssignmentFindRolesByUserSuspense<TData = RoleAssignmentFindRolesByUser["response"], TQueryKey extends QueryKey = RoleAssignmentFindRolesByUserSuspenseQueryKey>(options: {
    query?: Partial<UseSuspenseQueryOptions<RoleAssignmentFindRolesByUser["response"], RoleAssignmentFindRolesByUser["error"], TData, TQueryKey>>;
    client?: RoleAssignmentFindRolesByUser["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, RoleAssignmentFindRolesByUser["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? roleAssignmentFindRolesByUserSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...roleAssignmentFindRolesByUserSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, RoleAssignmentFindRolesByUser["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { UserAutoCompleteQueryResponse, UserAutoCompleteQueryParams, UserAutoComplete401 } from "../models/UserAutoComplete";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type UserAutoCompleteClient = typeof client<UserAutoCompleteQueryResponse, UserAutoComplete401, never>;
type UserAutoComplete = {
    data: UserAutoCompleteQueryResponse;
    error: UserAutoComplete401;
    request: never;
    pathParams: never;
    queryParams: UserAutoCompleteQueryParams;
    headerParams: never;
    response: UserAutoCompleteQueryResponse;
    client: {
        parameters: Partial<Parameters<UserAutoCompleteClient>[0]>;
        return: Awaited<ReturnType<UserAutoCompleteClient>>;
    };
};
export const userAutoCompleteQueryKey = (params: UserAutoComplete["queryParams"]) => ["api", "v5", "users", "autocomplete", ...(params ? [params] : [])] as const;
export type UserAutoCompleteQueryKey = ReturnType<typeof userAutoCompleteQueryKey>;
export function userAutoCompleteQueryOptions(params: UserAutoComplete["queryParams"], options: UserAutoComplete["client"]["parameters"] = {}) {
    const queryKey = userAutoCompleteQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserAutoComplete["data"], UserAutoComplete["error"]>({
                method: "get",
                url: `/api/v5/users/autocomplete`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 按Email或姓名搜索用户
 * @link /api/v5/users/autocomplete
 */
export function useUserAutoComplete<TData = UserAutoComplete["response"], TQueryData = UserAutoComplete["response"], TQueryKey extends QueryKey = UserAutoCompleteQueryKey>(params: UserAutoComplete["queryParams"], options: {
    query?: Partial<QueryObserverOptions<UserAutoComplete["response"], UserAutoComplete["error"], TData, TQueryData, TQueryKey>>;
    client?: UserAutoComplete["client"]["parameters"];
} = {}): UseQueryResult<TData, UserAutoComplete["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userAutoCompleteQueryKey(params);
    const query = useQuery({
        ...userAutoCompleteQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, UserAutoComplete["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const userAutoCompleteSuspenseQueryKey = (params: UserAutoComplete["queryParams"]) => ["api", "v5", "users", "autocomplete", ...(params ? [params] : [])] as const;
export type UserAutoCompleteSuspenseQueryKey = ReturnType<typeof userAutoCompleteSuspenseQueryKey>;
export function userAutoCompleteSuspenseQueryOptions(params: UserAutoComplete["queryParams"], options: UserAutoComplete["client"]["parameters"] = {}) {
    const queryKey = userAutoCompleteSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserAutoComplete["data"], UserAutoComplete["error"]>({
                method: "get",
                url: `/api/v5/users/autocomplete`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 按Email或姓名搜索用户
 * @link /api/v5/users/autocomplete
 */
export function useUserAutoCompleteSuspense<TData = UserAutoComplete["response"], TQueryKey extends QueryKey = UserAutoCompleteSuspenseQueryKey>(params: UserAutoComplete["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<UserAutoComplete["response"], UserAutoComplete["error"], TData, TQueryKey>>;
    client?: UserAutoComplete["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, UserAutoComplete["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userAutoCompleteSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...userAutoCompleteSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, UserAutoComplete["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
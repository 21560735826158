// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceReservationAdminShowQueryResponse, ResourceReservationAdminShowPathParams, ResourceReservationAdminShow401 } from "../models/ResourceReservationAdminShow";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceReservationAdminShowClient = typeof client<ResourceReservationAdminShowQueryResponse, ResourceReservationAdminShow401, never>;
type ResourceReservationAdminShow = {
    data: ResourceReservationAdminShowQueryResponse;
    error: ResourceReservationAdminShow401;
    request: never;
    pathParams: ResourceReservationAdminShowPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationAdminShowQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationAdminShowClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationAdminShowClient>>;
    };
};
export const resourceReservationAdminShowQueryKey = (id: ResourceReservationAdminShowPathParams["id"]) => ["api", "v5", "admin", "resource-reservations", id] as const;
export type ResourceReservationAdminShowQueryKey = ReturnType<typeof resourceReservationAdminShowQueryKey>;
export function resourceReservationAdminShowQueryOptions(id: ResourceReservationAdminShowPathParams["id"], options: ResourceReservationAdminShow["client"]["parameters"] = {}) {
    const queryKey = resourceReservationAdminShowQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationAdminShow["data"], ResourceReservationAdminShow["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-reservations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 预定详情接口
 * @link /api/v5/admin/resource-reservations/:id
 */
export function useResourceReservationAdminShow<TData = ResourceReservationAdminShow["response"], TQueryData = ResourceReservationAdminShow["response"], TQueryKey extends QueryKey = ResourceReservationAdminShowQueryKey>(id: ResourceReservationAdminShowPathParams["id"], options: {
    query?: Partial<QueryObserverOptions<ResourceReservationAdminShow["response"], ResourceReservationAdminShow["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceReservationAdminShow["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceReservationAdminShow["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationAdminShowQueryKey(id);
    const query = useQuery({
        ...resourceReservationAdminShowQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceReservationAdminShow["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceReservationAdminShowSuspenseQueryKey = (id: ResourceReservationAdminShowPathParams["id"]) => ["api", "v5", "admin", "resource-reservations", id] as const;
export type ResourceReservationAdminShowSuspenseQueryKey = ReturnType<typeof resourceReservationAdminShowSuspenseQueryKey>;
export function resourceReservationAdminShowSuspenseQueryOptions(id: ResourceReservationAdminShowPathParams["id"], options: ResourceReservationAdminShow["client"]["parameters"] = {}) {
    const queryKey = resourceReservationAdminShowSuspenseQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationAdminShow["data"], ResourceReservationAdminShow["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-reservations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 预定详情接口
 * @link /api/v5/admin/resource-reservations/:id
 */
export function useResourceReservationAdminShowSuspense<TData = ResourceReservationAdminShow["response"], TQueryKey extends QueryKey = ResourceReservationAdminShowSuspenseQueryKey>(id: ResourceReservationAdminShowPathParams["id"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceReservationAdminShow["response"], ResourceReservationAdminShow["error"], TData, TQueryKey>>;
    client?: ResourceReservationAdminShow["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceReservationAdminShow["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationAdminShowSuspenseQueryKey(id);
    const query = useSuspenseQuery({
        ...resourceReservationAdminShowSuspenseQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceReservationAdminShow["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
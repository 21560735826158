// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthChooseSpacesMutationRequest, AuthChooseSpacesMutationResponse } from "../models/AuthChooseSpaces";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthChooseSpacesClient = typeof client<AuthChooseSpacesMutationResponse, never, AuthChooseSpacesMutationRequest>;
type AuthChooseSpaces = {
    data: AuthChooseSpacesMutationResponse;
    error: never;
    request: AuthChooseSpacesMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthChooseSpacesMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthChooseSpacesClient>[0]>;
        return: Awaited<ReturnType<AuthChooseSpacesClient>>;
    };
};
/**
 * @summary 获取用户的空间列表，用户选择空间后，返回用户信息和空间信息
 * @link /api/v5/auth/email/choose_spaces
 */
export function useAuthChooseSpaces(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthChooseSpaces["response"], AuthChooseSpaces["error"], {
        data?: AuthChooseSpaces["request"];
    }>;
    client?: AuthChooseSpaces["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthChooseSpaces["data"], AuthChooseSpaces["error"], AuthChooseSpaces["request"]>({
                method: "post",
                url: `/api/v5/auth/email/choose_spaces`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth", "email"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
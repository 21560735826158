import NiceModal, { useModal } from '@ebay/nice-modal-react'

import {
  ServiceItemEntity,
  useLocationFindById,
  useServiceItemFindMany,
} from '@/api'
import {
  KBFlex,
  KBImage,
  KBInput,
  KBInputNumber,
  KBModal,
  KBTable,
  KBText,
} from '@/components/atoms'
import { getRandomImageSrc } from '@/components/utils'
import { LocationAmountUnitEnum } from '@/enums/modelEnums'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'

type ServiceItemEntityIncludeValue = {
  value: number
} & ServiceItemEntity
export interface ServiceItem {
  serviceItemId: number
  quantity: number
}
;[]

export interface TotalInfo {
  serviceTypeId?: number
  resourceId?: number
  serviceItems: {
    value: number
    id: number
    price?: number
    total?: number
  }[]
}
interface AddServiceProps {
  resourceId?: number
  serviceTypeId?: number
  value?: ServiceItem[]
  onConfirm?: (totalInfo: TotalInfo) => void
}

function AddService(props: AddServiceProps) {
  const { serviceTypeId, resourceId, value, onConfirm } = props
  const modal = useModal()
  const [searchText, setSearchText] = useState<string>('')
  const totalInfoRef = useRef<{ value: number; id: number }[]>([])
  const { data: serviceItems } = useServiceItemFindMany({
    id: serviceTypeId,
  })

  const locationId = useCurrentLocationId()
  // 是否应该全局Location对象？
  const { data: location } = useLocationFindById(locationId!)
  const amountUnit =
    location?.amount_unit === LocationAmountUnitEnum.dollar ? '$' : '¥'

  const getColumns = (
    onChange: (info: {
      value: number
      id: number
      total?: number
      price?: number
    }) => void
  ) => [
    {
      title: tr('common.serviceName'),
      key: 'name',
      render(record: ServiceItemEntityIncludeValue) {
        return (
          <KBFlex gap={12}>
            <KBImage
              className="tw-h-[42px] tw-w-[42px] tw-bg-slate-100"
              src={record.image ? record.image : getRandomImageSrc()}
            />
            <KBFlex vertical gap={4}>
              <KBText weight="bold">{record.name}</KBText>
              <KBText
                level={3}
                className="tw-text-[var(--wox-color-text-description)]"
              >
                {tr('[intl]单价')}:{amountUnit}
                {record.price}
              </KBText>
            </KBFlex>
          </KBFlex>
        )
      },
    },
    {
      title: tr('common.quantity'),
      key: 'age',
      render(record: ServiceItemEntityIncludeValue) {
        return (
          <KBInputNumber
            min={0}
            defaultValue={record.value || 0}
            onChange={(value) =>
              onChange({
                value: value as number,
                id: record.id,
                price: Number(record.price),
                total: Number(value) * Number(record.price),
              })
            }
          />
        )
      },
    },
  ]

  const serviceListData = useMemo(() => {
    let arr = []
    if (searchText) {
      arr =
        serviceItems?.result.filter((serviceItem: ServiceItemEntity) =>
          searchText
            ? serviceItem.name
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            : true
        ) || []
    } else {
      arr = serviceItems?.result || []
    }

    return arr.map((i) => ({
      ...i,
      value: value?.find((j) => j.serviceItemId === i.id)?.quantity,
    }))
  }, [searchText, serviceItems])

  function onChange(info: {
    value: number
    id: number
    total?: number
    price?: number
  }) {
    const index = totalInfoRef.current.findIndex((info) => info.id)
    if (index > -1) {
      totalInfoRef.current.splice(index, 1, info)
    } else {
      totalInfoRef.current.push(info)
    }
  }
  function onOk() {
    modal.hide()
    onConfirm?.({
      serviceTypeId,
      resourceId,
      serviceItems: totalInfoRef.current,
    })
  }

  return (
    <KBModal title={tr('[intl]添加服务')} modal={modal} onOk={onOk}>
      <KBInput
        placeholder={tr('[intl]搜索服务')}
        className="tw-mb-5"
        onChange={(event) => setSearchText(event.target.value)}
      />
      <KBTable columns={getColumns(onChange)} dataSource={serviceListData} />
    </KBModal>
  )
}

const AddServiceModal = NiceModal.create(AddService)

export default AddServiceModal

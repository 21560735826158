// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceAdminFindServiceTypesQueryResponse, ResourceAdminFindServiceTypesQueryParams, ResourceAdminFindServiceTypes401 } from "../models/ResourceAdminFindServiceTypes";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceAdminFindServiceTypesClient = typeof client<ResourceAdminFindServiceTypesQueryResponse, ResourceAdminFindServiceTypes401, never>;
type ResourceAdminFindServiceTypes = {
    data: ResourceAdminFindServiceTypesQueryResponse;
    error: ResourceAdminFindServiceTypes401;
    request: never;
    pathParams: never;
    queryParams: ResourceAdminFindServiceTypesQueryParams;
    headerParams: never;
    response: ResourceAdminFindServiceTypesQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceAdminFindServiceTypesClient>[0]>;
        return: Awaited<ReturnType<ResourceAdminFindServiceTypesClient>>;
    };
};
export const resourceAdminFindServiceTypesQueryKey = (params: ResourceAdminFindServiceTypes["queryParams"]) => ["api", "v5", "admin", "resources", "service-types", ...(params ? [params] : [])] as const;
export type ResourceAdminFindServiceTypesQueryKey = ReturnType<typeof resourceAdminFindServiceTypesQueryKey>;
export function resourceAdminFindServiceTypesQueryOptions(params: ResourceAdminFindServiceTypes["queryParams"], options: ResourceAdminFindServiceTypes["client"]["parameters"] = {}) {
    const queryKey = resourceAdminFindServiceTypesQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceAdminFindServiceTypes["data"], ResourceAdminFindServiceTypes["error"]>({
                method: "get",
                url: `/api/v5/admin/resources/service-types`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find serviceTypes by Id
 * @link /api/v5/admin/resources/service-types
 */
export function useResourceAdminFindServiceTypes<TData = ResourceAdminFindServiceTypes["response"], TQueryData = ResourceAdminFindServiceTypes["response"], TQueryKey extends QueryKey = ResourceAdminFindServiceTypesQueryKey>(params: ResourceAdminFindServiceTypes["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceAdminFindServiceTypes["response"], ResourceAdminFindServiceTypes["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceAdminFindServiceTypes["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceAdminFindServiceTypes["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceAdminFindServiceTypesQueryKey(params);
    const query = useQuery({
        ...resourceAdminFindServiceTypesQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceAdminFindServiceTypes["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceAdminFindServiceTypesSuspenseQueryKey = (params: ResourceAdminFindServiceTypes["queryParams"]) => ["api", "v5", "admin", "resources", "service-types", ...(params ? [params] : [])] as const;
export type ResourceAdminFindServiceTypesSuspenseQueryKey = ReturnType<typeof resourceAdminFindServiceTypesSuspenseQueryKey>;
export function resourceAdminFindServiceTypesSuspenseQueryOptions(params: ResourceAdminFindServiceTypes["queryParams"], options: ResourceAdminFindServiceTypes["client"]["parameters"] = {}) {
    const queryKey = resourceAdminFindServiceTypesSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceAdminFindServiceTypes["data"], ResourceAdminFindServiceTypes["error"]>({
                method: "get",
                url: `/api/v5/admin/resources/service-types`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find serviceTypes by Id
 * @link /api/v5/admin/resources/service-types
 */
export function useResourceAdminFindServiceTypesSuspense<TData = ResourceAdminFindServiceTypes["response"], TQueryKey extends QueryKey = ResourceAdminFindServiceTypesSuspenseQueryKey>(params: ResourceAdminFindServiceTypes["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceAdminFindServiceTypes["response"], ResourceAdminFindServiceTypes["error"], TData, TQueryKey>>;
    client?: ResourceAdminFindServiceTypes["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceAdminFindServiceTypes["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceAdminFindServiceTypesSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceAdminFindServiceTypesSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceAdminFindServiceTypes["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
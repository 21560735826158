// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthLoginMutationRequest, AuthLoginMutationResponse } from "../models/AuthLogin";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthLoginClient = typeof client<AuthLoginMutationResponse, never, AuthLoginMutationRequest>;
type AuthLogin = {
    data: AuthLoginMutationResponse;
    error: never;
    request: AuthLoginMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthLoginMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthLoginClient>[0]>;
        return: Awaited<ReturnType<AuthLoginClient>>;
    };
};
/**
 * @summary Email登录，需要传入用户的Email, Password, SpaceId
 * @link /api/v5/auth/email/login
 */
export function useAuthLogin(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthLogin["response"], AuthLogin["error"], {
        data?: AuthLogin["request"];
    }>;
    client?: AuthLogin["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthLogin["data"], AuthLogin["error"], AuthLogin["request"]>({
                method: "post",
                url: `/api/v5/auth/email/login`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth", "email"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
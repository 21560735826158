// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { LocationAdminFindByIdQueryResponse, LocationAdminFindByIdPathParams, LocationAdminFindById401 } from "../models/LocationAdminFindById";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type LocationAdminFindByIdClient = typeof client<LocationAdminFindByIdQueryResponse, LocationAdminFindById401, never>;
type LocationAdminFindById = {
    data: LocationAdminFindByIdQueryResponse;
    error: LocationAdminFindById401;
    request: never;
    pathParams: LocationAdminFindByIdPathParams;
    queryParams: never;
    headerParams: never;
    response: LocationAdminFindByIdQueryResponse;
    client: {
        parameters: Partial<Parameters<LocationAdminFindByIdClient>[0]>;
        return: Awaited<ReturnType<LocationAdminFindByIdClient>>;
    };
};
export const locationAdminFindByIdQueryKey = (id: LocationAdminFindByIdPathParams["id"]) => ["api", "v5", "admin", "locations", id] as const;
export type LocationAdminFindByIdQueryKey = ReturnType<typeof locationAdminFindByIdQueryKey>;
export function locationAdminFindByIdQueryOptions(id: LocationAdminFindByIdPathParams["id"], options: LocationAdminFindById["client"]["parameters"] = {}) {
    const queryKey = locationAdminFindByIdQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<LocationAdminFindById["data"], LocationAdminFindById["error"]>({
                method: "get",
                url: `/api/v5/admin/locations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find FullLocationEntity by Id
 * @link /api/v5/admin/locations/:id
 */
export function useLocationAdminFindById<TData = LocationAdminFindById["response"], TQueryData = LocationAdminFindById["response"], TQueryKey extends QueryKey = LocationAdminFindByIdQueryKey>(id: LocationAdminFindByIdPathParams["id"], options: {
    query?: Partial<QueryObserverOptions<LocationAdminFindById["response"], LocationAdminFindById["error"], TData, TQueryData, TQueryKey>>;
    client?: LocationAdminFindById["client"]["parameters"];
} = {}): UseQueryResult<TData, LocationAdminFindById["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? locationAdminFindByIdQueryKey(id);
    const query = useQuery({
        ...locationAdminFindByIdQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, LocationAdminFindById["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const locationAdminFindByIdSuspenseQueryKey = (id: LocationAdminFindByIdPathParams["id"]) => ["api", "v5", "admin", "locations", id] as const;
export type LocationAdminFindByIdSuspenseQueryKey = ReturnType<typeof locationAdminFindByIdSuspenseQueryKey>;
export function locationAdminFindByIdSuspenseQueryOptions(id: LocationAdminFindByIdPathParams["id"], options: LocationAdminFindById["client"]["parameters"] = {}) {
    const queryKey = locationAdminFindByIdSuspenseQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<LocationAdminFindById["data"], LocationAdminFindById["error"]>({
                method: "get",
                url: `/api/v5/admin/locations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find FullLocationEntity by Id
 * @link /api/v5/admin/locations/:id
 */
export function useLocationAdminFindByIdSuspense<TData = LocationAdminFindById["response"], TQueryKey extends QueryKey = LocationAdminFindByIdSuspenseQueryKey>(id: LocationAdminFindByIdPathParams["id"], options: {
    query?: Partial<UseSuspenseQueryOptions<LocationAdminFindById["response"], LocationAdminFindById["error"], TData, TQueryKey>>;
    client?: LocationAdminFindById["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, LocationAdminFindById["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? locationAdminFindByIdSuspenseQueryKey(id);
    const query = useSuspenseQuery({
        ...locationAdminFindByIdSuspenseQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, LocationAdminFindById["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceTypeAdminFindManyQueryResponse, ResourceTypeAdminFindManyQueryParams, ResourceTypeAdminFindMany401 } from "../models/ResourceTypeAdminFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceTypeAdminFindManyClient = typeof client<ResourceTypeAdminFindManyQueryResponse, ResourceTypeAdminFindMany401, never>;
type ResourceTypeAdminFindMany = {
    data: ResourceTypeAdminFindManyQueryResponse;
    error: ResourceTypeAdminFindMany401;
    request: never;
    pathParams: never;
    queryParams: ResourceTypeAdminFindManyQueryParams;
    headerParams: never;
    response: ResourceTypeAdminFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceTypeAdminFindManyClient>[0]>;
        return: Awaited<ReturnType<ResourceTypeAdminFindManyClient>>;
    };
};
export const resourceTypeAdminFindManyQueryKey = (params?: ResourceTypeAdminFindMany["queryParams"]) => ["api", "v5", "admin", "resource-types", "list", ...(params ? [params] : [])] as const;
export type ResourceTypeAdminFindManyQueryKey = ReturnType<typeof resourceTypeAdminFindManyQueryKey>;
export function resourceTypeAdminFindManyQueryOptions(params?: ResourceTypeAdminFindMany["queryParams"], options: ResourceTypeAdminFindMany["client"]["parameters"] = {}) {
    const queryKey = resourceTypeAdminFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceTypeAdminFindMany["data"], ResourceTypeAdminFindMany["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-types/list`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceTypeCustomFieldsEntity with query
 * @link /api/v5/admin/resource-types/list
 */
export function useResourceTypeAdminFindMany<TData = ResourceTypeAdminFindMany["response"], TQueryData = ResourceTypeAdminFindMany["response"], TQueryKey extends QueryKey = ResourceTypeAdminFindManyQueryKey>(params?: ResourceTypeAdminFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceTypeAdminFindMany["response"], ResourceTypeAdminFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceTypeAdminFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceTypeAdminFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceTypeAdminFindManyQueryKey(params);
    const query = useQuery({
        ...resourceTypeAdminFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceTypeAdminFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceTypeAdminFindManySuspenseQueryKey = (params?: ResourceTypeAdminFindMany["queryParams"]) => ["api", "v5", "admin", "resource-types", "list", ...(params ? [params] : [])] as const;
export type ResourceTypeAdminFindManySuspenseQueryKey = ReturnType<typeof resourceTypeAdminFindManySuspenseQueryKey>;
export function resourceTypeAdminFindManySuspenseQueryOptions(params?: ResourceTypeAdminFindMany["queryParams"], options: ResourceTypeAdminFindMany["client"]["parameters"] = {}) {
    const queryKey = resourceTypeAdminFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceTypeAdminFindMany["data"], ResourceTypeAdminFindMany["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-types/list`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceTypeCustomFieldsEntity with query
 * @link /api/v5/admin/resource-types/list
 */
export function useResourceTypeAdminFindManySuspense<TData = ResourceTypeAdminFindMany["response"], TQueryKey extends QueryKey = ResourceTypeAdminFindManySuspenseQueryKey>(params?: ResourceTypeAdminFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceTypeAdminFindMany["response"], ResourceTypeAdminFindMany["error"], TData, TQueryKey>>;
    client?: ResourceTypeAdminFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceTypeAdminFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceTypeAdminFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceTypeAdminFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceTypeAdminFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export function escapeHtml(unsafe?: string): string {
  if (unsafe) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  }
  return ''
}

/**
 * Checks if a given string is a valid email address.
 * Trims the input string before validation.
 * @param email - The string to check.
 * @returns `true` if the string is a valid email address, otherwise `false`.
 */
export function isValidEmail(email: string): boolean {
  // Trim the email string
  const trimmedEmail = email.trim()

  // Regular expression to validate email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return emailRegex.test(trimmedEmail)
}

export function isValidUrl(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}

/**
 * Checks if a given string is a valid IP address, or a range of IP addresses.
 *
 * @example
 * isValidIpAddress('192.168.1.1') // true
 * isValidIpAddress('192.168.1.1/24') // true
 * isValidIpAddress('192.168.1.1/32') // true
 * isValidIpAddress('192.168.1.1/33') // false
 * isValidIpAddress('192.168.1.1/0') // false
 * isValidIpAddress('192.168.1.1/32') // true
 * isValidIpAddress('192.168.1.1/32') // true
 * isValidIpAddress('192.168.1.1/32') // true
 * @param ip - The string to check.
 * @returns `true` if the string is a valid IP address, otherwise `false`.
 */
export function isValidIpAddress(ip: string): boolean {
  const regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

  // add ip address range
  const ipRanges = ip.split('/')
  if (ipRanges.length !== 2) {
    return regex.test(ip)
  }
  const ipAddress = ipRanges[0]
  const ipRange = parseInt(ipRanges[1])

  if (ipRange < 0 || ipRange > 32) {
    return false
  }

  return regex.test(ipAddress)
}

// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationCreateMutationRequest, ResourceReservationCreateMutationResponse, ResourceReservationCreate401 } from "../models/ResourceReservationCreate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationCreateClient = typeof client<ResourceReservationCreateMutationResponse, ResourceReservationCreate401, ResourceReservationCreateMutationRequest>;
type ResourceReservationCreate = {
    data: ResourceReservationCreateMutationResponse;
    error: ResourceReservationCreate401;
    request: ResourceReservationCreateMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationCreateMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationCreateClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationCreateClient>>;
    };
};
/**
 * @summary 创建单个或者集合预定，根据resource_ids传值来判断，如果是多个值的情况下表示集合预定
 * @link /api/v5/resource-reservations
 */
export function useResourceReservationCreate(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationCreate["response"], ResourceReservationCreate["error"], {
        data?: ResourceReservationCreate["request"];
    }>;
    client?: ResourceReservationCreate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<ResourceReservationCreate["data"], ResourceReservationCreate["error"], ResourceReservationCreate["request"]>({
                method: "post",
                url: `/api/v5/resource-reservations`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "resource-reservations"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceReservationShowQueryResponse, ResourceReservationShowPathParams, ResourceReservationShow401 } from "../models/ResourceReservationShow";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceReservationShowClient = typeof client<ResourceReservationShowQueryResponse, ResourceReservationShow401, never>;
type ResourceReservationShow = {
    data: ResourceReservationShowQueryResponse;
    error: ResourceReservationShow401;
    request: never;
    pathParams: ResourceReservationShowPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationShowQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationShowClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationShowClient>>;
    };
};
export const resourceReservationShowQueryKey = (id: ResourceReservationShowPathParams["id"]) => ["api", "v5", "resource-reservations", id] as const;
export type ResourceReservationShowQueryKey = ReturnType<typeof resourceReservationShowQueryKey>;
export function resourceReservationShowQueryOptions(id: ResourceReservationShowPathParams["id"], options: ResourceReservationShow["client"]["parameters"] = {}) {
    const queryKey = resourceReservationShowQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationShow["data"], ResourceReservationShow["error"]>({
                method: "get",
                url: `/api/v5/resource-reservations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 预定详情
 * @link /api/v5/resource-reservations/:id
 */
export function useResourceReservationShow<TData = ResourceReservationShow["response"], TQueryData = ResourceReservationShow["response"], TQueryKey extends QueryKey = ResourceReservationShowQueryKey>(id: ResourceReservationShowPathParams["id"], options: {
    query?: Partial<QueryObserverOptions<ResourceReservationShow["response"], ResourceReservationShow["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceReservationShow["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceReservationShow["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationShowQueryKey(id);
    const query = useQuery({
        ...resourceReservationShowQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceReservationShow["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceReservationShowSuspenseQueryKey = (id: ResourceReservationShowPathParams["id"]) => ["api", "v5", "resource-reservations", id] as const;
export type ResourceReservationShowSuspenseQueryKey = ReturnType<typeof resourceReservationShowSuspenseQueryKey>;
export function resourceReservationShowSuspenseQueryOptions(id: ResourceReservationShowPathParams["id"], options: ResourceReservationShow["client"]["parameters"] = {}) {
    const queryKey = resourceReservationShowSuspenseQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationShow["data"], ResourceReservationShow["error"]>({
                method: "get",
                url: `/api/v5/resource-reservations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 预定详情
 * @link /api/v5/resource-reservations/:id
 */
export function useResourceReservationShowSuspense<TData = ResourceReservationShow["response"], TQueryKey extends QueryKey = ResourceReservationShowSuspenseQueryKey>(id: ResourceReservationShowPathParams["id"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceReservationShow["response"], ResourceReservationShow["error"], TData, TQueryKey>>;
    client?: ResourceReservationShow["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceReservationShow["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationShowSuspenseQueryKey(id);
    const query = useSuspenseQuery({
        ...resourceReservationShowSuspenseQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceReservationShow["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
import { FeatureNameEnum } from '@/enums/modelEnums'
import { subscribedFeatureAtom } from '@/store'
import { useAtom } from 'jotai'
import { ReactNode } from 'react'

interface KBSubscribedFeatureProps {
  fallback?: ReactNode
  children?: ReactNode
  featureName: FeatureNameEnum
}

const KBSubscribedFeature = (props: KBSubscribedFeatureProps) => {
  const { fallback, children, featureName } = props

  const [hasSubscribed] = useAtom(subscribedFeatureAtom)

  if (hasSubscribed(featureName)) {
    return <>{children}</>
  }

  return <>{fallback}</>
}

export default KBSubscribedFeature

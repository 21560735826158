import { Table, TableProps } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { useSpinDelay } from 'spin-delay'

import { KBSkeleton, KBSkeletonProps } from '@/components/atoms/KBSkeleton'

interface CustomColumnType<T> extends ColumnType<T> {
  kBSkeletonProps?: KBSkeletonProps
}

// eslint-disable-next-line typescriptEslint/no-explicit-any
export type CustomColumnsType<T = any> = CustomColumnType<T>[]

export type KBSkeletonTableColumnsType = {
  key: string
}

// eslint-disable-next-line typescriptEslint/no-explicit-any
export interface KBSkeletonTableProps<T = any> extends TableProps {
  columns: CustomColumnsType<T>
  skeletonRowCount?: number
  dataSource: T[]
}

export function KBSkeletonTable({
  skeletonRowCount = 5,
  columns,
  dataSource,
  loading,
  rowKey = 'id',
  ...otherProps
}: KBSkeletonTableProps) {
  const showSpinner = useSpinDelay(!!loading, {
    delay: 500,
    minDuration: 200,
  })
  // console.log('showSpinner', showSpinner, loading)

  const processedColumns = columns.map((column) => {
    if (column?.kBSkeletonProps) {
      return column
    } else {
      return {
        ...column,
        kBSkeletonProps: { active: true, paragraph: false, title: true },
      }
    }
  })
  return showSpinner ? (
    <Table
      rowKey={rowKey}
      pagination={false}
      dataSource={[...Array(skeletonRowCount)].map((_, index) => ({
        id: `key${index}`,
      }))}
      columns={processedColumns.map((processedColumn) => {
        return {
          ...processedColumn,
          render: function renderPlaceholder() {
            return (
              <KBSkeleton
                key={processedColumn.key}
                {...processedColumn.kBSkeletonProps}
              />
            )
          },
        }
      })}
    />
  ) : (
    <Table
      columns={columns}
      rowKey={rowKey}
      dataSource={dataSource}
      pagination={false}
      {...otherProps}
    />
  )
}

import type { ProgressProps } from 'antd'
import { Progress } from 'antd'
import { useEffect, useState } from 'react'

export interface KBProgressLoadingProps extends ProgressProps {
  complete?: false
  onComplete?: () => {}
}

export const KBProgressLoading = ({
  complete,
  onComplete,
  ...restProps
}: KBProgressLoadingProps) => {
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    if (complete) {
      setPercent(100)
      onComplete &&
        setTimeout(() => {
          onComplete?.()
        }, 200)
      return
    }

    let interval: NodeJS.Timeout
    let increment = 10

    const updateProgress = () => {
      setPercent((prevPercent) => {
        if (prevPercent >= 99) {
          return prevPercent // Cap the progress at 99%
        }
        const newPercent = prevPercent + increment
        increment = increment * 0.9 // Decrease increment to slow down progress
        return newPercent
      })
    }

    interval = setInterval(updateProgress, 200)

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [complete, onComplete])

  return (
    <>
      <Progress
        strokeColor={{
          from: '#108ee9',
          to: '#87d068',
        }}
        status="active"
        showInfo={false}
        percent={percent}
        {...restProps}
      />
    </>
  )
}

import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

export function initSentry() {
  import.meta.env.VITE_NODE_ENV === 'production' &&
    import.meta.env.VITE_SENTRY_DSN &&
    import.meta.env.VITE_SENTRY_PROJECT &&
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration(),
      ],
      beforeSend: (event, hint) => {
        // eslint-disable-next-line typescriptEslint/no-explicit-any
        const { response } = hint.originalException as any

        const status = response && response.status && response.status
        // link: https://stackoverflow.com/questions/55846690/how-to-globally-ignore-errors-with-sentry-v5-to-reduce-noise
        // If the request returns a 422 status code, we want to discard the event
        if ([422, 401, 403].includes(status)) {
          return null
        }

        return event
      },
      ignoreErrors: [
        // 'Non-Error promise rejection captured',
        // 'ResizeObserver loop limit exceeded',
        // 'Large Render Blocking Asset',
        // 'UnhandledRejection: Object captured as promise rejection with keys: code, config, message, name, request',
        // 'ResizeObserver loop completed with undelivered notifications.',
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

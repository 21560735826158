import { Avatar, AvatarProps } from 'antd'

import { cn } from '@/utils/tailwind.helper'

export interface KBAvatarProps extends AvatarProps {
  width?: number
}

export function KBAvatar(props: KBAvatarProps) {
  const { width, size = 'default', className, ...otherProps } = props
  return (
    <Avatar
      className={cn(
        'tw-shrink-0 tw-cursor-pointer tw-object-contain',
        className
      )}
      size={width || size}
      {...otherProps}
    ></Avatar>
  )
}

KBAvatar.Group = Avatar.Group

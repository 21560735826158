import {
  LoginResponseDto,
  useAuthMe,
  useRoleAssignmentFindRolesByUser,
} from '@/api'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import {
  currentRefreshTokenAtom,
  currentRolesAtom,
  currentSessionTokenAtom,
  currentSpaceAtom,
  currentUserDataAtom,
  currentUserTokenAtom,
} from '@/store'
import { setCurrentUserToken } from '@/utils/axios'
import { useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const AuthenticatedLayout: React.FC = () => {
  const setUserToken = useSetAtom(currentUserTokenAtom)
  const setRefreshToken = useSetAtom(currentRefreshTokenAtom)
  const setSessionToken = useSetAtom(currentSessionTokenAtom)
  const setCurrentSpace = useSetAtom(currentSpaceAtom)
  const setCurrentUserData = useSetAtom(currentUserDataAtom)
  const setCurrentUserRoles = useSetAtom(currentRolesAtom)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const {
    data: _userRoles,
    isLoading: isLoadingRoles,
    refetch: fetchRolesByUser,
  } = useRoleAssignmentFindRolesByUser({
    query: {
      enabled: false,
    },
  })

  const navigate = useNavigate()
  const { mutate: fetchMe, error, isPending } = useAuthMe()

  // fetch user data and roles every time the component is mounted
  useEffect(() => {
    fetchMe(
      {},
      {
        onSuccess: async (data: LoginResponseDto) => {
          // console.log(data)
          if (!data.user) {
            setIsAuthenticated(false)
            navigate('/login?error=invalid_user')
            return
          }
          setUserToken(data.token)
          setRefreshToken(data.refreshToken)
          setSessionToken(data.sessionToken)
          setCurrentSpace(data?.user?.space)
          setCurrentUserData(data?.user || {})

          setCurrentUserToken(data.token)
          // fetch user roles and store them
          const { data: userRoles } = await fetchRolesByUser()
          // console.log(`userRoles`, userRoles)

          setCurrentUserRoles(userRoles || [])
          setIsAuthenticated(true)
        },
        onError: () => {
          setIsAuthenticated(false)
          navigate(
            `/login?error=invalid_token&description=${encodeURIComponent(
              error?.message || 'Invalid token'
            )}`
          )
        },
      }
    )
  }, [])

  if (isPending || isLoadingRoles) {
    return <KBSuspenseLoading />
  }

  return isAuthenticated ? <Outlet /> : null
}

export default AuthenticatedLayout

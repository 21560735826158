// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { UserAdminFindManyQueryResponse, UserAdminFindManyQueryParams, UserAdminFindMany401 } from "../models/UserAdminFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type UserAdminFindManyClient = typeof client<UserAdminFindManyQueryResponse, UserAdminFindMany401, never>;
type UserAdminFindMany = {
    data: UserAdminFindManyQueryResponse;
    error: UserAdminFindMany401;
    request: never;
    pathParams: never;
    queryParams: UserAdminFindManyQueryParams;
    headerParams: never;
    response: UserAdminFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<UserAdminFindManyClient>[0]>;
        return: Awaited<ReturnType<UserAdminFindManyClient>>;
    };
};
export const userAdminFindManyQueryKey = (params?: UserAdminFindMany["queryParams"]) => ["api", "v5", "admin", "users", ...(params ? [params] : [])] as const;
export type UserAdminFindManyQueryKey = ReturnType<typeof userAdminFindManyQueryKey>;
export function userAdminFindManyQueryOptions(params?: UserAdminFindMany["queryParams"], options: UserAdminFindMany["client"]["parameters"] = {}) {
    const queryKey = userAdminFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserAdminFindMany["data"], UserAdminFindMany["error"]>({
                method: "get",
                url: `/api/v5/admin/users`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many UserEntity with query
 * @link /api/v5/admin/users
 */
export function useUserAdminFindMany<TData = UserAdminFindMany["response"], TQueryData = UserAdminFindMany["response"], TQueryKey extends QueryKey = UserAdminFindManyQueryKey>(params?: UserAdminFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<UserAdminFindMany["response"], UserAdminFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: UserAdminFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, UserAdminFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userAdminFindManyQueryKey(params);
    const query = useQuery({
        ...userAdminFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, UserAdminFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const userAdminFindManySuspenseQueryKey = (params?: UserAdminFindMany["queryParams"]) => ["api", "v5", "admin", "users", ...(params ? [params] : [])] as const;
export type UserAdminFindManySuspenseQueryKey = ReturnType<typeof userAdminFindManySuspenseQueryKey>;
export function userAdminFindManySuspenseQueryOptions(params?: UserAdminFindMany["queryParams"], options: UserAdminFindMany["client"]["parameters"] = {}) {
    const queryKey = userAdminFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserAdminFindMany["data"], UserAdminFindMany["error"]>({
                method: "get",
                url: `/api/v5/admin/users`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many UserEntity with query
 * @link /api/v5/admin/users
 */
export function useUserAdminFindManySuspense<TData = UserAdminFindMany["response"], TQueryKey extends QueryKey = UserAdminFindManySuspenseQueryKey>(params?: UserAdminFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<UserAdminFindMany["response"], UserAdminFindMany["error"], TData, TQueryKey>>;
    client?: UserAdminFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, UserAdminFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userAdminFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...userAdminFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, UserAdminFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
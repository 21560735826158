import { Typography } from 'antd'
import type { TitleProps } from 'antd/es/typography/Title'
import { cva } from 'class-variance-authority'
import { clsx } from 'clsx'
import { forwardRef, Fragment, useMemo } from 'react'

import { KBText, KBTextProps } from '../KBText'

export const KBTitleLevelConfig = {
  1: 'tw-text-4xl',
  2: 'tw-text-3xl',
  3: 'tw-text-2xl',
  4: 'tw-text-xl',
  5: 'tw-text-lg',
}

export type KBTitleLevels = keyof typeof KBTitleLevelConfig | 6 | 7 | 8

// @ts-ignore
export interface KBTitleProps extends TitleProps {
  /**
   * 控制字体大小
   */
  level?: KBTitleLevels
}

const KBTitleClass = cva('tw-font-semibold tw-my-0', {
  variants: {
    level: KBTitleLevelConfig,
  },
})

/**
 * KBTitle component that wraps Ant Design's Typography.Title
 * @param {KBTitleProps} props - The props passed to the KBTitle component.
 */
const KBTitle = forwardRef<HTMLHeadingElement, KBTitleProps>((props, ref) => {
  const { level = 1, children, className, ...otherProps } = props
  const KBTextLevel = useMemo(
    () => (level - 5) as KBTextProps['level'],
    [level]
  )
  return (
    <Fragment>
      {level <= 5 ? (
        <Typography.Title
          ref={ref}
          level={level as keyof typeof KBTitleLevelConfig}
          className={clsx(
            KBTitleClass({
              level: level as keyof typeof KBTitleLevelConfig,
            }),
            className
          )}
          {...otherProps}
        >
          {children}
        </Typography.Title>
      ) : (
        <KBText level={KBTextLevel} className={className} {...otherProps}>
          {children}
        </KBText>
      )}
    </Fragment>
  )
})

KBTitle.displayName = 'KBTitle'

export { KBTitle }

import { KBFlex, KBText } from '@/components/atoms'

export interface KBEmptyProps {
  title?: string
}

export const KBEmpty = (props: KBEmptyProps) => {
  const { title = tr('common.noData') } = props
  return (
    <KBFlex
      vertical
      justify="center"
      align="center"
      className="tw-m-auto tw-px-8 tw-py-10"
    >
      <img src="/empty/empty.svg" alt="empty" />
      <KBText level={1} weight="bold">
        {title}
      </KBText>
    </KBFlex>
  )
}

import { Alert, AlertProps } from 'antd'
import { CircleAlert, CircleCheck, CircleX, LucideInfo } from 'lucide-react'

export interface KBAlertProps extends AlertProps {}

export const KBAlertTypeIcons = {
  success: <CircleCheck size={16} />,
  info: <LucideInfo size={16} />,
  error: <CircleX size={16} />,
  warning: <CircleAlert size={16} />,
}
export function KBAlert(props: KBAlertProps) {
  return <Alert icon={props.type && KBAlertTypeIcons[props.type]} {...props} />
}

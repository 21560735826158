// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceAdminUpdateMutationRequest, ResourceAdminUpdateMutationResponse, ResourceAdminUpdatePathParams, ResourceAdminUpdate401, ResourceAdminUpdate406, ResourceAdminUpdate409 } from "../models/ResourceAdminUpdate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceAdminUpdateClient = typeof client<ResourceAdminUpdateMutationResponse, ResourceAdminUpdate401 | ResourceAdminUpdate406 | ResourceAdminUpdate409, ResourceAdminUpdateMutationRequest>;
type ResourceAdminUpdate = {
    data: ResourceAdminUpdateMutationResponse;
    error: ResourceAdminUpdate401 | ResourceAdminUpdate406 | ResourceAdminUpdate409;
    request: ResourceAdminUpdateMutationRequest;
    pathParams: ResourceAdminUpdatePathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceAdminUpdateMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceAdminUpdateClient>[0]>;
        return: Awaited<ReturnType<ResourceAdminUpdateClient>>;
    };
};
/**
 * @summary update unique ResourceEntity
 * @link /api/v5/admin/resources/:id
 */
export function useResourceAdminUpdate(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceAdminUpdate["response"], ResourceAdminUpdate["error"], {
        id: ResourceAdminUpdatePathParams["id"];
        data?: ResourceAdminUpdate["request"];
    }>;
    client?: ResourceAdminUpdate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<ResourceAdminUpdate["data"], ResourceAdminUpdate["error"], ResourceAdminUpdate["request"]>({
                method: "patch",
                url: `/api/v5/admin/resources/${id}`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "resources"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
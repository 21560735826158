import {
  KBButton,
  KBDropdown,
  KBDropdownProps,
  KBIcon,
} from '@/components/atoms'
import {
  getRecurrenceIntervalDescription,
  getRecurrenceIntervalOptions,
  RecurrenceByMonthInterval,
  RecurrenceInterval,
} from '@/components/organisms/KBRecurrenceEditModal/constants'
import { RecurrenceInfo } from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import NiceModal from '@ebay/nice-modal-react'
import dayjs from '@wox/dayjs'
import { FunctionComponent } from 'react'

interface KBRecurrenceDropdownProps extends Omit<KBDropdownProps, 'menu'> {
  disabled?: boolean
  value?: RecurrenceInfo
  onChange?: (recurrenceInfo?: RecurrenceInfo) => void
}

const KBRecurrenceDropdown: FunctionComponent<KBRecurrenceDropdownProps> = (
  props
) => {
  const { value, disabled, onChange, ...restProps } = props

  const onChangeRecurrence = (key: RecurrenceInterval) => {
    if (key !== RecurrenceInterval.noRecurrence) {
      let defaultRecurrenceInfo = value
      if (key === RecurrenceInterval.week) {
        defaultRecurrenceInfo = {
          interval: 1,
          selectedWeekdays: [dayjs().weekday()],
          startDate: dayjs(),
          endDate: dayjs().startOf('month').add(1, 'month'),
          ...defaultRecurrenceInfo,
          intervalType: key,
        }
      } else if (key === RecurrenceInterval.month) {
        defaultRecurrenceInfo = {
          interval: 1,
          monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
          monthDay: dayjs().date(),
          monthWeekNumber: dayjs().isoWeek(),
          monthWeekDay: dayjs().weekday(),
          startDate: dayjs(),
          endDate: dayjs().startOf('month').add(1, 'month'),
          ...defaultRecurrenceInfo,
          intervalType: key,
        }
      }
      NiceModal.show('KBRecurrenceEditModal', {
        intervalType: key,
        onConfirm: (info: RecurrenceInfo) => {
          onChange?.(info)
        },
        recurrenceInfo: defaultRecurrenceInfo,
      })
    } else {
      // 不重复预定
      onChange?.(undefined)
    }
  }

  return (
    <KBDropdown
      menu={{
        items: getRecurrenceIntervalOptions(),
        onClick: (e) => {
          const key = +e.key as RecurrenceInterval
          onChangeRecurrence(key)
        },
      }}
      placement="bottom"
      trigger={['click']}
      disabled={disabled}
      {...restProps}
    >
      <KBButton icon={<KBIcon name="repeat" />}>
        {getRecurrenceIntervalDescription(value?.intervalType)}
      </KBButton>
    </KBDropdown>
  )
}

export default KBRecurrenceDropdown

// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { SpaceFeatureFindManyQueryResponse, SpaceFeatureFindManyQueryParams, SpaceFeatureFindMany401 } from "../models/SpaceFeatureFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type SpaceFeatureFindManyClient = typeof client<SpaceFeatureFindManyQueryResponse, SpaceFeatureFindMany401, never>;
type SpaceFeatureFindMany = {
    data: SpaceFeatureFindManyQueryResponse;
    error: SpaceFeatureFindMany401;
    request: never;
    pathParams: never;
    queryParams: SpaceFeatureFindManyQueryParams;
    headerParams: never;
    response: SpaceFeatureFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<SpaceFeatureFindManyClient>[0]>;
        return: Awaited<ReturnType<SpaceFeatureFindManyClient>>;
    };
};
export const spaceFeatureFindManyQueryKey = (params?: SpaceFeatureFindMany["queryParams"]) => ["api", "v5", "space-features", ...(params ? [params] : [])] as const;
export type SpaceFeatureFindManyQueryKey = ReturnType<typeof spaceFeatureFindManyQueryKey>;
export function spaceFeatureFindManyQueryOptions(params?: SpaceFeatureFindMany["queryParams"], options: SpaceFeatureFindMany["client"]["parameters"] = {}) {
    const queryKey = spaceFeatureFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<SpaceFeatureFindMany["data"], SpaceFeatureFindMany["error"]>({
                method: "get",
                url: `/api/v5/space-features`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取SpaceFeatureEntity分页, 传入limit=1000获取所有SpaceFeatureEntity
 * @link /api/v5/space-features
 */
export function useSpaceFeatureFindMany<TData = SpaceFeatureFindMany["response"], TQueryData = SpaceFeatureFindMany["response"], TQueryKey extends QueryKey = SpaceFeatureFindManyQueryKey>(params?: SpaceFeatureFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<SpaceFeatureFindMany["response"], SpaceFeatureFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: SpaceFeatureFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, SpaceFeatureFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? spaceFeatureFindManyQueryKey(params);
    const query = useQuery({
        ...spaceFeatureFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, SpaceFeatureFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const spaceFeatureFindManySuspenseQueryKey = (params?: SpaceFeatureFindMany["queryParams"]) => ["api", "v5", "space-features", ...(params ? [params] : [])] as const;
export type SpaceFeatureFindManySuspenseQueryKey = ReturnType<typeof spaceFeatureFindManySuspenseQueryKey>;
export function spaceFeatureFindManySuspenseQueryOptions(params?: SpaceFeatureFindMany["queryParams"], options: SpaceFeatureFindMany["client"]["parameters"] = {}) {
    const queryKey = spaceFeatureFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<SpaceFeatureFindMany["data"], SpaceFeatureFindMany["error"]>({
                method: "get",
                url: `/api/v5/space-features`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取SpaceFeatureEntity分页, 传入limit=1000获取所有SpaceFeatureEntity
 * @link /api/v5/space-features
 */
export function useSpaceFeatureFindManySuspense<TData = SpaceFeatureFindMany["response"], TQueryKey extends QueryKey = SpaceFeatureFindManySuspenseQueryKey>(params?: SpaceFeatureFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<SpaceFeatureFindMany["response"], SpaceFeatureFindMany["error"], TData, TQueryKey>>;
    client?: SpaceFeatureFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, SpaceFeatureFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? spaceFeatureFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...spaceFeatureFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, SpaceFeatureFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
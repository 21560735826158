import { QueryCache, QueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { isProdEnv } from '@/utils/constants'
// import { useLogout } from '../store';

export const useReactQueryClient = () => {
  const navigate = useNavigate()
  // const logout = useLogout()

  return React.useRef(
    new QueryClient({
      queryCache: new QueryCache({
        // eslint-disable-next-line typescriptEslint/no-explicit-any
        onError: (error: any) => {
          if (error?.resStatus === 401) {
            // logout()
            // global intercept 401 and redirect to login page?
            navigate('/login')
          }
        },
      }),
      defaultOptions: {
        queries: {
          refetchOnReconnect: true, // refetch data when reconnect
          refetchOnWindowFocus: true, // refetch data when window focus
          staleTime: 1000 * 60 * 15, // default keep data for 15 minutes
          retry: isProdEnv ? 3 : 0, // Set the number of retries to 0 in development
        },
      },
    })
  )
}

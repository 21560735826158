// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { LocationFindByIdQueryResponse, LocationFindByIdPathParams, LocationFindById401 } from "../models/LocationFindById";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type LocationFindByIdClient = typeof client<LocationFindByIdQueryResponse, LocationFindById401, never>;
type LocationFindById = {
    data: LocationFindByIdQueryResponse;
    error: LocationFindById401;
    request: never;
    pathParams: LocationFindByIdPathParams;
    queryParams: never;
    headerParams: never;
    response: LocationFindByIdQueryResponse;
    client: {
        parameters: Partial<Parameters<LocationFindByIdClient>[0]>;
        return: Awaited<ReturnType<LocationFindByIdClient>>;
    };
};
export const locationFindByIdQueryKey = (id: LocationFindByIdPathParams["id"]) => ["api", "v5", "locations", id] as const;
export type LocationFindByIdQueryKey = ReturnType<typeof locationFindByIdQueryKey>;
export function locationFindByIdQueryOptions(id: LocationFindByIdPathParams["id"], options: LocationFindById["client"]["parameters"] = {}) {
    const queryKey = locationFindByIdQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<LocationFindById["data"], LocationFindById["error"]>({
                method: "get",
                url: `/api/v5/locations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find LocationEntity by Id
 * @link /api/v5/locations/:id
 */
export function useLocationFindById<TData = LocationFindById["response"], TQueryData = LocationFindById["response"], TQueryKey extends QueryKey = LocationFindByIdQueryKey>(id: LocationFindByIdPathParams["id"], options: {
    query?: Partial<QueryObserverOptions<LocationFindById["response"], LocationFindById["error"], TData, TQueryData, TQueryKey>>;
    client?: LocationFindById["client"]["parameters"];
} = {}): UseQueryResult<TData, LocationFindById["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? locationFindByIdQueryKey(id);
    const query = useQuery({
        ...locationFindByIdQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, LocationFindById["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const locationFindByIdSuspenseQueryKey = (id: LocationFindByIdPathParams["id"]) => ["api", "v5", "locations", id] as const;
export type LocationFindByIdSuspenseQueryKey = ReturnType<typeof locationFindByIdSuspenseQueryKey>;
export function locationFindByIdSuspenseQueryOptions(id: LocationFindByIdPathParams["id"], options: LocationFindById["client"]["parameters"] = {}) {
    const queryKey = locationFindByIdSuspenseQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<LocationFindById["data"], LocationFindById["error"]>({
                method: "get",
                url: `/api/v5/locations/${id}`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find LocationEntity by Id
 * @link /api/v5/locations/:id
 */
export function useLocationFindByIdSuspense<TData = LocationFindById["response"], TQueryKey extends QueryKey = LocationFindByIdSuspenseQueryKey>(id: LocationFindByIdPathParams["id"], options: {
    query?: Partial<UseSuspenseQueryOptions<LocationFindById["response"], LocationFindById["error"], TData, TQueryKey>>;
    client?: LocationFindById["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, LocationFindById["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? locationFindByIdSuspenseQueryKey(id);
    const query = useSuspenseQuery({
        ...locationFindByIdSuspenseQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, LocationFindById["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
import { Input, InputProps } from 'antd'

import { cn } from '@/utils/tailwind.helper'

export interface KBInputProps extends InputProps {}

export function KBInput(props: KBInputProps) {
  const { size = 'large', className, ...otherProps } = props
  return (
    <Input
      size={size}
      className={cn(size === 'large' && 'tw-h-9', className)}
      {...otherProps}
    ></Input>
  )
}

KBInput.Group = Input.Group
KBInput.Search = Input.Search
KBInput.TextArea = Input.TextArea
KBInput.Password = Input.Password

// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceCategoryFindManyQueryResponse, ResourceCategoryFindManyQueryParams, ResourceCategoryFindMany401 } from "../models/ResourceCategoryFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceCategoryFindManyClient = typeof client<ResourceCategoryFindManyQueryResponse, ResourceCategoryFindMany401, never>;
type ResourceCategoryFindMany = {
    data: ResourceCategoryFindManyQueryResponse;
    error: ResourceCategoryFindMany401;
    request: never;
    pathParams: never;
    queryParams: ResourceCategoryFindManyQueryParams;
    headerParams: never;
    response: ResourceCategoryFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceCategoryFindManyClient>[0]>;
        return: Awaited<ReturnType<ResourceCategoryFindManyClient>>;
    };
};
export const resourceCategoryFindManyQueryKey = (params?: ResourceCategoryFindMany["queryParams"]) => ["api", "v5", "resource-categories", ...(params ? [params] : [])] as const;
export type ResourceCategoryFindManyQueryKey = ReturnType<typeof resourceCategoryFindManyQueryKey>;
export function resourceCategoryFindManyQueryOptions(params?: ResourceCategoryFindMany["queryParams"], options: ResourceCategoryFindMany["client"]["parameters"] = {}) {
    const queryKey = resourceCategoryFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceCategoryFindMany["data"], ResourceCategoryFindMany["error"]>({
                method: "get",
                url: `/api/v5/resource-categories`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceCategoryEntity with query
 * @link /api/v5/resource-categories
 */
export function useResourceCategoryFindMany<TData = ResourceCategoryFindMany["response"], TQueryData = ResourceCategoryFindMany["response"], TQueryKey extends QueryKey = ResourceCategoryFindManyQueryKey>(params?: ResourceCategoryFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceCategoryFindMany["response"], ResourceCategoryFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceCategoryFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceCategoryFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceCategoryFindManyQueryKey(params);
    const query = useQuery({
        ...resourceCategoryFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceCategoryFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceCategoryFindManySuspenseQueryKey = (params?: ResourceCategoryFindMany["queryParams"]) => ["api", "v5", "resource-categories", ...(params ? [params] : [])] as const;
export type ResourceCategoryFindManySuspenseQueryKey = ReturnType<typeof resourceCategoryFindManySuspenseQueryKey>;
export function resourceCategoryFindManySuspenseQueryOptions(params?: ResourceCategoryFindMany["queryParams"], options: ResourceCategoryFindMany["client"]["parameters"] = {}) {
    const queryKey = resourceCategoryFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceCategoryFindMany["data"], ResourceCategoryFindMany["error"]>({
                method: "get",
                url: `/api/v5/resource-categories`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceCategoryEntity with query
 * @link /api/v5/resource-categories
 */
export function useResourceCategoryFindManySuspense<TData = ResourceCategoryFindMany["response"], TQueryKey extends QueryKey = ResourceCategoryFindManySuspenseQueryKey>(params?: ResourceCategoryFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceCategoryFindMany["response"], ResourceCategoryFindMany["error"], TData, TQueryKey>>;
    client?: ResourceCategoryFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceCategoryFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceCategoryFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceCategoryFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceCategoryFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
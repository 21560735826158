// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceFindServiceTypesQueryResponse, ResourceFindServiceTypesQueryParams, ResourceFindServiceTypes401 } from "../models/ResourceFindServiceTypes";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceFindServiceTypesClient = typeof client<ResourceFindServiceTypesQueryResponse, ResourceFindServiceTypes401, never>;
type ResourceFindServiceTypes = {
    data: ResourceFindServiceTypesQueryResponse;
    error: ResourceFindServiceTypes401;
    request: never;
    pathParams: never;
    queryParams: ResourceFindServiceTypesQueryParams;
    headerParams: never;
    response: ResourceFindServiceTypesQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceFindServiceTypesClient>[0]>;
        return: Awaited<ReturnType<ResourceFindServiceTypesClient>>;
    };
};
export const resourceFindServiceTypesQueryKey = (params: ResourceFindServiceTypes["queryParams"]) => ["api", "v5", "resources", "service-types", ...(params ? [params] : [])] as const;
export type ResourceFindServiceTypesQueryKey = ReturnType<typeof resourceFindServiceTypesQueryKey>;
export function resourceFindServiceTypesQueryOptions(params: ResourceFindServiceTypes["queryParams"], options: ResourceFindServiceTypes["client"]["parameters"] = {}) {
    const queryKey = resourceFindServiceTypesQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceFindServiceTypes["data"], ResourceFindServiceTypes["error"]>({
                method: "get",
                url: `/api/v5/resources/service-types`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find serviceTypes by Id
 * @link /api/v5/resources/service-types
 */
export function useResourceFindServiceTypes<TData = ResourceFindServiceTypes["response"], TQueryData = ResourceFindServiceTypes["response"], TQueryKey extends QueryKey = ResourceFindServiceTypesQueryKey>(params: ResourceFindServiceTypes["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceFindServiceTypes["response"], ResourceFindServiceTypes["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceFindServiceTypes["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceFindServiceTypes["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceFindServiceTypesQueryKey(params);
    const query = useQuery({
        ...resourceFindServiceTypesQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceFindServiceTypes["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceFindServiceTypesSuspenseQueryKey = (params: ResourceFindServiceTypes["queryParams"]) => ["api", "v5", "resources", "service-types", ...(params ? [params] : [])] as const;
export type ResourceFindServiceTypesSuspenseQueryKey = ReturnType<typeof resourceFindServiceTypesSuspenseQueryKey>;
export function resourceFindServiceTypesSuspenseQueryOptions(params: ResourceFindServiceTypes["queryParams"], options: ResourceFindServiceTypes["client"]["parameters"] = {}) {
    const queryKey = resourceFindServiceTypesSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceFindServiceTypes["data"], ResourceFindServiceTypes["error"]>({
                method: "get",
                url: `/api/v5/resources/service-types`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary find serviceTypes by Id
 * @link /api/v5/resources/service-types
 */
export function useResourceFindServiceTypesSuspense<TData = ResourceFindServiceTypes["response"], TQueryKey extends QueryKey = ResourceFindServiceTypesSuspenseQueryKey>(params: ResourceFindServiceTypes["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceFindServiceTypes["response"], ResourceFindServiceTypes["error"], TData, TQueryKey>>;
    client?: ResourceFindServiceTypes["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceFindServiceTypes["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceFindServiceTypesSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceFindServiceTypesSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceFindServiceTypes["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationUpdateMutationRequest, ResourceReservationUpdateMutationResponse, ResourceReservationUpdatePathParams, ResourceReservationUpdate401, ResourceReservationUpdate406, ResourceReservationUpdate409 } from "../models/ResourceReservationUpdate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationUpdateClient = typeof client<ResourceReservationUpdateMutationResponse, ResourceReservationUpdate401 | ResourceReservationUpdate406 | ResourceReservationUpdate409, ResourceReservationUpdateMutationRequest>;
type ResourceReservationUpdate = {
    data: ResourceReservationUpdateMutationResponse;
    error: ResourceReservationUpdate401 | ResourceReservationUpdate406 | ResourceReservationUpdate409;
    request: ResourceReservationUpdateMutationRequest;
    pathParams: ResourceReservationUpdatePathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationUpdateMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationUpdateClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationUpdateClient>>;
    };
};
/**
 * @summary 更新当前预定，如果是周期性预定更新后周期性为exception，如果是集合子预定更新只支持更新主持人，如果是周期性预定不能更新为非周期性预定
 * @link /api/v5/resource-reservations/:id
 */
export function useResourceReservationUpdate(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationUpdate["response"], ResourceReservationUpdate["error"], {
        id: ResourceReservationUpdatePathParams["id"];
        data?: ResourceReservationUpdate["request"];
    }>;
    client?: ResourceReservationUpdate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<ResourceReservationUpdate["data"], ResourceReservationUpdate["error"], ResourceReservationUpdate["request"]>({
                method: "patch",
                url: `/api/v5/resource-reservations/${id}`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "resource-reservations"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
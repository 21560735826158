import { KBIcon, KBSpace, KBText, KBTooltip } from '@/components/atoms'
import { FC } from 'react'

interface ServiceTime {
  service_time: string
  required_minutes?: number
}

interface KBServiceTimeIconProps {
  serviceType?: ServiceTime
}
const KBServiceTimeIcon: FC<KBServiceTimeIconProps> = (props) => {
  const { serviceType } = props
  if (!serviceType) {
    return null
  }

  switch (serviceType?.service_time) {
    case 'before_reservation':
      return (
        <KBSpace>
          <KBTooltip title={tr('state.before_reservation')}>
            {/* 这里需要包一层，不然图标会一直反复render造成闪烁问题 */}
            <>
              <KBIcon name="circle-arrow-left" className=" tw-flex" size={18} />
            </>
          </KBTooltip>
          <KBText className="tw-text-gray-500">
            {tr('common.minutes', {
              minutes: serviceType?.required_minutes || 0,
            })}
          </KBText>
        </KBSpace>
      )
    case 'after_reservation':
      return (
        <KBSpace>
          <KBTooltip title={tr('state.after_reservation')}>
            <>
              <KBIcon name="circle-arrow-right" size={18} />
            </>
          </KBTooltip>
          <KBText className="tw-text-gray-500">
            {tr('common.minutes', {
              minutes: serviceType?.required_minutes || 0,
            })}
          </KBText>
        </KBSpace>
      )
    case 'within_reservation':
      return (
        <KBTooltip title={tr('state.within_reservation')}>
          <>
            <KBIcon name="alarm-clock" size={18} />
          </>
        </KBTooltip>
      )
    default:
      return null
  }
}

export default KBServiceTimeIcon

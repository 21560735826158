// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ServiceItemFindManyQueryResponse, ServiceItemFindManyQueryParams, ServiceItemFindMany401 } from "../models/ServiceItemFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ServiceItemFindManyClient = typeof client<ServiceItemFindManyQueryResponse, ServiceItemFindMany401, never>;
type ServiceItemFindMany = {
    data: ServiceItemFindManyQueryResponse;
    error: ServiceItemFindMany401;
    request: never;
    pathParams: never;
    queryParams: ServiceItemFindManyQueryParams;
    headerParams: never;
    response: ServiceItemFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<ServiceItemFindManyClient>[0]>;
        return: Awaited<ReturnType<ServiceItemFindManyClient>>;
    };
};
export const serviceItemFindManyQueryKey = (params?: ServiceItemFindMany["queryParams"]) => ["api", "v5", "service_items", ...(params ? [params] : [])] as const;
export type ServiceItemFindManyQueryKey = ReturnType<typeof serviceItemFindManyQueryKey>;
export function serviceItemFindManyQueryOptions(params?: ServiceItemFindMany["queryParams"], options: ServiceItemFindMany["client"]["parameters"] = {}) {
    const queryKey = serviceItemFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ServiceItemFindMany["data"], ServiceItemFindMany["error"]>({
                method: "get",
                url: `/api/v5/service_items`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取ServiceItemEntity分页, 传入limit=1000获取所有ServiceItemEntity
 * @link /api/v5/service_items
 */
export function useServiceItemFindMany<TData = ServiceItemFindMany["response"], TQueryData = ServiceItemFindMany["response"], TQueryKey extends QueryKey = ServiceItemFindManyQueryKey>(params?: ServiceItemFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ServiceItemFindMany["response"], ServiceItemFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: ServiceItemFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, ServiceItemFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? serviceItemFindManyQueryKey(params);
    const query = useQuery({
        ...serviceItemFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ServiceItemFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const serviceItemFindManySuspenseQueryKey = (params?: ServiceItemFindMany["queryParams"]) => ["api", "v5", "service_items", ...(params ? [params] : [])] as const;
export type ServiceItemFindManySuspenseQueryKey = ReturnType<typeof serviceItemFindManySuspenseQueryKey>;
export function serviceItemFindManySuspenseQueryOptions(params?: ServiceItemFindMany["queryParams"], options: ServiceItemFindMany["client"]["parameters"] = {}) {
    const queryKey = serviceItemFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ServiceItemFindMany["data"], ServiceItemFindMany["error"]>({
                method: "get",
                url: `/api/v5/service_items`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取ServiceItemEntity分页, 传入limit=1000获取所有ServiceItemEntity
 * @link /api/v5/service_items
 */
export function useServiceItemFindManySuspense<TData = ServiceItemFindMany["response"], TQueryKey extends QueryKey = ServiceItemFindManySuspenseQueryKey>(params?: ServiceItemFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ServiceItemFindMany["response"], ServiceItemFindMany["error"], TData, TQueryKey>>;
    client?: ServiceItemFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ServiceItemFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? serviceItemFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...serviceItemFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ServiceItemFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
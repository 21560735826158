import { KBTypography } from '@/components/atoms'

interface KBAppVersionProps {
  className?: string
}

const { Text } = KBTypography

export const KBAppVersion: React.FC<KBAppVersionProps> = (props) => {
  const { className } = props
  return (
    <Text type="secondary" className={`tw-mt-1 tw-text-[10px] ${className}`}>
      v {import.meta.env.PACKAGE_VERSION}
    </Text>
  )
}

export default KBAppVersion

import { FormInstance } from 'antd'
import React from 'react'

import { AuthUserEntity, useAuthForgotPassword } from '@/api'
import {
  KBButton,
  KBFlex,
  KBForm,
  KBInput,
  KBTypography,
} from '@/components/atoms'
import { kbMessage, kbModal } from '@/components/atoms/KBMessageGlobal'
import SpaceItem from '@/pages/auth/components/SpaceItem'
import { tr } from '@/utils/localization/tr'

const { Link } = KBTypography

interface LoginFormProps {
  form: FormInstance
  authUser: AuthUserEntity
  onLogin: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({ form, authUser, onLogin }) => {
  const navigate = useNavigate()
  const { mutate: forgotPassword } = useAuthForgotPassword()
  const submitForm = () => {
    // setStep(2);
    onLogin()
  }

  const handleForgotPassword = () => {
    if (!authUser.space?.id) {
      kbMessage.error(tr('login.noSpace'))
      return
    }
    forgotPassword(
      {
        data: {
          email: form.getFieldValue('email'),
          space_id: authUser.space?.id,
        },
      },
      {
        onSuccess: () => {
          kbModal.success({
            title: tr('login.forgotPasswordSuccess'),
            content: tr('login.forgotPasswordSuccessContent'),
          })
        },
      }
    )
  }

  return (
    <KBFlex vertical className="tw-w-full tw-px-4 tw-pt-4">
      <SpaceItem user={authUser} width={44} />
      <KBForm.Item
        name="email"
        label={tr('locations.quickReserve.mailbox')}
        className="tw-mt-6"
      >
        <KBInput disabled size="large" />
      </KBForm.Item>
      <KBForm.Item name="password" label={tr('login.password')} required>
        <KBInput.Password size="large" />
      </KBForm.Item>

      <Link
        onClick={() => handleForgotPassword()}
        className="tw-relative -tw-top-3 tw-text-left"
      >
        {tr('login.forgetPassword')}
      </Link>

      <KBForm.Item className="tw-mb-0 tw-mt-3">
        <KBButton
          type="primary"
          size="large"
          onClick={submitForm}
          className="tw-w-full"
        >
          {tr('login.login')}
        </KBButton>
      </KBForm.Item>
    </KBFlex>
  )
}

export default LoginForm

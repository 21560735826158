import { KBAlert, KBForm } from '@/components/atoms'

const FormTotalAlert = () => {
  return (
    <KBForm.Item>
      <KBAlert
        message={tr('[intl]请填写有效的预定信息，否则将无法提交此次预定')}
        type="error"
      />
    </KBForm.Item>
  )
}

export default FormTotalAlert

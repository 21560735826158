import { AvatarSize } from '@/components/organisms'
import { CLOUDFLARE_IMAGE_URL } from '@/utils/constants'

type AvatarType = {
  name?: string
  avatar?: string
}

const AVATAR_SIZES: Record<AvatarSize, number> = {
  small: 32,
  default: 64,
  large: 200,
}
/**
 * 获取用户的avatar url
 * @param param0
 * @returns
 */
export function getUserAvatar(avatarType?: AvatarType, size?: AvatarSize) {
  const { avatar, name } = avatarType || { name: 'Unknown' }
  const getAvatarSize = (size: AvatarSize): number => {
    if (typeof size === 'number') {
      if (size <= 32) return 32
      if (size <= 64) return 64
      return 200
    }
    return AVATAR_SIZES[size] || AVATAR_SIZES.default
  }

  const avatarSize = getAvatarSize(size || 32)

  const getCloudflareImageUrl = (url: string, size: number) => {
    return `${CLOUDFLARE_IMAGE_URL}fit=cover,width=${size},height=${size}/${url}`
  }
  return avatar
    ? getCloudflareImageUrl(avatar, avatarSize)
    : `https://ui-avatars.com/api/?name=${encodeURIComponent(name || '')}&background=random&color=#fff&font-size=0.4`
}

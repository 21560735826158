import { KBButton, KBFlex } from '@/components/atoms'
import {
  KBRecurrenceEditModalProps,
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import { nameOfDay, weekdays } from '@wox/dayjs'
import { FunctionComponent } from 'react'

interface KBRecurrenceByWeekProps extends KBRecurrenceEditModalProps {
  recurrenceInfo: RecurrenceInfo
  setRecurrenceInfo: (info: RecurrenceInfo) => void
}

const KBRecurrenceByWeek: FunctionComponent<KBRecurrenceByWeekProps> = (
  props
) => {
  const { recurrenceInfo, setRecurrenceInfo } = props

  console.log('recurrenceInfo', recurrenceInfo)
  const weekdayButtons = weekdays().map((weekday) => {
    const isSelected = recurrenceInfo?.selectedWeekdays?.includes(weekday)
    return (
      <KBButton
        className="tw-aspect-square"
        type={isSelected ? 'primary' : 'default'}
        onClick={() => {
          if (isSelected) {
            setRecurrenceInfo({
              ...recurrenceInfo,
              selectedWeekdays:
                recurrenceInfo?.selectedWeekdays?.filter(
                  (day) => day !== weekday
                ) || [],
            })
          } else {
            setRecurrenceInfo({
              ...recurrenceInfo,
              selectedWeekdays: [
                ...(recurrenceInfo?.selectedWeekdays || []),
                weekday,
              ].sort((a, b) => a - b),
            })
          }
        }}
      >
        {nameOfDay(weekday, {
          weekdayFormat: 'short',
        })}
      </KBButton>
    )
  })

  return (
    <KBFlex justify="flex-end" className="tw-mt-3" gap={6}>
      {weekdayButtons}
    </KBFlex>
  )
}

export default KBRecurrenceByWeek

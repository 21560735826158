import { SpaceFeatureEntity } from '@/api'
import { atom } from 'jotai'

/**
 * Features for current space
 */
export const featuresAtom = atom<SpaceFeatureEntity[] | undefined>(undefined)

/**
 * Check if the feature is enabled
 */
export const hasFeatureAtom = atom((get) => (featureName: string) => {
  const features = get(featuresAtom)
  return (
    features?.some(
      (feature) => feature.feature.name === featureName && feature.is_enabled
    ) ?? false
  )
})

/**
 * Check if the feature is subscribed
 */
export const subscribedFeatureAtom = atom((get) => (featureName: string) => {
  const features = get(featuresAtom)
  return (
    features?.some((feature) => feature.feature.name === featureName) ?? false
  )
})

// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorConfigAdminEnableVisitorConfigByLocationMutationRequest, VisitorConfigAdminEnableVisitorConfigByLocationMutationResponse, VisitorConfigAdminEnableVisitorConfigByLocationQueryParams, VisitorConfigAdminEnableVisitorConfigByLocation401, VisitorConfigAdminEnableVisitorConfigByLocation406, VisitorConfigAdminEnableVisitorConfigByLocation409 } from "../models/VisitorConfigAdminEnableVisitorConfigByLocation";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorConfigAdminEnableVisitorConfigByLocationClient = typeof client<VisitorConfigAdminEnableVisitorConfigByLocationMutationResponse, VisitorConfigAdminEnableVisitorConfigByLocation401 | VisitorConfigAdminEnableVisitorConfigByLocation406 | VisitorConfigAdminEnableVisitorConfigByLocation409, VisitorConfigAdminEnableVisitorConfigByLocationMutationRequest>;
type VisitorConfigAdminEnableVisitorConfigByLocation = {
    data: VisitorConfigAdminEnableVisitorConfigByLocationMutationResponse;
    error: VisitorConfigAdminEnableVisitorConfigByLocation401 | VisitorConfigAdminEnableVisitorConfigByLocation406 | VisitorConfigAdminEnableVisitorConfigByLocation409;
    request: VisitorConfigAdminEnableVisitorConfigByLocationMutationRequest;
    pathParams: never;
    queryParams: VisitorConfigAdminEnableVisitorConfigByLocationQueryParams;
    headerParams: never;
    response: VisitorConfigAdminEnableVisitorConfigByLocationMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorConfigAdminEnableVisitorConfigByLocationClient>[0]>;
        return: Awaited<ReturnType<VisitorConfigAdminEnableVisitorConfigByLocationClient>>;
    };
};
/**
 * @summary 开启访客功能
 * @link /api/v5/admin/visitor_configs/enable
 */
export function useVisitorConfigAdminEnableVisitorConfigByLocation(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorConfigAdminEnableVisitorConfigByLocation["response"], VisitorConfigAdminEnableVisitorConfigByLocation["error"], {
        params: VisitorConfigAdminEnableVisitorConfigByLocation["queryParams"];
        data?: VisitorConfigAdminEnableVisitorConfigByLocation["request"];
    }>;
    client?: VisitorConfigAdminEnableVisitorConfigByLocation["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ params, data }) => {
            const res = await client<VisitorConfigAdminEnableVisitorConfigByLocation["data"], VisitorConfigAdminEnableVisitorConfigByLocation["error"], VisitorConfigAdminEnableVisitorConfigByLocation["request"]>({
                method: "patch",
                url: `/api/v5/admin/visitor_configs/enable`,
                params,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "visitor_configs"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
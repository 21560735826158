import { DefaultOptionType, SelectProps } from 'antd/es/select'

import {
  SimpleUserEntity,
  UserEntity,
  UserFindManyQueryParams,
  useUserAdminAutoComplete,
  useUserAdminFindMany,
  useUserAutoComplete,
  useUserFindMany,
} from '@/api'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { KBSelect, KBSelectProps } from '@/components/atoms/KBSelect'
import { KBTag } from '@/components/atoms/KBTag'
import { KBUser } from '@/components/organisms/KBUser/KBUser'
import { MAX_PAGINATION_LIMIT } from '@/utils/constants'
import { isValidEmail } from '@/utils/string'
import { cn } from '@/utils/tailwind.helper'
import { isUserClient } from '@/utils/userEnvironment'
import dayjs from '@wox/dayjs'

type TagRender = SelectProps['tagRender']

export interface KBUserSelectProps extends KBSelectProps {
  usersQueryParams?: UserFindManyQueryParams
}

export function getUserSelectOptions(
  users: SimpleUserEntity[],
  mode?: 'multiple' | 'tags'
): DefaultOptionType[] {
  return users.map((user) => ({
    label: (
      <KBUser
        key={user.id}
        size={mode === 'multiple' ? 18 : 24}
        user={user}
        showTooltip={false}
      />
    ),
    value: user.id,
    user: user,
  }))
}

export const tagRender: TagRender = (props) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <KBTag
      className="tw-my-0.5 tw-h-6 tw-rounded-full tw-py-1 tw-pl-1.5 tw-pr-2 "
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {typeof label === 'string' ? label.replace('...', '') : label}
    </KBTag>
  )
}

export function KBUserSelect(props: KBUserSelectProps) {
  const {
    className,
    mode,
    remoteSearch = false,
    placeholder = 'Select a person',
    allowClear = true,
    usersQueryParams = {},
    options,
    onChange,
    ...otherProps
  } = props
  const [search, setSearch] = useState('')
  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>(
    options || []
  )

  const [selectedUsers, setSelectedUsers] = useState<SimpleUserEntity[]>([])

  // console.log('remoteSearch', search)
  const queryOptions = {
    query: {
      enabled: !remoteSearch,
    },
  }

  const remoteSearchResponse = isUserClient()
    ? useUserAutoComplete(
        {
          ...usersQueryParams,
          name: search,
        },
        {
          query: {
            enabled: false,
          },
        }
      )
    : useUserAdminAutoComplete(
        {
          ...usersQueryParams,
          name: search,
        },
        {
          query: {
            enabled: false,
          },
        }
      )

  const response = isUserClient()
    ? useUserFindMany(
        {
          ...usersQueryParams,
          limit: MAX_PAGINATION_LIMIT,
        },
        queryOptions
      )
    : useUserAdminFindMany(
        {
          ...usersQueryParams,
          limit: MAX_PAGINATION_LIMIT,
        },
        queryOptions
      )

  useEffect(() => {
    if (remoteSearch) {
      if (remoteSearchResponse.data) {
        setUserOptions(
          getUserSelectOptions(remoteSearchResponse.data || [], mode)
        )
      } else {
        setUserOptions(options || [])
      }
    }
  }, [mode, remoteSearch, remoteSearchResponse.data])

  useEffect(() => {
    if (!remoteSearch) {
      if (response.data) {
        setUserOptions(getUserSelectOptions(response.data?.result || [], mode))
      } else {
        setUserOptions(options || [])
      }
    }
  }, [mode, remoteSearch, response.data])

  const handleRemoteSearch = (value: string) => {
    setSearch(value)
    setTimeout(() => {
      remoteSearchResponse.refetch()
    }, 50)
  }

  const handleChange = (
    value: number | number[],
    option: DefaultOptionType
  ) => {
    // console.log('handle change', value, userOptions)
    const arrayValue = value ? (Array.isArray(value) ? value : [value]) : []
    const newUsers = (userOptions || [])
      .filter((option) => arrayValue.includes(option.user.id))
      .map((option) => option.user)

    setSelectedUsers(newUsers)
    onChange?.(value, option)
  }

  const handleEnterValue = (value?: string) => {
    if (!value) return false
    if (!isValidEmail(value)) {
      kbMessage.error('请输入正确的邮箱地址')
      return false
    }

    const user = {
      id: dayjs().valueOf(),
      name: value,
      email: value,
    } as SimpleUserEntity
    const option = getUserSelectOptions([user], mode)
    // console.log('option', option)
    setUserOptions((prevOptions) => [
      ...prevOptions,
      ...getUserSelectOptions([user], mode),
    ])
    setSelectedUsers((prev) => [...prev, user])
    return true
  }

  // console.log('userOptions', userOptions)
  // console.log('selectedUsers', selectedUsers)

  return (
    <KBSelect
      placeholder={placeholder}
      value={selectedUsers.map((user) => user.id)}
      showSearchInput
      searchFn={(option, searchValue) => {
        if (remoteSearch) return false
        const users: UserEntity[] = response.data?.result || []
        // local search
        const user = users.find((user) => user.id === option.value)
        return (
          user?.name?.toLocaleLowerCase().includes(searchValue) ||
          user?.email?.toLocaleLowerCase().includes(searchValue) ||
          false
        )
      }}
      remoteSearch={remoteSearch}
      remoteSearchFn={handleRemoteSearch}
      options={userOptions}
      tagRender={tagRender}
      className={cn('tw-w-[200px]', className)}
      mode={mode}
      allowClear={allowClear}
      onEnter={handleEnterValue}
      onChange={handleChange}
      // suffixIcon={null}
      {...otherProps}
    ></KBSelect>
  )
}

import type { MenuProps } from 'antd'
import { useAtom } from 'jotai'
import React from 'react'

import {
  KBButton,
  KBDropdown,
  KBDropdownProps,
  KBFlex,
} from '@/components/atoms'
import { localeAtom } from '@/store'
import { SUPPORTED_LOCALES } from '@/utils/localization/supported_locales'
import WoxIconLanguages from '~icons/wox-icons/languages'
import WoxIconLanguagesGray from '~icons/wox-icons/languagesGray'
import WoxIconLanguagesWhite from '~icons/wox-icons/languagesWhite'
/**
 * color :文字的颜色  black  white gray
 */
export interface KBLanguageDropdownProps extends KBDropdownProps {
  color?: 'black' | 'white' | 'gray'
}

export const KBLanguageDropdown: React.FC<KBLanguageDropdownProps> = ({
  color = 'black',
}) => {
  const [locale, setLocale] = useAtom(localeAtom)

  const onClick: MenuProps['onClick'] = ({ key }) => {
    const selectedLocale = SUPPORTED_LOCALES.find((lang) => lang.value === key)

    setLocale(selectedLocale?.value!)
  }

  const items: MenuProps['items'] = SUPPORTED_LOCALES.map((lang) => {
    return {
      key: lang.value,
      label: lang.name,
      ariaLabel: lang.value,
    }
  })

  const currentLocale = SUPPORTED_LOCALES.find((lang) => lang.value === locale)
  const getIcon = () => {
    switch (color) {
      case 'black':
        return <WoxIconLanguages />
      case 'white':
        return <WoxIconLanguagesWhite />
      case 'gray':
        return <WoxIconLanguagesGray />
    }
  }

  const getTextStyle = () => {
    switch (color) {
      case 'black':
        return 'tw-text-[var(--wox-color-text)] hover:tw-bg-transparent'
      case 'white':
        return 'tw-text-[var(--wox-color-bg-container)] hover:tw-bg-transparent'
      case 'gray':
        return 'tw-text-gray-500 hover:tw-bg-transparent'
    }
  }
  return (
    <KBDropdown menu={{ items, onClick }}>
      <KBButton
        type="text"
        size="large"
        className={getTextStyle()}
        aria-label="Language"
      >
        <KBFlex gap={8} align="center">
          {getIcon()}
          {currentLocale?.name}
        </KBFlex>
      </KBButton>
    </KBDropdown>
  )
}

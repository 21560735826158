import { Skeleton, SkeletonProps } from 'antd'

export interface KBSkeletonProps extends SkeletonProps {}

export function KBSkeleton(props: KBSkeletonProps) {
  return <Skeleton {...props}></Skeleton>
}
KBSkeleton.Button = Skeleton.Button
KBSkeleton.Avatar = Skeleton.Avatar
KBSkeleton.Input = Skeleton.Input
KBSkeleton.Node = Skeleton.Node

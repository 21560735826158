import { InputProps } from 'antd'
import { debounce } from 'radash'

import { KBInput } from '@/components/atoms/KBInput'
import { cn } from '@/utils/tailwind.helper'

interface KBSearchInputProps extends InputProps {}

export function KBSearchInput(props: KBSearchInputProps) {
  const { size = 'large', className, onChange, ...otherProps } = props
  return (
    <KBInput
      size={size}
      allowClear
      className={cn(size === 'large' && 'tw-h-9', className)}
      {...otherProps}
      onChange={debounce({ delay: 100 }, (e) => {
        onChange?.(e)
      })}
    ></KBInput>
  )
}

KBSearchInput.Group = KBInput.Group
KBSearchInput.Search = KBInput.Search
KBSearchInput.TextArea = KBInput.TextArea
KBSearchInput.Password = KBInput.Password

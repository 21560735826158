import { SpaceFeatureEntity, useSpaceFeatureFindMany } from '@/api'
import { featuresAtom } from '@/store'
import { MAX_PAGINATION_LIMIT } from '@/utils/constants'
import { useSetAtom } from 'jotai'
import { FC } from 'react'

/**
 *
 * @returns
 */
const Init: FC = () => {
  const setFeatures = useSetAtom(featuresAtom)
  const { data: spaceFeatures } = useSpaceFeatureFindMany({
    limit: MAX_PAGINATION_LIMIT,
  })

  useEffect(() => {
    const features = spaceFeatures?.result as SpaceFeatureEntity[]
    setFeatures(features)
  }, [spaceFeatures])

  return null
}

export default Init

import { Space } from 'antd'

import { SimpleUserEntity } from '@/api'
import { KBFlex, KBTypography } from '@/components/atoms'
import { KBAvatar } from '@/components/atoms/KBAvatar'
import { KBUserPopover } from '@/components/molecules/KBUserPopover'
import { getUserAvatar } from '@/utils/avatarUrl'
const { Text } = KBTypography
export type AvatarSize = number | 'small' | 'default' | 'large'
export interface KBUserProps {
  user?: SimpleUserEntity
  size?: AvatarSize
  showName?: boolean
  showDepartment?: boolean
  /**
   * 是否显示 tooltip, 默认为 true, 显示则鼠标 hover 时显示用户名
   */
  showTooltip?: boolean

  /**
   * 子元素
   */
  children?: React.ReactNode

  /**
   * tooltip 内容
   */
  toolTipContent?: React.ReactNode
}

export function KBUser({
  user,
  size = 32,
  showTooltip = true,
  showDepartment = false,
  showName = true,
  toolTipContent,
  children,
}: KBUserProps) {
  const element = (
    <KBFlex gap={8}>
      <KBAvatar size={size} src={getUserAvatar(user, size)}></KBAvatar>
      <KBFlex vertical>
        <KBFlex gap={4}>
          {showName && <Text className="tw-font-medium">{user?.name}</Text>}
          {showDepartment && user?.department && (
            <Text type="secondary" className="tw-font-normal">
              {user?.department?.name}
            </Text>
          )}
        </KBFlex>
        {children}
      </KBFlex>
    </KBFlex>
  )

  return (
    <Space>
      {showTooltip ? (
        <KBUserPopover user={user} toolTipContent={toolTipContent}>
          {element}
        </KBUserPopover>
      ) : (
        element
      )}
    </Space>
  )
}

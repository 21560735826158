import { Upload, UploadProps } from 'antd'

export interface KBUploadProps extends UploadProps {}

export function KBUpload(props: KBUploadProps) {
  return <Upload {...props}></Upload>
}

KBUpload.Dragger = Upload.Dragger

KBUpload.LIST_IGNORE = Upload.LIST_IGNORE

import {
  KBButton,
  KBFlex,
  KBIcon,
  KBSpace,
  KBText,
  KBTitle,
} from '@/components/atoms'
import PageContentLayout from '@/components/layouts/PageContentLayout'
import { ReactNode } from 'react'

interface KBUpgradeProps {
  title?: ReactNode
  subtitle?: string
  description?: string
  button?: ReactNode
}

const KBUpgrade = (props: KBUpgradeProps) => {
  const { title, subtitle: content, description, button } = props

  return (
    <PageContentLayout title={title}>
      <KBFlex
        vertical
        gap={24}
        className="tw-h-[calc(100vh-121px)] tw-w-full tw-pt-[120px]"
        align="center"
      >
        <img src="/billing/noData.svg" />

        <KBSpace direction="vertical" className="tw-text-center" size={4}>
          <KBTitle level={6} className="tw-font-bold">
            {content}
          </KBTitle>
          <KBText type="secondary">{description}</KBText>
        </KBSpace>

        {button ? (
          button
        ) : (
          <KBButton
            type="primary"
            onClick={() => {
              // window.location.reload()
            }}
            className="tw-mt-2"
            icon={<KBIcon name="mail" />}
          >
            {tr('common.contactUs')}
          </KBButton>
        )}
      </KBFlex>
    </PageContentLayout>
  )
}

export default KBUpgrade

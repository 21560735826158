import { ResourceEntity, useResourceAdminUpdate } from '@/api'
import { KBIcon, KBImage, KBSpace } from '@/components/atoms'
import { kbMessage, kbModal } from '@/components/atoms/KBMessageGlobal'
import { KBUploadImage } from '@/components/atoms/KBUploadImage'
import { getResourceTypeIcon } from '@/pages/admin/resources/utils/iconUtils'
import { cn } from '@/utils/tailwind.helper'
import { UploadIcon } from 'lucide-react'
import { FC } from 'react'
import styles from './index.module.css'

interface KBResourceImageProps {
  resource: Partial<ResourceEntity>
  /**
   * 禁用操作，只能查看
   */
  disabledOperate?: boolean
}

/**
 * 上传资源图片
 * @param props
 * @returns
 */
const KBResourceImage: FC<KBResourceImageProps> = (props) => {
  const { resource: resource, disabledOperate = false } = props

  const { mutate: updateResource } = useResourceAdminUpdate()

  return (
    <>
      {resource.images?.[0] ? (
        <KBImage
          width={63}
          height={42}
          className="tw-rounded-md"
          src={resource.images?.[0]}
          preview={{
            mask: (
              <KBSpace>
                <KBIcon name="eye" />
                {!disabledOperate && (
                  <KBIcon
                    name="trash-2"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      kbModal.confirm({
                        title: tr('common.confirmDelete'),
                        content: tr('common.confirmDeleteImage'),
                        okButtonProps: {
                          danger: true,
                        },
                        onOk: () => {
                          updateResource(
                            {
                              id: resource.id!,
                              data: {
                                images: [],
                              },
                            },
                            {
                              onSuccess: () => {
                                kbMessage.success(tr('common.deleteSuccess'))
                              },
                            }
                          )
                        },
                      })
                    }}
                  />
                )}
              </KBSpace>
            ),
          }}
        />
      ) : (
        <div
          className={cn(
            'tw-relative tw-h-[42px] tw-w-[63px] tw-rounded-md tw-bg-gray-100 tw-text-center ',
            styles.iconBox,
            disabledOperate
              ? null
              : 'hover:tw-bg-[var(--wox-color-bg-mask)] hover:tw-opacity-45'
          )}
        >
          <KBImage
            src={getResourceTypeIcon(resource?.resource_type?.icon)}
            className="tw-mt-2 tw-h-[26px] tw-w-[26px] tw-opacity-15"
            preview={false}
          />
          {!disabledOperate && (
            <KBUploadImage
              maxCount={1}
              crop
              aspectRatio={63 / 42}
              previewHeight={63}
              preUpload
              onChange={(value) => {
                const url = value as string
                if (url) {
                  updateResource({
                    id: resource.id!,
                    data: {
                      images: [url],
                    },
                  })
                }
              }}
            >
              <UploadIcon
                size={16}
                color="#ffffff"
                strokeWidth={1.25}
                className={cn(
                  'tw-absolute tw-left-6 tw-top-3 tw-cursor-pointer',
                  styles.uploadIcon
                )}
              />
            </KBUploadImage>
          )}
        </div>
      )}
    </>
  )
}

export default KBResourceImage

import {
  ReservationShowEntity,
  ResourceEntity,
  TReservationFormRule,
  TReservationFormRuleCloudVideoApp,
} from '@/api'
import { KBFlex, KBForm, KBIcon, KBInput, KBSelect } from '@/components/atoms'
import {
  FormValueProps,
  GroupEditType,
} from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { unique } from 'radash'

/**
 * 云会议选择
 */
export interface CloudVideoValueProps {
  joinUrl?: string
  meetingProvider?: TReservationFormRuleCloudVideoApp
}
interface CloudVideoSelectProps {
  value?: CloudVideoValueProps
  resourcesList: ResourceEntity[]
  reservationInfo?: ReservationShowEntity
  groupEditType?: GroupEditType
  errorsMessages?: string
  onChange?: (value?: CloudVideoValueProps) => void
}

const CloudVideo: React.FC<CloudVideoSelectProps> = (props) => {
  const {
    value = {},
    onChange,
    resourcesList,
    reservationInfo,
    groupEditType,
  } = props

  const onSelectChange = (val: TReservationFormRuleCloudVideoApp) => {
    onChange?.({
      joinUrl: val !== 'unknown' ? '' : value.joinUrl,
      meetingProvider: val,
    })
  }

  const onInputChange = (val: string) => {
    onChange?.({
      meetingProvider: value.meetingProvider,
      joinUrl: val,
    })
  }

  const options = useMemo(() => {
    const allApps: string[] = []
    resourcesList.forEach((i) => {
      let cloudVideoApps =
        (i.reservation_form_rule?.rules as TReservationFormRule)
          ?.cloud_video_app || []
      allApps.push(...cloudVideoApps)
    })
    const options = unique(allApps).map((i) => ({ label: i, value: i }))

    // 是否允许云会议手动输入
    const canInput = resourcesList.some(
      (i) =>
        (i.reservation_form_rule?.rules as TReservationFormRule)
          ?.connection_by_self
    )

    if (canInput) {
      options.push({ label: tr('[intl]其他'), value: 'unknown' })
    }
    return options
  }, [resourcesList])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return (
    <>
      <KBFlex align="center" gap={6}>
        <KBSelect
          value={value?.meetingProvider}
          options={options}
          className="tw-w-full"
          allowClear
          disabled={disabled}
          onChange={onSelectChange}
        />
      </KBFlex>
      <KBFlex gap={28} className="tw-mt-2">
        {value?.meetingProvider === 'unknown' && (
          <KBInput
            placeholder={tr('[intl]请输入链接')}
            disabled={disabled}
            value={value?.joinUrl}
            onChange={(e) => onInputChange(e.target.value)}
          />
        )}
      </KBFlex>
    </>
  )
}

/**
 * 云会议选择组件
 */
const CloudVideoSelect: React.FC<
  Pick<
    CloudVideoSelectProps,
    'reservationInfo' | 'resourcesList' | 'groupEditType' | 'errorsMessages'
  >
> = (props) => {
  const { resourcesList, reservationInfo, groupEditType, errorsMessages } =
    props

  /**
   * 云会议校验
   */
  const validateCloudVideo = (
    cloudVideoProviders: FormValueProps['cloudVideoProviders']
  ) => {
    if (!cloudVideoProviders) return Promise.resolve()

    const { joinUrl, meetingProvider } = cloudVideoProviders
    // 多个资源是否都有xx选项
    if (meetingProvider) {
      if (meetingProvider !== 'unknown') {
        const cannotSelectCloudVideoProviders = resourcesList.filter(
          (i) =>
            !(
              i.reservation_form_rule?.rules as TReservationFormRule
            )?.cloud_video_app?.includes(meetingProvider)
        )
        if (cannotSelectCloudVideoProviders?.length) {
          return Promise.reject(
            new Error(
              `${cannotSelectCloudVideoProviders.map((i) => i.name).join('、')}${tr('[intl]不允许选择')}${meetingProvider}`
            )
          )
        } else {
          return Promise.resolve()
        }
      } else {
        // 自定义输入--校验自定义链接必填
        const cannotInputCloudVideoProviders = resourcesList.filter(
          (i) =>
            !(i.reservation_form_rule?.rules as TReservationFormRule)
              ?.connection_by_self
        )
        if (cannotInputCloudVideoProviders?.length) {
          return Promise.reject(
            new Error(
              `${cannotInputCloudVideoProviders.map((i) => i.name).join('、')}${tr('[intl]不允许自定义云会议链接')}`
            )
          )
        } else if (joinUrl) {
          return Promise.resolve()
        } else {
          return Promise.reject(new Error(tr('[intl]请输入云会议链接')))
        }
      }
    } else {
      return Promise.resolve()
    }
  }

  const showCloudVideo = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_form_rule?.rules as TReservationFormRule
      return rules?.cloud_video_providers
    })
  }, [resourcesList])

  return showCloudVideo ? (
    <KBForm.Item
      name="cloudVideoProviders"
      label={<KBIcon name="video" size={24} />}
      dependencies={['resourceIds']}
      // rules={[
      //   {
      //     validator: (_, cloudVideoProviders) =>
      //       validateCloudVideo(cloudVideoProviders),
      //   },
      // ]}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <CloudVideo
        resourcesList={resourcesList}
        reservationInfo={reservationInfo}
        groupEditType={groupEditType}
      />
    </KBForm.Item>
  ) : null
}

export default CloudVideoSelect

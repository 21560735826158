import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import intl from 'react-intl-universal'

import {
  ILocale,
  SUPPORTED_LOCALES,
} from '../utils/localization/supported_locales'

const getDefaultLocale = () => {
  let currentLocale = intl.determineLocale({
    urlLocaleKey: 'lang',
    cookieLocaleKey: 'lang',
    localStorageLocaleKey: 'lang',
  })
  if (!SUPPORTED_LOCALES.find((x: ILocale) => x.value === currentLocale)) {
    currentLocale = 'en-US'
  }
  return currentLocale
}

export const localeAtom = atomWithStorage<string>(
  'lang',
  // default locale if not exist
  getDefaultLocale(),
  createJSONStorage(),
  {
    getOnInit: true,
  }
)

// src/components/AdminRoute.tsx
import { useAtom } from 'jotai'
import React from 'react'

import { hasAdminRoleAtom } from '@/store'
import { isAuthenticatedAtom } from '@/store/auth.atom'

interface AdminRouteProps {
  element: JSX.Element
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element }) => {
  const isAuthenticated = useAtom(isAuthenticatedAtom)
  const [hasAdminRole] = useAtom(hasAdminRoleAtom)

  return isAuthenticated && hasAdminRole ? element : <Navigate to="/login" />
}

export default AdminRoute

/**
 * 获取用户环境、这里可以做一些环境的区分，如客户端环境h5/pc，当前是用户端或者是管理端
 */

/**
 * 是否是用户端
 */
export const isUserClient = () => {
  return window.location.pathname.startsWith('/user')
}

/**
 * 是否是管理端
 */
export const isAdminClient = () => {
  return window.location.pathname.startsWith('/admin')
}

interface EnumValueOption {
  /**
   * @description  The order position of each enum on the page
   */

  position?: number

  /**
   * @description  Count of enum
   */

  num?: number

  /**
   * @description  Whether to hide this enum on the page
   */

  hiddenSelect?: boolean

  /**
   * @description  The icon associated with the enum
   */

  icon?: string

  /**
   * @description  The color associated with the enum
   */

  color?: string
}
export interface ModelEnumObjects {
  [key: string]: EnumValueOption
}
export const NotificationChannelEnumObjects: ModelEnumObjects = {
  App: {
    position: 0,
  },
  Sms: {
    position: 1,
  },
  Email: {
    position: 2,
  },
  Wechat: {
    position: 3,
  },
  Slack: {
    position: 4,
  },
  Feishu: {
    position: 5,
  },
  QiyeWechat: {
    position: 6,
  },
}
export const NotificationChannelEnums = [
  'App',
  'Sms',
  'Email',
  'Wechat',
  'Slack',
  'Feishu',
  'QiyeWechat',
]
export const enum NotificationChannelEnum {
  App = 'App',
  Sms = 'Sms',
  Email = 'Email',
  Wechat = 'Wechat',
  Slack = 'Slack',
  Feishu = 'Feishu',
  QiyeWechat = 'QiyeWechat',
}
export const NotificationTemplateEventNameEnumObjects: ModelEnumObjects = {
  Test: {
    position: 0,
  },
  SpaceExpiring: {
    position: 1,
  },
  ReservationStartHostReminder: {
    position: 2,
  },
  ReservationStartAttendeeReminder: {
    position: 3,
  },
  ReservationEndSoonReminder: {
    position: 4,
  },
  ReservationCreatedHostReminder: {
    position: 5,
  },
  ReservationCreatedAttendeeReminder: {
    position: 6,
  },
  SyncCalendarFailed: {
    position: 7,
  },
  ReservationRecurrentCreatedHostReminder: {
    position: 8,
  },
  ReservationRecurrentCreatedAttendeeReminder: {
    position: 9,
  },
  ReservationResourceOrTimeUpdated: {
    position: 10,
  },
  ReservationHostUpdatedOldHostReminder: {
    position: 11,
  },
  ReservationHostUpdatedNewHostReminder: {
    position: 12,
  },
  ReservationAttendeeUpdatedOldAttendeeReminder: {
    position: 13,
  },
  ReservationAttendeeUpdatedNewAttendeeReminder: {
    position: 14,
  },
  ReservationServiceUpdated: {
    position: 15,
  },
  ReservationApprovalReminder: {
    position: 16,
  },
  ReservationCancelledHostReminder: {
    position: 17,
  },
  ReservationCancelledAttendeeReminder: {
    position: 18,
  },
  ReservationCancelledTaskReminder: {
    position: 19,
  },
  ReservationRecurrentCancelledHostReminder: {
    position: 20,
  },
  ReservationRecurrentCancelledAttendeeReminder: {
    position: 21,
  },
  MeetingReservationReminder: {
    position: 22,
  },
  MeetingReservationTerminated: {
    position: 23,
  },
  MeetingReservationUpdated: {
    position: 24,
  },
  MeetingReservationCancelled: {
    position: 25,
  },
  MeetingReservationCancellation: {
    position: 26,
  },
  DeskReservationCreated: {
    position: 27,
  },
  DeskReservationReminder: {
    position: 28,
  },
  DeskReservationCancelled: {
    position: 29,
  },
  DeskReservationAutoTerminated: {
    position: 30,
  },
  SaasTransactionCreditSuccess: {
    position: 31,
  },
  SaasRenewSuccess: {
    position: 32,
  },
  SaasRenewFailed: {
    position: 33,
  },
  SaasSubscriptionExpired: {
    position: 34,
  },
  SaasSubscriptionPastDue: {
    position: 35,
  },
  SaasSubscriptionTrialExpiring: {
    position: 36,
  },
  VisitorRemindApprove: {
    position: 37,
  },
  VisitorSendInvite: {
    position: 38,
  },
  VisitorSignin: {
    position: 39,
  },
  VisitorSignout: {
    position: 40,
  },
  VisitorRemind: {
    position: 41,
  },
  VisitorLeaveRemind: {
    position: 42,
  },
  VisitorExternalAttendeesUpdated: {
    position: 43,
  },
  VisitorExternalAttendeesCancelled: {
    position: 44,
  },
  VisitorDisapprovedForHost: {
    position: 45,
  },
  VisitorApprovedForHost: {
    position: 46,
  },
  VisitorSigninForHost: {
    position: 47,
  },
  VisitorSigninForCreator: {
    position: 48,
  },
  VisitorSatisfactionSurvey: {
    position: 49,
  },
  VisitorNotSignout: {
    position: 50,
  },
  VisitorAcceptInvitation: {
    position: 51,
  },
  VisitorSignNDA: {
    position: 52,
  },
  VisitorApprovedForVisitor: {
    position: 53,
  },
  VisitorDisapprovedForVisitor: {
    position: 54,
  },
  VisitorWatchListRemind: {
    position: 55,
  },
  TaskCreatedCreator: {
    position: 56,
  },
  TaskCreatedAssignTo: {
    position: 57,
  },
  TaskCompleted: {
    position: 58,
  },
  TaskExpired: {
    position: 59,
  },
  BlacklistViolation: {
    position: 60,
  },
  BlacklistViolationDepartment: {
    position: 61,
  },
  BlacklistViolationInvalid: {
    position: 62,
  },
  BlacklistByUserRule: {
    position: 63,
  },
  BlacklistByDepartmentRule: {
    position: 64,
  },
  BlacklistInvalid: {
    position: 65,
  },
  WaitlistCreated: {
    position: 66,
  },
  WaitlistPendingConfirm: {
    position: 67,
  },
  WaitlistConfirmed: {
    position: 68,
  },
  WaitlistTimeOutUnconfirmed: {
    position: 69,
  },
  WaitlistByOther: {
    position: 70,
  },
  WaitlistFailByRule: {
    position: 71,
  },
  DeliveryCreated: {
    position: 72,
  },
  DeliveryExpiredToReceiver: {
    position: 73,
  },
  DeliveryExpiredToAssignTo: {
    position: 74,
  },
  SpaceCreateApply: {
    position: 75,
  },
  UserCreatedRemind: {
    position: 76,
  },
  TaskBeforeExpiredRemind: {
    position: 77,
  },
  DeliveryBeforeExpiredRemindToReceiver: {
    position: 78,
  },
  DeliveryBeforeExpiredRemindToAssignTo: {
    position: 79,
  },
  DeliveryAreaChangeRemind: {
    position: 80,
  },
}
export const NotificationTemplateEventNameEnums = [
  'Test',
  'SpaceExpiring',
  'ReservationStartHostReminder',
  'ReservationStartAttendeeReminder',
  'ReservationEndSoonReminder',
  'ReservationCreatedHostReminder',
  'ReservationCreatedAttendeeReminder',
  'SyncCalendarFailed',
  'ReservationRecurrentCreatedHostReminder',
  'ReservationRecurrentCreatedAttendeeReminder',
  'ReservationResourceOrTimeUpdated',
  'ReservationHostUpdatedOldHostReminder',
  'ReservationHostUpdatedNewHostReminder',
  'ReservationAttendeeUpdatedOldAttendeeReminder',
  'ReservationAttendeeUpdatedNewAttendeeReminder',
  'ReservationServiceUpdated',
  'ReservationApprovalReminder',
  'ReservationCancelledHostReminder',
  'ReservationCancelledAttendeeReminder',
  'ReservationCancelledTaskReminder',
  'ReservationRecurrentCancelledHostReminder',
  'ReservationRecurrentCancelledAttendeeReminder',
  'MeetingReservationReminder',
  'MeetingReservationTerminated',
  'MeetingReservationUpdated',
  'MeetingReservationCancelled',
  'MeetingReservationCancellation',
  'DeskReservationCreated',
  'DeskReservationReminder',
  'DeskReservationCancelled',
  'DeskReservationAutoTerminated',
  'SaasTransactionCreditSuccess',
  'SaasRenewSuccess',
  'SaasRenewFailed',
  'SaasSubscriptionExpired',
  'SaasSubscriptionPastDue',
  'SaasSubscriptionTrialExpiring',
  'VisitorRemindApprove',
  'VisitorSendInvite',
  'VisitorSignin',
  'VisitorSignout',
  'VisitorRemind',
  'VisitorLeaveRemind',
  'VisitorExternalAttendeesUpdated',
  'VisitorExternalAttendeesCancelled',
  'VisitorDisapprovedForHost',
  'VisitorApprovedForHost',
  'VisitorSigninForHost',
  'VisitorSigninForCreator',
  'VisitorSatisfactionSurvey',
  'VisitorNotSignout',
  'VisitorAcceptInvitation',
  'VisitorSignNDA',
  'VisitorApprovedForVisitor',
  'VisitorDisapprovedForVisitor',
  'VisitorWatchListRemind',
  'TaskCreatedCreator',
  'TaskCreatedAssignTo',
  'TaskCompleted',
  'TaskExpired',
  'BlacklistViolation',
  'BlacklistViolationDepartment',
  'BlacklistViolationInvalid',
  'BlacklistByUserRule',
  'BlacklistByDepartmentRule',
  'BlacklistInvalid',
  'WaitlistCreated',
  'WaitlistPendingConfirm',
  'WaitlistConfirmed',
  'WaitlistTimeOutUnconfirmed',
  'WaitlistByOther',
  'WaitlistFailByRule',
  'DeliveryCreated',
  'DeliveryExpiredToReceiver',
  'DeliveryExpiredToAssignTo',
  'SpaceCreateApply',
  'UserCreatedRemind',
  'TaskBeforeExpiredRemind',
  'DeliveryBeforeExpiredRemindToReceiver',
  'DeliveryBeforeExpiredRemindToAssignTo',
  'DeliveryAreaChangeRemind',
]
export const enum NotificationTemplateEventNameEnum {
  Test = 'Test',
  SpaceExpiring = 'SpaceExpiring',
  ReservationStartHostReminder = 'ReservationStartHostReminder',
  ReservationStartAttendeeReminder = 'ReservationStartAttendeeReminder',
  ReservationEndSoonReminder = 'ReservationEndSoonReminder',
  ReservationCreatedHostReminder = 'ReservationCreatedHostReminder',
  ReservationCreatedAttendeeReminder = 'ReservationCreatedAttendeeReminder',
  SyncCalendarFailed = 'SyncCalendarFailed',
  ReservationRecurrentCreatedHostReminder = 'ReservationRecurrentCreatedHostReminder',
  ReservationRecurrentCreatedAttendeeReminder = 'ReservationRecurrentCreatedAttendeeReminder',
  ReservationResourceOrTimeUpdated = 'ReservationResourceOrTimeUpdated',
  ReservationHostUpdatedOldHostReminder = 'ReservationHostUpdatedOldHostReminder',
  ReservationHostUpdatedNewHostReminder = 'ReservationHostUpdatedNewHostReminder',
  ReservationAttendeeUpdatedOldAttendeeReminder = 'ReservationAttendeeUpdatedOldAttendeeReminder',
  ReservationAttendeeUpdatedNewAttendeeReminder = 'ReservationAttendeeUpdatedNewAttendeeReminder',
  ReservationServiceUpdated = 'ReservationServiceUpdated',
  ReservationApprovalReminder = 'ReservationApprovalReminder',
  ReservationCancelledHostReminder = 'ReservationCancelledHostReminder',
  ReservationCancelledAttendeeReminder = 'ReservationCancelledAttendeeReminder',
  ReservationCancelledTaskReminder = 'ReservationCancelledTaskReminder',
  ReservationRecurrentCancelledHostReminder = 'ReservationRecurrentCancelledHostReminder',
  ReservationRecurrentCancelledAttendeeReminder = 'ReservationRecurrentCancelledAttendeeReminder',
  MeetingReservationReminder = 'MeetingReservationReminder',
  MeetingReservationTerminated = 'MeetingReservationTerminated',
  MeetingReservationUpdated = 'MeetingReservationUpdated',
  MeetingReservationCancelled = 'MeetingReservationCancelled',
  MeetingReservationCancellation = 'MeetingReservationCancellation',
  DeskReservationCreated = 'DeskReservationCreated',
  DeskReservationReminder = 'DeskReservationReminder',
  DeskReservationCancelled = 'DeskReservationCancelled',
  DeskReservationAutoTerminated = 'DeskReservationAutoTerminated',
  SaasTransactionCreditSuccess = 'SaasTransactionCreditSuccess',
  SaasRenewSuccess = 'SaasRenewSuccess',
  SaasRenewFailed = 'SaasRenewFailed',
  SaasSubscriptionExpired = 'SaasSubscriptionExpired',
  SaasSubscriptionPastDue = 'SaasSubscriptionPastDue',
  SaasSubscriptionTrialExpiring = 'SaasSubscriptionTrialExpiring',
  VisitorRemindApprove = 'VisitorRemindApprove',
  VisitorSendInvite = 'VisitorSendInvite',
  VisitorSignin = 'VisitorSignin',
  VisitorSignout = 'VisitorSignout',
  VisitorRemind = 'VisitorRemind',
  VisitorLeaveRemind = 'VisitorLeaveRemind',
  VisitorExternalAttendeesUpdated = 'VisitorExternalAttendeesUpdated',
  VisitorExternalAttendeesCancelled = 'VisitorExternalAttendeesCancelled',
  VisitorDisapprovedForHost = 'VisitorDisapprovedForHost',
  VisitorApprovedForHost = 'VisitorApprovedForHost',
  VisitorSigninForHost = 'VisitorSigninForHost',
  VisitorSigninForCreator = 'VisitorSigninForCreator',
  VisitorSatisfactionSurvey = 'VisitorSatisfactionSurvey',
  VisitorNotSignout = 'VisitorNotSignout',
  VisitorAcceptInvitation = 'VisitorAcceptInvitation',
  VisitorSignNDA = 'VisitorSignNDA',
  VisitorApprovedForVisitor = 'VisitorApprovedForVisitor',
  VisitorDisapprovedForVisitor = 'VisitorDisapprovedForVisitor',
  VisitorWatchListRemind = 'VisitorWatchListRemind',
  TaskCreatedCreator = 'TaskCreatedCreator',
  TaskCreatedAssignTo = 'TaskCreatedAssignTo',
  TaskCompleted = 'TaskCompleted',
  TaskExpired = 'TaskExpired',
  BlacklistViolation = 'BlacklistViolation',
  BlacklistViolationDepartment = 'BlacklistViolationDepartment',
  BlacklistViolationInvalid = 'BlacklistViolationInvalid',
  BlacklistByUserRule = 'BlacklistByUserRule',
  BlacklistByDepartmentRule = 'BlacklistByDepartmentRule',
  BlacklistInvalid = 'BlacklistInvalid',
  WaitlistCreated = 'WaitlistCreated',
  WaitlistPendingConfirm = 'WaitlistPendingConfirm',
  WaitlistConfirmed = 'WaitlistConfirmed',
  WaitlistTimeOutUnconfirmed = 'WaitlistTimeOutUnconfirmed',
  WaitlistByOther = 'WaitlistByOther',
  WaitlistFailByRule = 'WaitlistFailByRule',
  DeliveryCreated = 'DeliveryCreated',
  DeliveryExpiredToReceiver = 'DeliveryExpiredToReceiver',
  DeliveryExpiredToAssignTo = 'DeliveryExpiredToAssignTo',
  SpaceCreateApply = 'SpaceCreateApply',
  UserCreatedRemind = 'UserCreatedRemind',
  TaskBeforeExpiredRemind = 'TaskBeforeExpiredRemind',
  DeliveryBeforeExpiredRemindToReceiver = 'DeliveryBeforeExpiredRemindToReceiver',
  DeliveryBeforeExpiredRemindToAssignTo = 'DeliveryBeforeExpiredRemindToAssignTo',
  DeliveryAreaChangeRemind = 'DeliveryAreaChangeRemind',
}
export const ApprovalRecordSyncProviderEnumObjects: ModelEnumObjects = {
  qiyeWechat: {
    position: 0,
  },
  dingtalk: {
    position: 1,
  },
}
export const ApprovalRecordSyncProviderEnums = ['qiyeWechat', 'dingtalk']
export const enum ApprovalRecordSyncProviderEnum {
  qiyeWechat = 'qiyeWechat',
  dingtalk = 'dingtalk',
}
export const ApprovalRecordStatusEnumObjects: ModelEnumObjects = {
  approving: {
    color: 'orange',
    icon: 'clock',
    position: 0,
  },
  approved: {
    color: 'blue',
    icon: 'check',
    position: 1,
  },
  rejected: {
    color: 'gray',
    icon: 'x',
    position: 2,
  },
  terminated: {
    color: 'gray',
    icon: 'x',
    position: 3,
  },
}
export const ApprovalRecordStatusEnums = [
  'approving',
  'approved',
  'rejected',
  'terminated',
]
export const enum ApprovalRecordStatusEnum {
  approving = 'approving',
  approved = 'approved',
  rejected = 'rejected',
  terminated = 'terminated',
}
export const ApprovalRecordResourceEnumObjects: ModelEnumObjects = {
  visitor: {
    color: 'gray',
    position: 0,
  },
  resourceReservation: {
    color: 'gray',
    position: 1,
  },
}
export const ApprovalRecordResourceEnums = ['visitor', 'resourceReservation']
export const enum ApprovalRecordResourceEnum {
  visitor = 'visitor',
  resourceReservation = 'resourceReservation',
}
export const CustomFieldTypeEnumObjects: ModelEnumObjects = {
  Text: {
    position: 1,
  },
  Textarea: {
    position: 6,
  },
  Number: {
    position: 7,
  },
  Email: {
    position: 8,
  },
  Phone: {
    position: 9,
  },
  Address: {
    position: 10,
  },
  Select: {
    position: 2,
  },
  MultiSelect: {
    position: 3,
  },
  Checkbox: {
    position: 4,
  },
  Radio: {
    position: 5,
  },
}
export const CustomFieldTypeEnums = [
  'Text',
  'Textarea',
  'Number',
  'Email',
  'Phone',
  'Address',
  'Select',
  'MultiSelect',
  'Checkbox',
  'Radio',
]
export const enum CustomFieldTypeEnum {
  Text = 'Text',
  Textarea = 'Textarea',
  Number = 'Number',
  Email = 'Email',
  Phone = 'Phone',
  Address = 'Address',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
}
export const CustomFieldOwnerTypeEnumObjects: ModelEnumObjects = {
  UserType: {
    position: 0,
  },
  ResourceType: {
    position: 1,
  },
  VisitorType: {
    position: 2,
  },
  ReservationPolicyType: {
    position: 3,
  },
}
export const CustomFieldOwnerTypeEnums = [
  'UserType',
  'ResourceType',
  'VisitorType',
  'ReservationPolicyType',
]
export const enum CustomFieldOwnerTypeEnum {
  UserType = 'UserType',
  ResourceType = 'ResourceType',
  VisitorType = 'VisitorType',
  ReservationPolicyType = 'ReservationPolicyType',
}
export const EmployeeWorkStatusStateEnumObjects: ModelEnumObjects = {
  WorkOffice: {
    position: 0,
  },
  WorkHome: {
    position: 1,
  },
  Rest: {
    position: 2,
  },
  Unknown: {
    position: 3,
  },
}
export const EmployeeWorkStatusStateEnums = [
  'WorkOffice',
  'WorkHome',
  'Rest',
  'Unknown',
]
export const enum EmployeeWorkStatusStateEnum {
  WorkOffice = 'WorkOffice',
  WorkHome = 'WorkHome',
  Rest = 'Rest',
  Unknown = 'Unknown',
}
export const FeatureNameEnumObjects: ModelEnumObjects = {
  Map: {
    position: 0,
  },
  Dashboard: {
    position: 1,
  },
  WorkStatus: {
    position: 2,
  },
  Delivery: {
    position: 3,
  },
  Task: {
    position: 4,
  },
  Resource: {
    position: 5,
  },
  Announcement: {
    position: 6,
  },
  AppLink: {
    position: 7,
  },
  Visitor: {
    position: 8,
  },
  AddressBook: {
    position: 9,
  },
}
export const FeatureNameEnums = [
  'Map',
  'Dashboard',
  'WorkStatus',
  'Delivery',
  'Task',
  'Resource',
  'Announcement',
  'AppLink',
  'Visitor',
  'AddressBook',
]
export const enum FeatureNameEnum {
  Map = 'Map',
  Dashboard = 'Dashboard',
  WorkStatus = 'WorkStatus',
  Delivery = 'Delivery',
  Task = 'Task',
  Resource = 'Resource',
  Announcement = 'Announcement',
  AppLink = 'AppLink',
  Visitor = 'Visitor',
  AddressBook = 'AddressBook',
}
export const AreaUnitEnumObjects: ModelEnumObjects = {
  meter: {
    position: 0,
  },
  foot: {
    position: 1,
  },
}
export const AreaUnitEnums = ['meter', 'foot']
export const enum AreaUnitEnum {
  meter = 'meter',
  foot = 'foot',
}
export const LocationAmountUnitEnumObjects: ModelEnumObjects = {
  dollar: {
    position: 0,
  },
  rmb: {
    position: 1,
  },
}
export const LocationAmountUnitEnums = ['dollar', 'rmb']
export const enum LocationAmountUnitEnum {
  dollar = 'dollar',
  rmb = 'rmb',
}
export const PadOSEnumObjects: ModelEnumObjects = {
  android: {
    position: 0,
  },
  ios: {
    position: 1,
  },
}
export const PadOSEnums = ['android', 'ios']
export const enum PadOSEnum {
  android = 'android',
  ios = 'ios',
}
export const PadAppTypeEnumObjects: ModelEnumObjects = {
  visitor: {
    position: 0,
  },
  meetingroom: {
    position: 1,
  },
  office: {
    position: 2,
  },
  panel: {
    position: 3,
  },
  signage: {
    position: 4,
  },
  locker: {
    position: 5,
  },
  meetingroom_pro: {
    position: 6,
  },
}
export const PadAppTypeEnums = [
  'visitor',
  'meetingroom',
  'office',
  'panel',
  'signage',
  'locker',
  'meetingroom_pro',
]
export const enum PadAppTypeEnum {
  visitor = 'visitor',
  meetingroom = 'meetingroom',
  office = 'office',
  panel = 'panel',
  signage = 'signage',
  locker = 'locker',
  meetingroom_pro = 'meetingroom_pro',
}
export const PadSubTypeEnumObjects: ModelEnumObjects = {
  front_desk: {
    position: 0,
  },
  meeting_display: {
    position: 1,
  },
  isaac: {
    position: 2,
  },
  tv_screen: {
    position: 3,
  },
  office_display: {
    position: 4,
  },
  meeting_panel: {
    position: 5,
  },
  office_panel: {
    position: 6,
  },
  digital_signage: {
    position: 7,
  },
  locker: {
    position: 8,
  },
  desk_panel: {
    position: 9,
  },
  pad: {
    position: 10,
  },
  pad_light: {
    position: 11,
  },
  pad_light_all: {
    position: 12,
  },
  visitor_pad: {
    position: 13,
  },
  visitor_machine: {
    position: 14,
  },
  visitor_double_faced: {
    position: 15,
  },
}
export const PadSubTypeEnums = [
  'front_desk',
  'meeting_display',
  'isaac',
  'tv_screen',
  'office_display',
  'meeting_panel',
  'office_panel',
  'digital_signage',
  'locker',
  'desk_panel',
  'pad',
  'pad_light',
  'pad_light_all',
  'visitor_pad',
  'visitor_machine',
  'visitor_double_faced',
]
export const enum PadSubTypeEnum {
  front_desk = 'front_desk',
  meeting_display = 'meeting_display',
  isaac = 'isaac',
  tv_screen = 'tv_screen',
  office_display = 'office_display',
  meeting_panel = 'meeting_panel',
  office_panel = 'office_panel',
  digital_signage = 'digital_signage',
  locker = 'locker',
  desk_panel = 'desk_panel',
  pad = 'pad',
  pad_light = 'pad_light',
  pad_light_all = 'pad_light_all',
  visitor_pad = 'visitor_pad',
  visitor_machine = 'visitor_machine',
  visitor_double_faced = 'visitor_double_faced',
}
export const PadLockTypeEnumObjects: ModelEnumObjects = {
  serial: {
    position: 0,
  },
  usb: {
    position: 1,
  },
  remote: {
    position: 2,
  },
  io: {
    position: 3,
  },
}
export const PadLockTypeEnums = ['serial', 'usb', 'remote', 'io']
export const enum PadLockTypeEnum {
  serial = 'serial',
  usb = 'usb',
  remote = 'remote',
  io = 'io',
}
export const PadStateEnumObjects: ModelEnumObjects = {
  enabled: {
    color: 'green',
    position: 0,
  },
  disabled: {
    color: 'red',
    position: 1,
  },
}
export const PadStateEnums = ['enabled', 'disabled']
export const enum PadStateEnum {
  enabled = 'enabled',
  disabled = 'disabled',
}
export const PadCommunicationModeEnumObjects: ModelEnumObjects = {
  socket: {
    position: 0,
  },
  iot: {
    position: 1,
  },
  all: {
    position: 2,
  },
}
export const PadCommunicationModeEnums = ['socket', 'iot', 'all']
export const enum PadCommunicationModeEnum {
  socket = 'socket',
  iot = 'iot',
  all = 'all',
}
export const AreaTypeEnumObjects: ModelEnumObjects = {
  delivery: {
    position: 0,
  },
  other: {
    position: 1,
  },
}
export const AreaTypeEnums = ['delivery', 'other']
export const enum AreaTypeEnum {
  delivery = 'delivery',
  other = 'other',
}
export const RoleLevelEnumObjects: ModelEnumObjects = {
  SPACE: {
    position: 0,
  },
  LOCATION: {
    position: 1,
  },
}
export const RoleLevelEnums = ['SPACE', 'LOCATION']
export const enum RoleLevelEnum {
  SPACE = 'SPACE',
  LOCATION = 'LOCATION',
}
export const PermissionTypeEnumObjects: ModelEnumObjects = {
  ONLY_SPACE: {
    position: 0,
  },
  ONLY_LOCATION: {
    position: 1,
  },
  ALL: {
    position: 2,
  },
}
export const PermissionTypeEnums = ['ONLY_SPACE', 'ONLY_LOCATION', 'ALL']
export const enum PermissionTypeEnum {
  ONLY_SPACE = 'ONLY_SPACE',
  ONLY_LOCATION = 'ONLY_LOCATION',
  ALL = 'ALL',
}
export const CustomerPlanLevelEnumObjects: ModelEnumObjects = {
  Team: {
    position: 0,
  },
  Premium: {
    color: 'blue',
    position: 1,
  },
  Enterprise: {
    color: 'orange',
    position: 2,
  },
}
export const CustomerPlanLevelEnums = ['Team', 'Premium', 'Enterprise']
export const enum CustomerPlanLevelEnum {
  Team = 'Team',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}
export const CustomerPricingIntervalEnumObjects: ModelEnumObjects = {
  Monthly: {
    position: 0,
  },
  Yearly: {
    position: 1,
  },
}
export const CustomerPricingIntervalEnums = ['Monthly', 'Yearly']
export const enum CustomerPricingIntervalEnum {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}
export const CustomerSubscriptionStatusEnumObjects: ModelEnumObjects = {
  Inactive: {
    color: 'red',
    position: 0,
  },
  Active: {
    color: 'green',
    position: 1,
  },
  Suspended: {
    color: '#D1D5DB',
    position: 2,
  },
  Canceled: {
    color: '#D1D5DB',
    position: 3,
  },
}
export const CustomerSubscriptionStatusEnums = [
  'Inactive',
  'Active',
  'Suspended',
  'Canceled',
]
export const enum CustomerSubscriptionStatusEnum {
  Inactive = 'Inactive',
  Active = 'Active',
  Suspended = 'Suspended',
  Canceled = 'Canceled',
}
export const ServiceTimeEnumObjects: ModelEnumObjects = {
  before_reservation: {
    color: 'description-gray',
    icon: 'alarm-clock',
    position: 0,
  },
  after_reservation: {
    color: 'description-gray',
    icon: 'alarm-clock',
    position: 1,
  },
  within_reservation: {
    color: 'description-gray',
    icon: 'alarm-clock',
    position: 2,
  },
}
export const ServiceTimeEnums = [
  'before_reservation',
  'after_reservation',
  'within_reservation',
]
export const enum ServiceTimeEnum {
  before_reservation = 'before_reservation',
  after_reservation = 'after_reservation',
  within_reservation = 'within_reservation',
}
export const SpaceStatusEnumObjects: ModelEnumObjects = {
  active: {
    position: 0,
  },
  init: {
    position: 1,
  },
  rejected: {
    position: 2,
  },
}
export const SpaceStatusEnums = ['active', 'init', 'rejected']
export const enum SpaceStatusEnum {
  active = 'active',
  init = 'init',
  rejected = 'rejected',
}
export const SpaceLevelEnumObjects: ModelEnumObjects = {
  Standard: {
    position: 0,
  },
  Premium: {
    position: 1,
  },
  Enterprise: {
    position: 2,
  },
}
export const SpaceLevelEnums = ['Standard', 'Premium', 'Enterprise']
export const enum SpaceLevelEnum {
  Standard = 'Standard',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}
export const TaskCategoryTypeEnumObjects: ModelEnumObjects = {
  Repair: {
    position: 0,
  },
  Meeting: {
    position: 1,
  },
  Cleaning: {
    position: 2,
  },
  Other: {
    position: 3,
  },
}
export const TaskCategoryTypeEnums = ['Repair', 'Meeting', 'Cleaning', 'Other']
export const enum TaskCategoryTypeEnum {
  Repair = 'Repair',
  Meeting = 'Meeting',
  Cleaning = 'Cleaning',
  Other = 'Other',
}
export const UserStateEnumObjects: ModelEnumObjects = {
  unconfirmed: {
    color: 'orange',
    position: 0,
  },
  active: {
    color: 'green',
    position: 1,
  },
  dismissed: {
    color: 'red',
    position: 2,
  },
}
export const UserStateEnums = ['unconfirmed', 'active', 'dismissed']
export const enum UserStateEnum {
  unconfirmed = 'unconfirmed',
  active = 'active',
  dismissed = 'dismissed',
}
export const UserGenderEnumObjects: ModelEnumObjects = {
  notSet: {
    position: 0,
  },
  male: {
    position: 1,
  },
  female: {
    position: 2,
  },
}
export const UserGenderEnums = ['notSet', 'male', 'female']
export const enum UserGenderEnum {
  notSet = 'notSet',
  male = 'male',
  female = 'female',
}
export const UserEmployeeTypeEnumObjects: ModelEnumObjects = {
  fulltime: {
    position: 0,
  },
  parttime: {
    position: 1,
  },
  contractor: {
    position: 2,
  },
  external: {
    position: 3,
  },
}
export const UserEmployeeTypeEnums = [
  'fulltime',
  'parttime',
  'contractor',
  'external',
]
export const enum UserEmployeeTypeEnum {
  fulltime = 'fulltime',
  parttime = 'parttime',
  contractor = 'contractor',
  external = 'external',
}
export const UserSyncProviderTypeEnumObjects: ModelEnumObjects = {
  SCIM: {
    position: 0,
  },
}
export const UserSyncProviderTypeEnums = ['SCIM']
export const enum UserSyncProviderTypeEnum {
  SCIM = 'SCIM',
}
export const ObjectAuditTypeEnumObjects: ModelEnumObjects = {
  User: {
    position: 0,
  },
  Resource: {
    position: 1,
  },
  ResourceReservation: {
    position: 2,
  },
  Task: {
    position: 3,
  },
}
export const ObjectAuditTypeEnums = [
  'User',
  'Resource',
  'ResourceReservation',
  'Task',
]
export const enum ObjectAuditTypeEnum {
  User = 'User',
  Resource = 'Resource',
  ResourceReservation = 'ResourceReservation',
  Task = 'Task',
}
export const ObjectAuditActionEnumObjects: ModelEnumObjects = {
  Create: {
    position: 0,
  },
  Update: {
    position: 1,
  },
  Delete: {
    position: 2,
  },
  Login: {
    position: 3,
  },
  Logout: {
    position: 4,
  },
  LoginFailed: {
    position: 5,
  },
}
export const ObjectAuditActionEnums = [
  'Create',
  'Update',
  'Delete',
  'Login',
  'Logout',
  'LoginFailed',
]
export const enum ObjectAuditActionEnum {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Login = 'Login',
  Logout = 'Logout',
  LoginFailed = 'LoginFailed',
}
export const SecurityAuditActionEnumObjects: ModelEnumObjects = {
  Login: {
    position: 0,
  },
  Logout: {
    position: 1,
  },
  LoginFailed: {
    position: 2,
  },
  LoginInvalidPassword: {
    position: 3,
  },
}
export const SecurityAuditActionEnums = [
  'Login',
  'Logout',
  'LoginFailed',
  'LoginInvalidPassword',
]
export const enum SecurityAuditActionEnum {
  Login = 'Login',
  Logout = 'Logout',
  LoginFailed = 'LoginFailed',
  LoginInvalidPassword = 'LoginInvalidPassword',
}
export const SecurityAuditClientTypeEnumObjects: ModelEnumObjects = {
  Web: {
    position: 0,
  },
  App: {
    position: 1,
  },
}
export const SecurityAuditClientTypeEnums = ['Web', 'App']
export const enum SecurityAuditClientTypeEnum {
  Web = 'Web',
  App = 'App',
}
export const VisitorBlockItemTypeEnumObjects: ModelEnumObjects = {
  blacklist: {
    position: 0,
  },
  watchlist: {
    position: 1,
  },
}
export const VisitorBlockItemTypeEnums = ['blacklist', 'watchlist']
export const enum VisitorBlockItemTypeEnum {
  blacklist = 'blacklist',
  watchlist = 'watchlist',
}
export const VisitorBlockKeywordTypeEnumObjects: ModelEnumObjects = {
  phone_num: {
    color: 'gray',
    icon: 'smartphone',
    position: 0,
  },
  email: {
    color: 'gray',
    icon: 'mail',
    position: 1,
  },
  name: {
    color: 'gray',
    icon: 'user',
    position: 2,
  },
  company: {
    color: 'gray',
    icon: 'building-2',
    position: 3,
  },
}
export const VisitorBlockKeywordTypeEnums = [
  'phone_num',
  'email',
  'name',
  'company',
]
export const enum VisitorBlockKeywordTypeEnum {
  phone_num = 'phone_num',
  email = 'email',
  name = 'name',
  company = 'company',
}
export const InviteSectionTypeEnumObjects: ModelEnumObjects = {
  wifi: {
    position: 0,
  },
  lobby: {
    position: 1,
  },
  restroom: {
    position: 2,
  },
  storage: {
    position: 3,
  },
  waiting: {
    position: 4,
  },
  emergency: {
    position: 5,
  },
  beverage: {
    position: 6,
  },
  sign_out: {
    position: 7,
  },
  other: {
    position: 8,
  },
}
export const InviteSectionTypeEnums = [
  'wifi',
  'lobby',
  'restroom',
  'storage',
  'waiting',
  'emergency',
  'beverage',
  'sign_out',
  'other',
]
export const enum InviteSectionTypeEnum {
  wifi = 'wifi',
  lobby = 'lobby',
  restroom = 'restroom',
  storage = 'storage',
  waiting = 'waiting',
  emergency = 'emergency',
  beverage = 'beverage',
  sign_out = 'sign_out',
  other = 'other',
}
export const SignInSectionTypeEnumObjects: ModelEnumObjects = {
  traffic: {
    position: 0,
  },
  park: {
    position: 1,
  },
  elevator: {
    position: 2,
  },
  proscenium: {
    position: 3,
  },
  necessity: {
    position: 4,
  },
}
export const SignInSectionTypeEnums = [
  'traffic',
  'park',
  'elevator',
  'proscenium',
  'necessity',
]
export const enum SignInSectionTypeEnum {
  traffic = 'traffic',
  park = 'park',
  elevator = 'elevator',
  proscenium = 'proscenium',
  necessity = 'necessity',
}
export const GuideTypeEnumObjects: ModelEnumObjects = {
  invite: {
    position: 0,
  },
  sign_in: {
    position: 1,
  },
}
export const GuideTypeEnums = ['invite', 'sign_in']
export const enum GuideTypeEnum {
  invite = 'invite',
  sign_in = 'sign_in',
}
export const lawAgreementStatusEnumObjects: ModelEnumObjects = {
  enabled: {
    color: 'green',
    position: 0,
  },
  disabled: {
    color: 'red',
    position: 1,
  },
}
export const lawAgreementStatusEnums = ['enabled', 'disabled']
export const enum lawAgreementStatusEnum {
  enabled = 'enabled',
  disabled = 'disabled',
}
export const lawAgreementTypeEnumObjects: ModelEnumObjects = {
  html: {
    position: 0,
  },
  pdf: {
    position: 1,
  },
}
export const lawAgreementTypeEnums = ['html', 'pdf']
export const enum lawAgreementTypeEnum {
  html = 'html',
  pdf = 'pdf',
}
export const VisitorEmailNotificationStatusEnumObjects: ModelEnumObjects = {
  sending: {
    position: 0,
  },
  sent: {
    position: 1,
  },
  sent_error: {
    position: 2,
  },
  soft_bounce: {
    position: 3,
  },
  deliver: {
    position: 4,
  },
  open: {
    position: 5,
  },
  click: {
    position: 6,
  },
  invalid: {
    position: 7,
  },
  report_spam: {
    position: 8,
  },
}
export const VisitorEmailNotificationStatusEnums = [
  'sending',
  'sent',
  'sent_error',
  'soft_bounce',
  'deliver',
  'open',
  'click',
  'invalid',
  'report_spam',
]
export const enum VisitorEmailNotificationStatusEnum {
  sending = 'sending',
  sent = 'sent',
  sent_error = 'sent_error',
  soft_bounce = 'soft_bounce',
  deliver = 'deliver',
  open = 'open',
  click = 'click',
  invalid = 'invalid',
  report_spam = 'report_spam',
}
export const VisitorSmsNotificationStatusEnumObjects: ModelEnumObjects = {
  sending: {
    position: 0,
  },
  sent: {
    position: 1,
  },
  success: {
    position: 2,
  },
  bounced: {
    position: 3,
  },
}
export const VisitorSmsNotificationStatusEnums = [
  'sending',
  'sent',
  'success',
  'bounced',
]
export const enum VisitorSmsNotificationStatusEnum {
  sending = 'sending',
  sent = 'sent',
  success = 'success',
  bounced = 'bounced',
}
export const VisitorIdTypeEnumObjects: ModelEnumObjects = {
  none: {
    position: 0,
  },
  person_identification_card: {
    position: 1,
  },
  passport: {
    position: 2,
  },
  foreign_passport: {
    position: 3,
  },
  hk_or_macao_card: {
    position: 4,
  },
}
export const VisitorIdTypeEnums = [
  'none',
  'person_identification_card',
  'passport',
  'foreign_passport',
  'hk_or_macao_card',
]
export const enum VisitorIdTypeEnum {
  none = 'none',
  person_identification_card = 'person_identification_card',
  passport = 'passport',
  foreign_passport = 'foreign_passport',
  hk_or_macao_card = 'hk_or_macao_card',
}
export const VisitorIdCheckedSourceEnumObjects: ModelEnumObjects = {
  none: {
    position: 0,
  },
  manual: {
    position: 1,
  },
  pad: {
    position: 2,
  },
}
export const VisitorIdCheckedSourceEnums = ['none', 'manual', 'pad']
export const enum VisitorIdCheckedSourceEnum {
  none = 'none',
  manual = 'manual',
  pad = 'pad',
}
export const VisitorSignedInSourceEnumObjects: ModelEnumObjects = {
  unknown: {
    position: 0,
  },
  admin: {
    position: 1,
  },
  wechat: {
    position: 2,
  },
  qiye_wechat: {
    position: 3,
  },
  pad: {
    position: 4,
  },
  lock: {
    position: 5,
  },
  h5: {
    position: 6,
  },
}
export const VisitorSignedInSourceEnums = [
  'unknown',
  'admin',
  'wechat',
  'qiye_wechat',
  'pad',
  'lock',
  'h5',
]
export const enum VisitorSignedInSourceEnum {
  unknown = 'unknown',
  admin = 'admin',
  wechat = 'wechat',
  qiye_wechat = 'qiye_wechat',
  pad = 'pad',
  lock = 'lock',
  h5 = 'h5',
}
export const VisitorSignedOutSourceEnumObjects: ModelEnumObjects = {
  unknown: {
    position: 0,
  },
  admin: {
    position: 1,
  },
  wechat: {
    position: 2,
  },
  qiye_wechat: {
    position: 3,
  },
  pad: {
    position: 4,
  },
  cron: {
    position: 5,
  },
}
export const VisitorSignedOutSourceEnums = [
  'unknown',
  'admin',
  'wechat',
  'qiye_wechat',
  'pad',
  'cron',
]
export const enum VisitorSignedOutSourceEnum {
  unknown = 'unknown',
  admin = 'admin',
  wechat = 'wechat',
  qiye_wechat = 'qiye_wechat',
  pad = 'pad',
  cron = 'cron',
}
export const VisitorCategoryEnumObjects: ModelEnumObjects = {
  visitor: {
    position: 0,
  },
  employee: {
    position: 1,
  },
}
export const VisitorCategoryEnums = ['visitor', 'employee']
export const enum VisitorCategoryEnum {
  visitor = 'visitor',
  employee = 'employee',
}
export const VisitorUnifiedStatusEnumObjects: ModelEnumObjects = {
  init: {
    position: 0,
  },
  approving: {
    color: 'orange',
    icon: 'ellipsis',
    position: 1,
  },
  waiting_for_acceptance: {
    color: 'gray',
    position: 2,
  },
  waiting_for_sign: {
    color: 'orange',
    icon: 'clock',
    position: 3,
  },
  signed_in: {
    color: 'green',
    icon: 'check',
    position: 4,
  },
  signed_out: {
    color: 'gray',
    icon: 'check',
    position: 5,
  },
  cancelled: {
    color: 'gray',
    icon: 'x',
    position: 6,
  },
}
export const VisitorUnifiedStatusEnums = [
  'init',
  'approving',
  'waiting_for_acceptance',
  'waiting_for_sign',
  'signed_in',
  'signed_out',
  'cancelled',
]
export const enum VisitorUnifiedStatusEnum {
  init = 'init',
  approving = 'approving',
  waiting_for_acceptance = 'waiting_for_acceptance',
  waiting_for_sign = 'waiting_for_sign',
  signed_in = 'signed_in',
  signed_out = 'signed_out',
  cancelled = 'cancelled',
}
export const VisitorCancelReasonEnumObjects: ModelEnumObjects = {
  self_cancel: {
    position: 0,
  },
  approve_reject: {
    position: 1,
  },
  auto_cancel: {
    position: 2,
  },
}
export const VisitorCancelReasonEnums = [
  'self_cancel',
  'approve_reject',
  'auto_cancel',
]
export const enum VisitorCancelReasonEnum {
  self_cancel = 'self_cancel',
  approve_reject = 'approve_reject',
  auto_cancel = 'auto_cancel',
}
export const VisitorRequestTypeEnumObjects: ModelEnumObjects = {
  request: {
    position: 0,
  },
  invite: {
    position: 1,
  },
}
export const VisitorRequestTypeEnums = ['request', 'invite']
export const enum VisitorRequestTypeEnum {
  request = 'request',
  invite = 'invite',
}
export const VisitorRecurrenceTypeEnumObjects: ModelEnumObjects = {
  Occurance: {
    position: 0,
  },
  Exception: {
    position: 1,
  },
}
export const VisitorRecurrenceTypeEnums = ['Occurance', 'Exception']
export const enum VisitorRecurrenceTypeEnum {
  Occurance = 'Occurance',
  Exception = 'Exception',
}
export const VisitorRecordCycleTypeEnumObjects: ModelEnumObjects = {
  Daily: {
    position: 0,
  },
  Monthly: {
    position: 1,
  },
  Hourly: {
    position: 2,
  },
}
export const VisitorRecordCycleTypeEnums = ['Daily', 'Monthly', 'Hourly']
export const enum VisitorRecordCycleTypeEnum {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Hourly = 'Hourly',
}
export const VisitorSourceEnumObjects: ModelEnumObjects = {
  Web: {
    position: 0,
  },
  Tenant: {
    position: 1,
  },
  App: {
    position: 2,
  },
  Wechat: {
    position: 3,
  },
  VisitorPad: {
    position: 4,
  },
}
export const VisitorSourceEnums = [
  'Web',
  'Tenant',
  'App',
  'Wechat',
  'VisitorPad',
]
export const enum VisitorSourceEnum {
  Web = 'Web',
  Tenant = 'Tenant',
  App = 'App',
  Wechat = 'Wechat',
  VisitorPad = 'VisitorPad',
}
export const WebhookEventEnumObjects: ModelEnumObjects = {
  Ping: {
    position: 0,
  },
  Reservation: {
    position: 1,
  },
  Visitor: {
    position: 2,
  },
  Task: {
    position: 3,
  },
  Delivery: {
    position: 4,
  },
}
export const WebhookEventEnums = [
  'Ping',
  'Reservation',
  'Visitor',
  'Task',
  'Delivery',
]
export const enum WebhookEventEnum {
  Ping = 'Ping',
  Reservation = 'Reservation',
  Visitor = 'Visitor',
  Task = 'Task',
  Delivery = 'Delivery',
}
export const WebhookEventStatusEnumObjects: ModelEnumObjects = {
  Success: {
    color: 'green',
    position: 0,
  },
  Failed: {
    color: 'red',
    position: 1,
  },
}
export const WebhookEventStatusEnums = ['Success', 'Failed']
export const enum WebhookEventStatusEnum {
  Success = 'Success',
  Failed = 'Failed',
}
export const HolidayTypeEnumObjects: ModelEnumObjects = {
  public: {
    position: 0,
  },
  bank: {
    position: 1,
  },
  school: {
    position: 2,
  },
  optional: {
    position: 3,
  },
  observance: {
    position: 4,
  },
}
export const HolidayTypeEnums = [
  'public',
  'bank',
  'school',
  'optional',
  'observance',
]
export const enum HolidayTypeEnum {
  public = 'public',
  bank = 'bank',
  school = 'school',
  optional = 'optional',
  observance = 'observance',
}
export const ResourceUsageModeEnumObjects: ModelEnumObjects = {
  shareable: {
    icon: 'users',
    position: 0,
  },
  exclusive: {
    icon: 'user',
    position: 1,
  },
}
export const ResourceUsageModeEnums = ['shareable', 'exclusive']
export const enum ResourceUsageModeEnum {
  shareable = 'shareable',
  exclusive = 'exclusive',
}
export const CustomFieldSourceTypeEnumObjects: ModelEnumObjects = {
  User: {
    position: 0,
  },
  Resource: {
    position: 1,
  },
  Visitor: {
    position: 2,
  },
  Reservation: {
    position: 3,
  },
}
export const CustomFieldSourceTypeEnums = [
  'User',
  'Resource',
  'Visitor',
  'Reservation',
]
export const enum CustomFieldSourceTypeEnum {
  User = 'User',
  Resource = 'Resource',
  Visitor = 'Visitor',
  Reservation = 'Reservation',
}
export const ResourceStateEnumObjects: ModelEnumObjects = {
  free: {
    color: 'green',
    position: 0,
  },
  assigned: {
    color: 'gray',
    position: 1,
  },
  disabled: {
    color: 'red',
    position: 2,
  },
}
export const ResourceStateEnums = ['free', 'assigned', 'disabled']
export const enum ResourceStateEnum {
  free = 'free',
  assigned = 'assigned',
  disabled = 'disabled',
}
export const ReservationSourceEnumObjects: ModelEnumObjects = {
  System: {
    position: 0,
  },
  User: {
    position: 1,
  },
  Admin: {
    position: 2,
  },
  Waitlist: {
    position: 3,
  },
  External: {
    position: 4,
  },
}
export const ReservationSourceEnums = [
  'System',
  'User',
  'Admin',
  'Waitlist',
  'External',
]
export const enum ReservationSourceEnum {
  System = 'System',
  User = 'User',
  Admin = 'Admin',
  Waitlist = 'Waitlist',
  External = 'External',
}
export const ReservationTypeEnumObjects: ModelEnumObjects = {
  Reservation: {
    position: 0,
  },
  Service: {
    position: 1,
  },
}
export const ReservationTypeEnums = ['Reservation', 'Service']
export const enum ReservationTypeEnum {
  Reservation = 'Reservation',
  Service = 'Service',
}
export const ReservationRecurrenceTypeEnumObjects: ModelEnumObjects = {
  Occurance: {
    position: 0,
  },
  Exception: {
    position: 1,
  },
}
export const ReservationRecurrenceTypeEnums = ['Occurance', 'Exception']
export const enum ReservationRecurrenceTypeEnum {
  Occurance = 'Occurance',
  Exception = 'Exception',
}
export const ReservationStatusEnumObjects: ModelEnumObjects = {
  approving: {
    color: 'orange',
    position: 0,
  },
  reserved: {
    color: 'blue',
    position: 1,
  },
  upcoming: {
    color: 'blue',
    position: 2,
  },
  starting_soon: {
    color: 'cyan',
    position: 3,
  },
  ongoing: {
    color: 'green',
    position: 4,
  },
  ending_soon: {
    color: 'pink',
    position: 5,
  },
  terminated: {
    color: 'gray',
    position: 6,
  },
  cancelled: {
    color: 'gray',
    position: 7,
  },
}
export const ReservationStatusEnums = [
  'approving',
  'reserved',
  'upcoming',
  'starting_soon',
  'ongoing',
  'ending_soon',
  'terminated',
  'cancelled',
]
export const enum ReservationStatusEnum {
  approving = 'approving',
  reserved = 'reserved',
  upcoming = 'upcoming',
  starting_soon = 'starting_soon',
  ongoing = 'ongoing',
  ending_soon = 'ending_soon',
  terminated = 'terminated',
  cancelled = 'cancelled',
}
export const ReservationTimeModeEnumObjects: ModelEnumObjects = {
  free: {
    position: 0,
  },
  fullDay: {
    position: 1,
  },
  timeslot: {
    position: 2,
  },
  crossDay: {
    position: 3,
  },
}
export const ReservationTimeModeEnums = [
  'free',
  'fullDay',
  'timeslot',
  'crossDay',
]
export const enum ReservationTimeModeEnum {
  free = 'free',
  fullDay = 'fullDay',
  timeslot = 'timeslot',
  crossDay = 'crossDay',
}
export const ReservationExternalSourceEnumObjects: ModelEnumObjects = {
  none: {
    position: 0,
  },
  office365: {
    position: 1,
  },
  exchange: {
    position: 2,
  },
  google: {
    position: 3,
  },
  feishu: {
    position: 4,
  },
}
export const ReservationExternalSourceEnums = [
  'none',
  'office365',
  'exchange',
  'google',
  'feishu',
]
export const enum ReservationExternalSourceEnum {
  none = 'none',
  office365 = 'office365',
  exchange = 'exchange',
  google = 'google',
  feishu = 'feishu',
}
export const AttendeeStatusEnumObjects: ModelEnumObjects = {
  not_reply: {
    position: 0,
  },
  attend: {
    position: 1,
  },
  not_attend: {
    position: 2,
  },
  possible_attend: {
    position: 3,
  },
}
export const AttendeeStatusEnums = [
  'not_reply',
  'attend',
  'not_attend',
  'possible_attend',
]
export const enum AttendeeStatusEnum {
  not_reply = 'not_reply',
  attend = 'attend',
  not_attend = 'not_attend',
  possible_attend = 'possible_attend',
}
export const AttendeeRoleEnumObjects: ModelEnumObjects = {
  host: {
    color: 'pink',
    position: 0,
  },
  participant: {
    position: 1,
  },
  cc: {
    position: 2,
  },
}
export const AttendeeRoleEnums = ['host', 'participant', 'cc']
export const enum AttendeeRoleEnum {
  host = 'host',
  participant = 'participant',
  cc = 'cc',
}
export const TaskPriorityEnumObjects: ModelEnumObjects = {
  None: {
    color: 'gray',
    icon: 'x',
    position: 0,
  },
  Low: {
    color: 'gray',
    icon: 'x',
    position: 1,
  },
  Normal: {
    color: 'gray',
    icon: 'ellipsis',
    position: 2,
  },
  High: {
    color: 'red',
    icon: 'x',
    position: 3,
  },
  Urgent: {
    color: 'red',
    icon: 'check',
    position: 4,
  },
}
export const TaskPriorityEnums = ['None', 'Low', 'Normal', 'High', 'Urgent']
export const enum TaskPriorityEnum {
  None = 'None',
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  Urgent = 'Urgent',
}
export const TaskSourceEnumObjects: ModelEnumObjects = {
  Management: {
    color: 'management',
    icon: 'managementIcon',
    position: 1,
  },
  WechatApp: {
    color: 'wechat',
    icon: 'wechatIcon',
    position: 2,
  },
  ControlPad: {
    color: 'pad',
    icon: 'padIcon',
    position: 3,
  },
  MeetingPad: {
    color: 'meetingPad',
    icon: 'meetingPadIcon',
    position: 4,
  },
  App: {
    color: 'app',
    icon: 'appIcon',
    position: 5,
  },
  Tenant: {
    color: 'tenant',
    icon: 'tenant',
    position: 6,
  },
  Reservation: {
    color: 'reservation',
    icon: 'reservation',
    position: 7,
  },
}
export const TaskSourceEnums = [
  'Management',
  'WechatApp',
  'ControlPad',
  'MeetingPad',
  'App',
  'Tenant',
  'Reservation',
]
export const enum TaskSourceEnum {
  Management = 'Management',
  WechatApp = 'WechatApp',
  ControlPad = 'ControlPad',
  MeetingPad = 'MeetingPad',
  App = 'App',
  Tenant = 'Tenant',
  Reservation = 'Reservation',
}
export const TaskStateEnumObjects: ModelEnumObjects = {
  Pending: {
    color: 'orange',
    icon: 'ellipsis',
    position: 0,
  },
  Received: {
    color: 'blue',
    icon: 'ellipsis',
    position: 1,
  },
  Completed: {
    color: 'green',
    icon: 'check',
    position: 2,
  },
  Rejected: {
    color: 'gray',
    icon: 'x',
    position: 3,
  },
}
export const TaskStateEnums = ['Pending', 'Received', 'Completed', 'Rejected']
export const enum TaskStateEnum {
  Pending = 'Pending',
  Received = 'Received',
  Completed = 'Completed',
  Rejected = 'Rejected',
}
export const DeliveryPriorityEnumObjects: ModelEnumObjects = {
  None: {
    color: 'gray',
    icon: 'x',
    position: 0,
  },
  Low: {
    color: 'gray',
    icon: 'x',
    position: 1,
  },
  Normal: {
    color: 'gray',
    icon: 'ellipsis',
    position: 2,
  },
  High: {
    color: 'red',
    icon: 'x',
    position: 3,
  },
  Urgent: {
    color: 'red',
    icon: 'check',
    position: 4,
  },
}
export const DeliveryPriorityEnums = ['None', 'Low', 'Normal', 'High', 'Urgent']
export const enum DeliveryPriorityEnum {
  None = 'None',
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  Urgent = 'Urgent',
}
export const DeliveryStateEnumObjects: ModelEnumObjects = {
  Pending: {
    color: 'orange',
    icon: 'ellipsis',
    position: 0,
  },
  PickedUp: {
    color: 'green',
    icon: 'check',
    position: 1,
  },
  Rejected: {
    color: 'gray',
    icon: 'x',
    position: 2,
  },
}
export const DeliveryStateEnums = ['Pending', 'PickedUp', 'Rejected']
export const enum DeliveryStateEnum {
  Pending = 'Pending',
  PickedUp = 'PickedUp',
  Rejected = 'Rejected',
}
export const CalendarTypeEnumObjects: ModelEnumObjects = {
  Google: {
    position: 0,
  },
  Outlook: {
    position: 1,
  },
}
export const CalendarTypeEnums = ['Google', 'Outlook']
export const enum CalendarTypeEnum {
  Google = 'Google',
  Outlook = 'Outlook',
}
export const ExternalCalendarSubscriptionRenewStatusEnumObjects: ModelEnumObjects =
  {
    Success: {
      color: 'green',
      position: 0,
    },
    Error: {
      color: 'red',
      position: 1,
    },
  }
export const ExternalCalendarSubscriptionRenewStatusEnums = ['Success', 'Error']
export const enum ExternalCalendarSubscriptionRenewStatusEnum {
  Success = 'Success',
  Error = 'Error',
}
export const AppCategoryEnumObjects: ModelEnumObjects = {
  SSO: {
    icon: 'log-in',
    position: 1,
  },
  Calendar: {
    icon: 'calendar-days',
    position: 2,
  },
  FileStorage: {
    icon: 'folder',
    position: 3,
  },
  Directory: {
    icon: 'layout-list',
    position: 4,
  },
  HR: {
    icon: 'users',
    position: 5,
  },
  OA: {
    icon: 'monitor',
    position: 6,
  },
  Automation: {
    icon: 'refresh-cw',
    position: 7,
  },
  VideoMeeting: {
    icon: 'square-play',
    position: 8,
  },
  Notifications: {
    icon: 'bell',
    position: 9,
  },
  SmartHardware: {
    icon: 'cpu',
    position: 10,
  },
  Analytics: {
    icon: 'layout-grid',
    position: 11,
  },
  Other: {
    icon: 'layout-grid',
    position: 12,
  },
}
export const AppCategoryEnums = [
  'SSO',
  'Calendar',
  'FileStorage',
  'Directory',
  'HR',
  'OA',
  'Automation',
  'VideoMeeting',
  'Notifications',
  'SmartHardware',
  'Analytics',
  'Other',
]
export const enum AppCategoryEnum {
  SSO = 'SSO',
  Calendar = 'Calendar',
  FileStorage = 'FileStorage',
  Directory = 'Directory',
  HR = 'HR',
  OA = 'OA',
  Automation = 'Automation',
  VideoMeeting = 'VideoMeeting',
  Notifications = 'Notifications',
  SmartHardware = 'SmartHardware',
  Analytics = 'Analytics',
  Other = 'Other',
}
export const CalendarSyncDirectionEnumObjects: ModelEnumObjects = {
  Inbound: {
    position: 0,
  },
  Outbound: {
    position: 1,
  },
}
export const CalendarSyncDirectionEnums = ['Inbound', 'Outbound']
export const enum CalendarSyncDirectionEnum {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}
export const CalendarSyncStatusEnumObjects: ModelEnumObjects = {
  Success: {
    color: 'green',
    position: 0,
  },
  Error: {
    color: 'red',
    position: 1,
  },
}
export const CalendarSyncStatusEnums = ['Success', 'Error']
export const enum CalendarSyncStatusEnum {
  Success = 'Success',
  Error = 'Error',
}
export const CalendarSyncActionEnumObjects: ModelEnumObjects = {
  Create: {
    position: 0,
  },
  Update: {
    position: 1,
  },
  Delete: {
    position: 2,
  },
}
export const CalendarSyncActionEnums = ['Create', 'Update', 'Delete']
export const enum CalendarSyncActionEnum {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}
export const PolicyTypeEnumObjects: ModelEnumObjects = {
  ReservationReserveRule: {
    position: 0,
  },
  ReservationInfoUpdateRule: {
    position: 1,
  },
  ReservationCheckInRule: {
    position: 2,
  },
  ReservationSecurityRule: {
    position: 3,
  },
  ReservationFormRule: {
    position: 4,
  },
  ReservationBlacklistRule: {
    position: 5,
  },
  ReservationSupportRule: {
    position: 6,
  },
  TReservationServiceRule: {
    position: 7,
  },
}
export const PolicyTypeEnums = [
  'ReservationReserveRule',
  'ReservationInfoUpdateRule',
  'ReservationCheckInRule',
  'ReservationSecurityRule',
  'ReservationFormRule',
  'ReservationBlacklistRule',
  'ReservationSupportRule',
  'TReservationServiceRule',
]
export const enum PolicyTypeEnum {
  ReservationReserveRule = 'ReservationReserveRule',
  ReservationInfoUpdateRule = 'ReservationInfoUpdateRule',
  ReservationCheckInRule = 'ReservationCheckInRule',
  ReservationSecurityRule = 'ReservationSecurityRule',
  ReservationFormRule = 'ReservationFormRule',
  ReservationBlacklistRule = 'ReservationBlacklistRule',
  ReservationSupportRule = 'ReservationSupportRule',
  TReservationServiceRule = 'TReservationServiceRule',
}
export const ViolationTypeEnumObjects: ModelEnumObjects = {
  reserved_not_usage_count: {
    position: 0,
  },
  reserved_not_usage_minutes: {
    position: 1,
  },
}
export const ViolationTypeEnums = [
  'reserved_not_usage_count',
  'reserved_not_usage_minutes',
]
export const enum ViolationTypeEnum {
  reserved_not_usage_count = 'reserved_not_usage_count',
  reserved_not_usage_minutes = 'reserved_not_usage_minutes',
}
export const ViolationStatusEnumObjects: ModelEnumObjects = {
  active: {
    color: 'green',
    position: 0,
  },
  inactive: {
    color: 'red',
    position: 1,
  },
}
export const ViolationStatusEnums = ['active', 'inactive']
export const enum ViolationStatusEnum {
  active = 'active',
  inactive = 'inactive',
}
export const BlacklistTypeEnumObjects: ModelEnumObjects = {
  user: {
    position: 0,
  },
  department: {
    position: 1,
  },
}
export const BlacklistTypeEnums = ['user', 'department']
export const enum BlacklistTypeEnum {
  user = 'user',
  department = 'department',
}
export const BlacklistSourceEnumObjects: ModelEnumObjects = {
  system: {
    position: 0,
  },
  admin: {
    position: 1,
  },
}
export const BlacklistSourceEnums = ['system', 'admin']
export const enum BlacklistSourceEnum {
  system = 'system',
  admin = 'admin',
}
export const BlacklistOperationEnumObjects: ModelEnumObjects = {
  add_to_blacklist: {
    position: 0,
  },
  remove_from_blacklist: {
    position: 1,
  },
}
export const BlacklistOperationEnums = [
  'add_to_blacklist',
  'remove_from_blacklist',
]
export const enum BlacklistOperationEnum {
  add_to_blacklist = 'add_to_blacklist',
  remove_from_blacklist = 'remove_from_blacklist',
}
export const WaitlistStatusEnumObjects: ModelEnumObjects = {
  pending: {
    color: 'orange',
    icon: 'circle-ellipsis',
    position: 0,
  },
  pending_confirm: {
    color: 'blue',
    icon: 'circle-ellipsis',
    position: 1,
  },
  confirmed: {
    color: 'green',
    icon: 'circle-check',
    position: 2,
  },
  cancelled: {
    color: 'gray',
    icon: 'circle-x',
    position: 3,
  },
  failed: {
    color: 'gray',
    icon: 'circle-x',
    position: 4,
  },
}
export const WaitlistStatusEnums = [
  'pending',
  'pending_confirm',
  'confirmed',
  'cancelled',
  'failed',
]
export const enum WaitlistStatusEnum {
  pending = 'pending',
  pending_confirm = 'pending_confirm',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  failed = 'failed',
}
export const EndReasonEnumObjects: ModelEnumObjects = {
  cancel_by_self: {
    position: 0,
  },
  timeout_unconfirmed: {
    position: 1,
  },
  waited_reservation_ended_normally: {
    position: 2,
  },
  waited_reservation_reserved_by_other: {
    position: 3,
  },
  waited_reservation_reserve_failed: {
    position: 4,
  },
  waitlist_reservation_cron_cancelled: {
    position: 5,
  },
  cancel_waitlist_reservation_by_self: {
    position: 6,
  },
  cancel_by_admin: {
    position: 7,
  },
  cancel_waitlist_reservation_by_admin: {
    position: 8,
  },
}
export const EndReasonEnums = [
  'cancel_by_self',
  'timeout_unconfirmed',
  'waited_reservation_ended_normally',
  'waited_reservation_reserved_by_other',
  'waited_reservation_reserve_failed',
  'waitlist_reservation_cron_cancelled',
  'cancel_waitlist_reservation_by_self',
  'cancel_by_admin',
  'cancel_waitlist_reservation_by_admin',
]
export const enum EndReasonEnum {
  cancel_by_self = 'cancel_by_self',
  timeout_unconfirmed = 'timeout_unconfirmed',
  waited_reservation_ended_normally = 'waited_reservation_ended_normally',
  waited_reservation_reserved_by_other = 'waited_reservation_reserved_by_other',
  waited_reservation_reserve_failed = 'waited_reservation_reserve_failed',
  waitlist_reservation_cron_cancelled = 'waitlist_reservation_cron_cancelled',
  cancel_waitlist_reservation_by_self = 'cancel_waitlist_reservation_by_self',
  cancel_by_admin = 'cancel_by_admin',
  cancel_waitlist_reservation_by_admin = 'cancel_waitlist_reservation_by_admin',
}
export const ReservationActionEnumObjects: ModelEnumObjects = {
  Create: {
    position: 0,
  },
  Update: {
    position: 1,
  },
  Checkin: {
    position: 2,
  },
  Checkout: {
    position: 3,
  },
  Cancel: {
    position: 4,
  },
}
export const ReservationActionEnums = [
  'Create',
  'Update',
  'Checkin',
  'Checkout',
  'Cancel',
]
export const enum ReservationActionEnum {
  Create = 'Create',
  Update = 'Update',
  Checkin = 'Checkin',
  Checkout = 'Checkout',
  Cancel = 'Cancel',
}
export const ReservationLogSourceEnumObjects: ModelEnumObjects = {
  System: {
    position: 0,
  },
  User: {
    position: 1,
  },
  Admin: {
    position: 2,
  },
  Waitlist: {
    position: 3,
  },
  External: {
    position: 4,
  },
  Approval: {
    position: 5,
  },
  Cron: {
    position: 6,
  },
  Service: {
    position: 7,
  },
}
export const ReservationLogSourceEnums = [
  'System',
  'User',
  'Admin',
  'Waitlist',
  'External',
  'Approval',
  'Cron',
  'Service',
]
export const enum ReservationLogSourceEnum {
  System = 'System',
  User = 'User',
  Admin = 'Admin',
  Waitlist = 'Waitlist',
  External = 'External',
  Approval = 'Approval',
  Cron = 'Cron',
  Service = 'Service',
}

// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { UserAdminAutoCompleteQueryResponse, UserAdminAutoCompleteQueryParams, UserAdminAutoComplete401 } from "../models/UserAdminAutoComplete";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type UserAdminAutoCompleteClient = typeof client<UserAdminAutoCompleteQueryResponse, UserAdminAutoComplete401, never>;
type UserAdminAutoComplete = {
    data: UserAdminAutoCompleteQueryResponse;
    error: UserAdminAutoComplete401;
    request: never;
    pathParams: never;
    queryParams: UserAdminAutoCompleteQueryParams;
    headerParams: never;
    response: UserAdminAutoCompleteQueryResponse;
    client: {
        parameters: Partial<Parameters<UserAdminAutoCompleteClient>[0]>;
        return: Awaited<ReturnType<UserAdminAutoCompleteClient>>;
    };
};
export const userAdminAutoCompleteQueryKey = (params: UserAdminAutoComplete["queryParams"]) => ["api", "v5", "admin", "users", "autocomplete", ...(params ? [params] : [])] as const;
export type UserAdminAutoCompleteQueryKey = ReturnType<typeof userAdminAutoCompleteQueryKey>;
export function userAdminAutoCompleteQueryOptions(params: UserAdminAutoComplete["queryParams"], options: UserAdminAutoComplete["client"]["parameters"] = {}) {
    const queryKey = userAdminAutoCompleteQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserAdminAutoComplete["data"], UserAdminAutoComplete["error"]>({
                method: "get",
                url: `/api/v5/admin/users/autocomplete`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 按Email或姓名搜索用户
 * @link /api/v5/admin/users/autocomplete
 */
export function useUserAdminAutoComplete<TData = UserAdminAutoComplete["response"], TQueryData = UserAdminAutoComplete["response"], TQueryKey extends QueryKey = UserAdminAutoCompleteQueryKey>(params: UserAdminAutoComplete["queryParams"], options: {
    query?: Partial<QueryObserverOptions<UserAdminAutoComplete["response"], UserAdminAutoComplete["error"], TData, TQueryData, TQueryKey>>;
    client?: UserAdminAutoComplete["client"]["parameters"];
} = {}): UseQueryResult<TData, UserAdminAutoComplete["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userAdminAutoCompleteQueryKey(params);
    const query = useQuery({
        ...userAdminAutoCompleteQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, UserAdminAutoComplete["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const userAdminAutoCompleteSuspenseQueryKey = (params: UserAdminAutoComplete["queryParams"]) => ["api", "v5", "admin", "users", "autocomplete", ...(params ? [params] : [])] as const;
export type UserAdminAutoCompleteSuspenseQueryKey = ReturnType<typeof userAdminAutoCompleteSuspenseQueryKey>;
export function userAdminAutoCompleteSuspenseQueryOptions(params: UserAdminAutoComplete["queryParams"], options: UserAdminAutoComplete["client"]["parameters"] = {}) {
    const queryKey = userAdminAutoCompleteSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserAdminAutoComplete["data"], UserAdminAutoComplete["error"]>({
                method: "get",
                url: `/api/v5/admin/users/autocomplete`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 按Email或姓名搜索用户
 * @link /api/v5/admin/users/autocomplete
 */
export function useUserAdminAutoCompleteSuspense<TData = UserAdminAutoComplete["response"], TQueryKey extends QueryKey = UserAdminAutoCompleteSuspenseQueryKey>(params: UserAdminAutoComplete["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<UserAdminAutoComplete["response"], UserAdminAutoComplete["error"], TData, TQueryKey>>;
    client?: UserAdminAutoComplete["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, UserAdminAutoComplete["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userAdminAutoCompleteSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...userAdminAutoCompleteSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, UserAdminAutoComplete["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import React, { Suspense } from 'react'

// eslint-disable-next-line typescriptEslint/no-explicit-any
export function lazyLoad(Component: React.LazyExoticComponent<any>) {
  return (
    <Suspense fallback={<KBSuspenseLoading />}>
      <Component />
    </Suspense>
  )
}

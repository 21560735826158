import { atomWithStorage, createJSONStorage } from 'jotai/utils'

export const themeAtom = atomWithStorage<string>(
  'theme',
  'light',
  createJSONStorage(),
  {
    getOnInit: true,
  }
)

import dayjs, { Dayjs, findClosestTimeWithStep } from '@wox/dayjs'
import { MenuProps } from 'antd'

import {
  CreateResourceReservationDtoTimeMode,
  ReservationShowEntity,
  ResourceEntity,
  TReservationReserveRule,
  UpdateResourceReservationDtoAction,
} from '@/api'
import {
  KBButton,
  KBDropdown,
  KBFlex,
  KBForm,
  KBIcon,
  KBText,
} from '@/components/atoms'
import {
  KBTimeRangePicker,
  RangePickerMode,
} from '@/components/atoms/KBTimeRangePicker'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

import { getRecurrenceDescription } from '@/components/organisms/KBRecurrenceEditModal/constants'
import KBRecurrenceDropdown from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceDropdown'
import KBRecurrenceEditModal, {
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import KBTimezoneSelect from '@/components/organisms/KBTimezoneSelect/KBTimezoneSelect'
import { ReservationTimeModeEnum } from '@/enums/modelEnums'

export interface TimeSlotItem {
  startAt?: Dayjs
  endAt?: Dayjs
  timeMode: CreateResourceReservationDtoTimeMode
  timeZone: string
  recurrenceInfo?: RecurrenceInfo
}

export interface TimeSlotSelectProps {
  recurringEditType?: UpdateResourceReservationDtoAction
  value?: TimeSlotItem
  groupEditType?: GroupEditType
  onChange?: (data?: TimeSlotItem) => void
  reservationInfo?: ReservationShowEntity
  resourcesList: ResourceEntity[]
}

const TimeSlots: React.FC<TimeSlotSelectProps> = (props) => {
  console.log('>>> TimeSlots', props.value?.startAt?.format('MM-DD HH:mm'))
  const startAt = props.value?.startAt || dayjs()
  const step = 60
  const {
    value = {
      startAt: findClosestTimeWithStep(startAt, step),
      endAt: startAt.add(step, 'minute'),
      timeMode: ReservationTimeModeEnum.free,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    groupEditType,
    recurringEditType,
    reservationInfo,
    resourcesList,
    onChange,
  } = props

  console.log('>>> value startAt', value.startAt?.format('MM-DD HH:mm'))
  console.log('>>> value endAt', value.endAt?.format('MM-DD HH:mm'))

  const [minTimeStep, setMinTimeStep] = useState<number>(60)

  const TimeModesDescription = {
    [ReservationTimeModeEnum.free]: tr('reservation.timeByFree'),
    [ReservationTimeModeEnum.fullDay]: tr('reservation.timeByFullDay'),
    [ReservationTimeModeEnum.crossDay]: tr('reservation.timeByCrossDay'),
    [ReservationTimeModeEnum.timeslot]: tr('reservation.timeByTimeslot'),
  }

  const timeModeOptions: MenuProps['items'] = [
    {
      key: ReservationTimeModeEnum.free,
      label: (
        <KBText>{TimeModesDescription[ReservationTimeModeEnum.free]} </KBText>
      ),
    },
    {
      key: ReservationTimeModeEnum.fullDay,
      label: (
        <KBText>
          {TimeModesDescription[ReservationTimeModeEnum.fullDay]}{' '}
        </KBText>
      ),
    },
    {
      key: ReservationTimeModeEnum.crossDay,
      label: (
        <KBText>
          {TimeModesDescription[ReservationTimeModeEnum.crossDay]}{' '}
        </KBText>
      ),
    },
  ]

  const onTimeModeClick: MenuProps['onClick'] = (e) => {
    const key = e.key as ReservationTimeModeEnum
    // 根据时间模式修改上面的时间选择器
    if (key === ReservationTimeModeEnum.fullDay) {
      onChange?.({
        ...value,
        timeMode: key,
        startAt: (value.startAt || dayjs()).startOf('day'),
        endAt: dayjs(value.endAt || dayjs()).endOf('day'),
      })
    } else if (key === ReservationTimeModeEnum.crossDay) {
      onChange?.({
        ...value,
        timeMode: key,
        endAt: dayjs(value.startAt).add(24, 'hours'),
      })
    } else if (key === ReservationTimeModeEnum.timeslot) {
      // FIXME:
      alert('开发中')
      // FIXME批量预定不允许按场次预定
      onChange?.({
        ...value,
        timeMode: key,
        // endAt: dayjs(value.startAt).add(24, 'hours'),
      })
    } else {
      // 当前时间往后推最小预定时长
      const startAt = findClosestTimeWithStep(
        value.startAt || dayjs(),
        minTimeStep
      )
      const endAt = startAt.add(minTimeStep, 'minute')

      onChange?.({
        ...value,
        timeMode: key,
        startAt,
        endAt,
      })
    }
  }

  const timeZoneChange = (val: string) => {
    onChange?.({ ...value, timeZone: val })
  }

  const disabled = useMemo(() => {
    if (!reservationInfo) return false
    const { is_group } = reservationInfo
    return groupEditType === 'current'
  }, [reservationInfo])

  const renderTimePicker = () => {
    const types = {
      [ReservationTimeModeEnum.free]: RangePickerMode.DateTimePicker,
      [ReservationTimeModeEnum.fullDay]: RangePickerMode.DatePicker,
      [ReservationTimeModeEnum.crossDay]: RangePickerMode.CrossDatePicker,
      [ReservationTimeModeEnum.timeslot]: RangePickerMode.TimeSegmentPicker,
    }

    return (
      <KBTimeRangePicker
        value={[value.startAt, value.endAt]}
        mode={types[value.timeMode]}
        disabled={disabled}
        step={90}
        onChange={(start, end) => {
          console.log(
            '>>> KBTimeRangePicker onChange',
            start?.format('MM-DD HH:mm'),
            end?.format('MM-DD HH:mm')
          )
          onChange?.({
            ...value,
            startAt: start,
            endAt: end,
          })
        }}
      />
    )
  }

  useEffect(() => {
    if (resourcesList.length === 1) {
      const [resource] = resourcesList
      setMinTimeStep(
        (resource.reservation_reserve_rule.rules as TReservationReserveRule)
          ?.reserve_duration_limit_rule?.min_duration || 60
      )
    }
  }, [resourcesList])

  return (
    <>
      <KBFlex align="start" gap={6}>
        <KBFlex vertical gap={8}>
          {renderTimePicker()}

          <KBFlex gap={8}>
            <KBDropdown
              menu={{
                items: timeModeOptions,
                onClick: onTimeModeClick,
              }}
              placement="bottom"
              trigger={['click']}
              disabled={disabled}
            >
              <KBButton icon={<KBIcon name="calendar-clock" />}>
                {
                  TimeModesDescription[
                    value?.timeMode as ReservationTimeModeEnum
                  ]
                }
              </KBButton>
            </KBDropdown>
            <KBRecurrenceDropdown
              value={value.recurrenceInfo}
              disabled={disabled || recurringEditType === 'current'}
              onChange={(info) => {
                onChange?.({ ...value, recurrenceInfo: info })
              }}
            />
            <KBTimezoneSelect
              className="tw-w-[191px]"
              onChange={(e) => timeZoneChange(e.value)}
              disabled={disabled}
            />
          </KBFlex>
          {/* <KBText>{repeatDescription}</KBText> */}
          {value.recurrenceInfo && (
            <KBText>{getRecurrenceDescription(value.recurrenceInfo)}</KBText>
          )}
        </KBFlex>
      </KBFlex>
      <KBRecurrenceEditModal id="KBRecurrenceEditModal" />
    </>
  )
}

/**
 * 时间组件
 */
const TimeSlotSelect: React.FC<
  TimeSlotSelectProps & {
    errorsMessages?: string
  }
> = (props) => {
  const { errorsMessages } = props

  return (
    <KBForm.Item
      name="timeMode"
      label={<KBIcon size={24} name="clock" />}
      dependencies={['resourceIds']}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <TimeSlots {...props} />
    </KBForm.Item>
  )
}

export default TimeSlotSelect

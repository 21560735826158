import { CLOUDFLARE_IMAGE_URL } from '@/utils/constants'
import { Image, ImageProps } from 'antd'

export interface KBImageProps extends ImageProps {}
const THUMBNAIL_SIZES = [100, 200, 300]

const transformImage = (src: string, size: number) => {
  return `${CLOUDFLARE_IMAGE_URL}fit=contain,width=${size}/${src}`
}

const SRC_SET_SIZES = [320, 640, 960, 1280, 2560]
export function KBImage(props: KBImageProps) {
  const { src, width, ...restProps } = props

  const srcSetString = props.src?.startsWith('https://')
    ? SRC_SET_SIZES.map(
        (size) =>
          `${CLOUDFLARE_IMAGE_URL}fit=contain,width=${size}/${props.src} ${size}w`
      ).join(',')
    : ''

  const transformedSrc =
    width && props.src?.startsWith('https://')
      ? transformImage(props.src, Number(width) * 2)
      : props.src

  return (
    <Image
      {...restProps}
      src={transformedSrc}
      width={width}
      srcSet={srcSetString}
    ></Image>
  )
}

KBImage.PreviewGroup = Image.PreviewGroup

import { MenuProps } from 'antd'

import {
  RecurringEventDto,
  RecurringEventDtoByweekday,
  RecurringEventDtoFreq,
} from '@/api'
import { KBText } from '@/components/atoms'
import { RecurrenceInfo } from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import { formatDate, Frequency, nameOfDay } from '@wox/dayjs'

export enum RecurrenceInterval {
  day = Frequency.DAILY,
  week = Frequency.WEEKLY,
  month = Frequency.MONTHLY,
  noRecurrence = -1,
}

export const getRecurrenceIntervalDescription = (
  interval?: RecurrenceInterval
): string => {
  switch (interval) {
    case RecurrenceInterval.noRecurrence:
      return tr('recurrence.noRecurrence')
    case RecurrenceInterval.day:
      return tr('recurrence.day')
    case RecurrenceInterval.week:
      return tr('recurrence.week')
    case RecurrenceInterval.month:
      return tr('recurrence.month')
    default:
      return tr('recurrence.noRecurrence')
  }
}

export const getRecurrenceIntervalOptions = (): MenuProps['items'] => {
  return [
    RecurrenceInterval.noRecurrence,
    RecurrenceInterval.day,
    RecurrenceInterval.week,
    RecurrenceInterval.month,
  ].map((interval: RecurrenceInterval) => {
    return {
      key: interval,
      label: <KBText>{getRecurrenceIntervalDescription(interval)}</KBText>,
    }
  })
}

export enum RecurrenceByMonthInterval {
  /**
   * @description 按每月的第几天
   */
  day,
  /**
   * @description 按每月的第几个周几
   */
  week,
}

/**
 * 将 RecurrenceInfo 转换为 RecurringEventDto
 * @param recurrenceInfo
 * @param timeZone
 * @returns
 */
export const recurrenceInfoToDto = (
  recurrenceInfo?: RecurrenceInfo,
  timeZone?: string
): RecurringEventDto | undefined => {
  let recurringEventDto: RecurringEventDto | undefined = undefined
  if (
    recurrenceInfo &&
    recurrenceInfo?.intervalType !== RecurrenceInterval.noRecurrence
  ) {
    recurringEventDto = {
      interval: recurrenceInfo.interval as number,
      freq: recurrenceInfo.intervalType as RecurringEventDtoFreq,
      until: recurrenceInfo?.endDate?.format('YYYY-MM-DD'),
      tzid: timeZone,
      bymonthday:
        recurrenceInfo?.intervalType === RecurrenceInterval.month &&
        recurrenceInfo.monthDay
          ? [recurrenceInfo.monthDay]
          : undefined,
      bysetpos: recurrenceInfo?.monthWeekNumber
        ? [recurrenceInfo?.monthWeekNumber]
        : undefined,
      byweekday:
        recurrenceInfo?.intervalType === RecurrenceInterval.week
          ? (recurrenceInfo?.selectedWeekdays as RecurringEventDtoByweekday[])
          : recurrenceInfo?.monthWeekDay
            ? [recurrenceInfo?.monthWeekDay as RecurringEventDtoByweekday]
            : undefined,
    }
  }
  return recurringEventDto
}

/**
 * 根据重复条件获取具体的文案描述
 */
export function getRecurrenceDescription(recurrenceInfo?: RecurrenceInfo) {
  if (!recurrenceInfo) return ''

  const { startDate: startTime, intervalType: type } = recurrenceInfo

  if (
    type === RecurrenceInterval.day &&
    recurrenceInfo.endDate &&
    recurrenceInfo.interval
  ) {
    return tr('recurrence.byDayDescription', {
      interval: recurrenceInfo.interval,
      endDate: formatDate(recurrenceInfo.endDate.toDate()),
    })
  } else if (
    type === RecurrenceInterval.week &&
    recurrenceInfo.endDate &&
    recurrenceInfo.interval &&
    recurrenceInfo.selectedWeekdays?.length
  ) {
    return tr('recurrence.byWeekDescription', {
      interval: recurrenceInfo.interval,
      weekdays: recurrenceInfo.selectedWeekdays
        ?.map((weekday) => nameOfDay(weekday, { weekdayFormat: 'short' }))
        .join(', '),
      endDate: formatDate(recurrenceInfo.endDate.toDate()),
    })
  } else if (type === RecurrenceInterval.month) {
    if (
      recurrenceInfo.monthRecurrenceByMonthInterval ===
      RecurrenceByMonthInterval.day
    ) {
      return tr('recurrence.byMonthDayDescription', {
        interval: recurrenceInfo.interval,
        day: startTime.date(),
        endDate: formatDate(recurrenceInfo.endDate.toDate()),
      })
    } else {
      return tr('recurrence.byMonthWeekDescription', {
        interval: recurrenceInfo.interval,
        week: startTime.isoWeek() - startTime.startOf('month').isoWeek(),
        weekday: nameOfDay(startTime.weekday(), { weekdayFormat: 'short' }),
        endDate: formatDate(recurrenceInfo.endDate.toDate()),
      })
    }
  } else {
    return ''
  }
}

import { KBSpin } from '@/components/atoms/KBSpin'
import { KBEmpty } from '@/components/organisms/KBEmpty'
import { List, ListProps } from 'antd'

export interface KBListProps<T> extends ListProps<T> {}

export function KBList<T>(props: KBListProps<T>) {
  return (
    <List
      locale={{
        emptyText: !props.loading ? <KBEmpty /> : <KBSpin />,
      }}
      {...props}
    ></List>
  )
}

KBList.Item = List.Item

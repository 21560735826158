import { KBButton, KBFlex } from '@/components/atoms'
import {
  RecurrenceByMonthInterval,
  RecurrenceInterval,
} from '@/components/organisms/KBRecurrenceEditModal/constants'
import {
  KBRecurrenceEditModalProps,
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import dayjs, { nameOfDay } from '@wox/dayjs'
import { FunctionComponent } from 'react'

interface KBRecurrenceByMonthProps extends KBRecurrenceEditModalProps {
  recurrenceInfo: RecurrenceInfo
  setRecurrenceInfo: (info: RecurrenceInfo) => void
}

const KBRecurrenceByMonth: FunctionComponent<KBRecurrenceByMonthProps> = (
  props
) => {
  const { recurrenceInfo, setRecurrenceInfo } = props
  const startTime = recurrenceInfo.startDate || dayjs()

  return (
    <KBFlex justify="flex-start" className="tw-mt-3 tw-pl-[64px]" gap={6}>
      <KBButton
        type={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.day
            ? 'primary'
            : 'default'
        }
        onClick={() => {
          // setAllValues({
          //   ...allValues,
          //   monthDay: startTime.date(),
          //   monthWeekNumber: undefined,
          //   monthWeekDay: undefined,
          // })
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: startTime.date(),
            monthWeekNumber: undefined,
            monthWeekDay: undefined,
            intervalType: RecurrenceInterval.month,
            monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
          })
        }}
      >
        {tr('recurrence.monthDay', { date: startTime.date() })}
      </KBButton>
      <KBButton
        type={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.week
            ? 'primary'
            : 'default'
        }
        onClick={() => {
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: undefined,
            monthWeekNumber:
              startTime.isoWeek() - startTime.startOf('month').isoWeek(),
            monthWeekDay: startTime.isoWeekday() - 1,
            intervalType: RecurrenceInterval.month,
            monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.week,
          })
        }}
      >
        {tr(
          `[intl]第${startTime.isoWeek() - startTime.startOf('month').isoWeek()}个${nameOfDay(startTime.weekday())}`
        )}
      </KBButton>
    </KBFlex>
  )
}

export default KBRecurrenceByMonth

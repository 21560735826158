import { Button, ButtonProps } from 'antd'
import { Fragment } from 'react/jsx-runtime'

import { KBFlex } from '@/components/atoms/KBFlex'

export interface KBButtonProps extends ButtonProps {
  gap?: number
}

export const KBButton = forwardRef<HTMLButtonElement, KBButtonProps>(
  (props, ref) => {
    const {
      icon,
      children,
      gap = 8,
      iconPosition = 'start',
      ...otherProps
    } = props
    return (
      <Button size="large" ref={ref} {...otherProps}>
        <KBFlex align="center" justify="center" gap={gap}>
          {iconPosition === 'start' ? (
            <Fragment>
              {icon}
              {children}
            </Fragment>
          ) : (
            <Fragment>
              {children}
              {icon}
            </Fragment>
          )}
        </KBFlex>
      </Button>
    )
  }
)

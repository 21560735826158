import { currentLocationIdAtom, currentUserDataAtom } from '@/store'
import { setCurrentLocationId } from '@/utils/axios'
import { isUserClient } from '@/utils/userEnvironment'
import { useAtom } from 'jotai'

/**
 * 当前分店ID
 *
 * @returns
 */
const useCurrentLocationId = () => {
  const params = useParams()
  const [locationId] = useAtom(currentLocationIdAtom)
  const [currentUserData] = useAtom(currentUserDataAtom)

  // user routes, no location id in params
  if (isUserClient()) {
    return locationId || currentUserData?.location_id
  }

  // admin routes
  const currentLocationId = params.location_id
    ? Number(params.location_id)
    : undefined

  setCurrentLocationId(currentLocationId)
  return currentLocationId
}

export default useCurrentLocationId

import { antdModal, NiceModalHandler } from '@ebay/nice-modal-react'
import { Modal, ModalProps } from 'antd'
import { LucideX } from 'lucide-react'

export interface KBModalProps extends ModalProps {
  modal: NiceModalHandler
  subTitle?: string
}

/**
 * Modal组件，样式根据antd改为上下居中、添加内容区域最大高度、过渡动画改为fade
 */
export function KBModal({ children, modal, ...otherProps }: KBModalProps) {
  // eslint-disable-next-line typescriptEslint/no-explicit-any
  const modalValues = antdModal(modal) as any

  // Rename visible to open as it's deprecated
  modalValues.open = modalValues.visible
  delete modalValues.visible

  return (
    <Modal
      maskClosable={false}
      closeIcon={<LucideX size={16} />}
      transitionName="ant-fade"
      okButtonProps={{ className: 'tw-h-9' }}
      cancelButtonProps={{ type: 'text', className: 'tw-h-9' }}
      {...modalValues}
      {...otherProps}
    >
      {children}
    </Modal>
  )
}

import React from 'react'

import {
  KBButton,
  KBFlex,
  KBIcon,
  KBSpin,
  KBText,
  KBTypography,
} from '@/components/atoms'
const { Title, Text, Link } = KBTypography

import { AuthUserEntity, useAuthFirstLogin } from '@/api'
import { tr } from '@/utils/localization/tr'
import WoxIconLogo from '~icons/wox-icons/woxLogo.svg'

const AuthFirstLogin: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const authUser = state?.user as AuthUserEntity

  const { mutate: firstLogin, isPending } = useAuthFirstLogin()

  useEffect(() => {
    firstLogin({
      data: {
        email: authUser.email!,
        space_id: authUser.space?.id!,
      },
    })
  }, [])

  return (
    <KBFlex
      vertical
      gap={32}
      justify="center"
      align="center"
      className="tw-m-auto"
    >
      <KBFlex align="center" gap={24} vertical>
        <WoxIconLogo />
      </KBFlex>
      <KBFlex
        vertical
        justify="center"
        align="center"
        className="tw-m-auto tw-w-[350px] tw-rounded-lg tw-border tw-border-solid tw-border-gray-200  tw-p-10 tw-shadow"
      >
        <KBFlex vertical align="center" gap={20}>
          <KBFlex
            justify="center"
            align="center"
            className="tw-h-[48px] tw-w-[48px] tw-rounded-full tw-bg-red-100"
          >
            <KBIcon name="triangle-alert" className="tw-text-orange-600" />
          </KBFlex>
          <KBFlex vertical align="center" gap={8}>
            <Title level={5} className="tw-m-0">
              {tr('login.firstLoginTitle')}
            </Title>
            {!isPending ? (
              <KBText className="tw-text-gray-500">
                {tr('login.firstLoginDescription')}
              </KBText>
            ) : (
              <KBSpin />
            )}
          </KBFlex>
          <KBButton type="primary" onClick={() => navigate('/')}>
            {tr('common.gotIt')}
          </KBButton>
        </KBFlex>
      </KBFlex>
    </KBFlex>
  )
}

export default AuthFirstLogin

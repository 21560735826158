import intl from 'react-intl-universal'

// easier format message
// eslint-disable-next-line typescriptEslint/no-explicit-any
export function tr(messageId: string, params?: any) {
  return intl.get(messageId, params) || `tr(${messageId})`
}

// easier format message
// eslint-disable-next-line typescriptEslint/no-explicit-any
export function trHTML(messageId: string, params?: any) {
  return intl.getHTML(messageId, params) || `tr(${messageId})`
}

// 国际化：对model进行操作
function trAction(action: string, model: string) {
  return tr(`model.actions.${action}`, { model: tr(`${model}`) })
}

// 国际化：所有model
export function trAll(model: string) {
  return tr(`model.actions.all`, { model: tr(`${model}`) })
}
// 国际化：新增model
export function trAdd(model: string) {
  return tr(`model.actions.add`, { model: tr(`${model}`) })
}
// 国际化：编辑model
export function trEdit(model: string) {
  return tr(`model.actions.edit`, { model: tr(`${model}`) })
}
// 国际化：删除model
export function trDelete(model: string) {
  return tr(`model.actions.delete`, { model: tr(`${model}`) })
}
// 国际化：选择model
export function trSelect(model: string) {
  return trAction('select', model)
}
// 国际化：填写model
export function trInput(model: string) {
  return trAction('input', model)
}
// 国际化：搜索model
export function trSearch(model: string) {
  return trAction('search', model)
}

import { Radio, RadioProps } from 'antd'

export interface KBRadioProps extends RadioProps {}

export function KBRadio(props: KBRadioProps) {
  return <Radio {...props}></Radio>
}

KBRadio.Group = Radio.Group
KBRadio.Button = Radio.Button

import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import {
  currentRefreshTokenAtom,
  currentSessionTokenAtom,
  currentUserTokenAtom,
} from '@/store'

export const AUTH_STORAGE_KEY = 'temp_auth'

/**
 * 处理App集成授权结果，和用户登录无关
 * @returns
 */
const AppAuthorizationResult = () => {
  const location = useLocation()
  const setUserToken = useSetAtom(currentUserTokenAtom)
  const setRefreshToken = useSetAtom(currentRefreshTokenAtom)
  const setSessionToken = useSetAtom(currentSessionTokenAtom)

  const params = new URLSearchParams(location.search)
  // const redirect_uri = params.get('redirect_uri')
  const error = params.get('error')
  // const token = params.get('token')
  // const refreshToken = params.get('refreshToken')
  // const sessionToken = params.get('sessionToken')

  useEffect(() => {
    if (error) {
      localStorage.setItem(
        AUTH_STORAGE_KEY,
        JSON.stringify({
          result: 'failure',
          error,
        })
      )
      window.close()
    } else {
      localStorage.setItem(
        AUTH_STORAGE_KEY,
        JSON.stringify({
          result: 'success',
        })
      )
      window.close()
    }
  }, [])

  return null
}

export default AppAuthorizationResult

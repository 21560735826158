import React from 'react'
import { Outlet } from 'react-router-dom'

import { KBFlex } from '@/components/atoms'
import { KBLayout } from '@/components/atoms/KBLayout'
import { KBLanguageDropdown } from '@/components/organisms'

const AuthLayout: React.FC = () => {
  return (
    <KBLayout>
      <KBLayout.Header className="tw-bg-[var(--wox-color-bg-layout)] tw-text-right">
        <KBLanguageDropdown />
      </KBLayout.Header>
      <KBLayout.Content className="tw-flex tw-h-[calc(100vh-64px)] tw-items-center tw-justify-center tw-px-2.5">
        <KBFlex className="tw-w-full" justify="center" align="center">
          <Outlet />
        </KBFlex>
      </KBLayout.Content>
    </KBLayout>
  )
}

export default AuthLayout

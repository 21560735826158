// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceTypeFindManyQueryResponse, ResourceTypeFindManyQueryParams, ResourceTypeFindMany401 } from "../models/ResourceTypeFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceTypeFindManyClient = typeof client<ResourceTypeFindManyQueryResponse, ResourceTypeFindMany401, never>;
type ResourceTypeFindMany = {
    data: ResourceTypeFindManyQueryResponse;
    error: ResourceTypeFindMany401;
    request: never;
    pathParams: never;
    queryParams: ResourceTypeFindManyQueryParams;
    headerParams: never;
    response: ResourceTypeFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceTypeFindManyClient>[0]>;
        return: Awaited<ReturnType<ResourceTypeFindManyClient>>;
    };
};
export const resourceTypeFindManyQueryKey = (params?: ResourceTypeFindMany["queryParams"]) => ["api", "v5", "resource-types", ...(params ? [params] : [])] as const;
export type ResourceTypeFindManyQueryKey = ReturnType<typeof resourceTypeFindManyQueryKey>;
export function resourceTypeFindManyQueryOptions(params?: ResourceTypeFindMany["queryParams"], options: ResourceTypeFindMany["client"]["parameters"] = {}) {
    const queryKey = resourceTypeFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceTypeFindMany["data"], ResourceTypeFindMany["error"]>({
                method: "get",
                url: `/api/v5/resource-types`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceTypeCustomFieldsEntity with query
 * @link /api/v5/resource-types
 */
export function useResourceTypeFindMany<TData = ResourceTypeFindMany["response"], TQueryData = ResourceTypeFindMany["response"], TQueryKey extends QueryKey = ResourceTypeFindManyQueryKey>(params?: ResourceTypeFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceTypeFindMany["response"], ResourceTypeFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceTypeFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceTypeFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceTypeFindManyQueryKey(params);
    const query = useQuery({
        ...resourceTypeFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceTypeFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceTypeFindManySuspenseQueryKey = (params?: ResourceTypeFindMany["queryParams"]) => ["api", "v5", "resource-types", ...(params ? [params] : [])] as const;
export type ResourceTypeFindManySuspenseQueryKey = ReturnType<typeof resourceTypeFindManySuspenseQueryKey>;
export function resourceTypeFindManySuspenseQueryOptions(params?: ResourceTypeFindMany["queryParams"], options: ResourceTypeFindMany["client"]["parameters"] = {}) {
    const queryKey = resourceTypeFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceTypeFindMany["data"], ResourceTypeFindMany["error"]>({
                method: "get",
                url: `/api/v5/resource-types`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceTypeCustomFieldsEntity with query
 * @link /api/v5/resource-types
 */
export function useResourceTypeFindManySuspense<TData = ResourceTypeFindMany["response"], TQueryKey extends QueryKey = ResourceTypeFindManySuspenseQueryKey>(params?: ResourceTypeFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceTypeFindMany["response"], ResourceTypeFindMany["error"], TData, TQueryKey>>;
    client?: ResourceTypeFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceTypeFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceTypeFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceTypeFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceTypeFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
// const loadImage = (locale: string) => {
//   // switch (locale) {
//   //   case 'en-US':
//   //     return import('@/assets/web/en-US.png')
//   //   case 'zh-CN':
//   //     return import('@/assets/web/zh-CN.png')
//   //   case 'zh-TW':
//   //     return import('@/assets/web/zh-TW.png')
//   //   default:
//   //     return import('@/assets/web/en-US.png')
//   // }
// }

const loadAntdLocale = async (locale: string) => {
  switch (locale) {
    case 'en-US':
      return await import('antd/locale/en_US')
    case 'zh-CN':
      return await import('antd/locale/zh_CN')
    case 'zh-TW':
      return await import('antd/locale/zh_TW')
    case 'pt-BR':
      return await import('antd/locale/pt_BR')
    case 'es-LA':
      return await import('antd/locale/es_ES')
    default:
      return await import('antd/locale/en_US')
  }
}

const loadDayjsLocale = async (locale: string) => {
  // switch (locale) {
  //   case 'en-US':
  //     return await import('dayjs/locale/en')
  //   case 'zh-CN':
  //     return await import('dayjs/locale/zh-cn')
  //   case 'zh-TW':
  //     return await import('dayjs/locale/zh-tw')
  //   case 'pt-BR':
  //     return await import('dayjs/locale/pt-br')
  //   case 'es-LA':
  //     return await import('dayjs/locale/es')
  //   default:
  //     return await import('dayjs/locale/en')
  // }
  switch (locale) {
    case 'en-US':
      return 'en'
    case 'zh-CN':
      return 'zh-cn'
    case 'zh-TW':
      return 'zh-tw'
    case 'pt-BR':
      return 'pt-br'
    case 'es-LA':
      return 'es'
    default:
      return 'en'
  }
}

const loadIntlLocale = async (locale: string) => {
  // console.log('loadIntlLocale', locale)
  let localeData
  switch (locale) {
    case 'en-US':
      localeData = await import('../../locales/en-US.json')
      break
    case 'zh-CN':
      localeData = await import('../../locales/zh-CN.json')
      break
    case 'zh-TW':
      localeData = await import('../../locales/zh-TW.json')
      break
    case 'es-LA':
      localeData = await import('../../locales/es-LA.json')
      break
    case 'pt-BR':
      localeData = await import('../../locales/pt-BR.json')
      break
    default:
      localeData = await import('../../locales/en-US.json')
  }
  // console.log(`loadIntl Locale: ${locale}`, localeData.default)
  return localeData
}

export interface ILocale {
  name: string
  value: string
  // logo: any
  // eslint-disable-next-line typescriptEslint/no-explicit-any
  antD: () => Promise<any>
  // eslint-disable-next-line typescriptEslint/no-explicit-any
  dayjs: () => Promise<any>
  // eslint-disable-next-line typescriptEslint/no-explicit-any
  intl: () => Promise<any>
}

export const SUPPORTED_LOCALES: ILocale[] = [
  {
    name: 'English',
    value: 'en-US',
    // logo: loadImage('en-US'),
    antD: () => loadAntdLocale('en-US'),
    dayjs: () => loadDayjsLocale('en-US'),
    intl: () => loadIntlLocale('en-US'),
  },
  {
    name: '简体中文',
    value: 'zh-CN',
    // logo: loadImage('zh-CN'),
    antD: () => loadAntdLocale('zh-CN'),
    dayjs: () => loadDayjsLocale('zh-CN'),
    intl: () => loadIntlLocale('zh-CN'),
  },
  {
    name: '繁體中文',
    value: 'zh-TW',
    // logo: loadImage('zh-TW'),
    antD: () => loadAntdLocale('zh-TW'),
    dayjs: () => loadDayjsLocale('zh-TW'),
    intl: () => loadIntlLocale('zh-TW'),
  },
  {
    name: 'Português do brasil',
    value: 'pt-BR',
    // logo: loadImage('en-US'), // Assuming same image is used, change if different
    antD: () => loadAntdLocale('pt-BR'),
    dayjs: () => loadDayjsLocale('pt-BR'),
    intl: () => loadIntlLocale('pt-BR'), // Assuming not available, change if different
  },
  {
    name: 'Español latinoamericano',
    value: 'es-LA',
    // logo: loadImage('en-US'), // Assuming same image is used, change if different
    antD: () => loadAntdLocale('es-LA'),
    dayjs: () => loadDayjsLocale('es-LA'),
    intl: () => loadIntlLocale('es-LA'), // Assuming not available, change if different
  },
]

import { DatePicker, DatePickerProps } from 'antd'

import { cn } from '@/utils/tailwind.helper'

export interface KBDatePickerProps extends DatePickerProps {}

export function KBDatePicker(props: KBDatePickerProps) {
  const { size = 'large', className, ...others } = props
  return (
    <DatePicker
      size={size}
      data-testid="date-picker"
      inputReadOnly // Prevent keyboard input
      {...others}
      className={cn(size === 'large' && 'tw-h-9', 'kb-date-picker', className)}
    />
  )
}

KBDatePicker.RangePicker = DatePicker.RangePicker

// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthResetPasswordMutationRequest, AuthResetPasswordMutationResponse } from "../models/AuthResetPassword";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthResetPasswordClient = typeof client<AuthResetPasswordMutationResponse, never, AuthResetPasswordMutationRequest>;
type AuthResetPassword = {
    data: AuthResetPasswordMutationResponse;
    error: never;
    request: AuthResetPasswordMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthResetPasswordMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthResetPasswordClient>[0]>;
        return: Awaited<ReturnType<AuthResetPasswordClient>>;
    };
};
/**
 * @summary 重置密码，发送重置邮件
 * @link /api/v5/auth/email/reset-password
 */
export function useAuthResetPassword(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthResetPassword["response"], AuthResetPassword["error"], {
        data?: AuthResetPassword["request"];
    }>;
    client?: AuthResetPassword["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthResetPassword["data"], AuthResetPassword["error"], AuthResetPassword["request"]>({
                method: "post",
                url: `/api/v5/auth/email/reset-password`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth", "email"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
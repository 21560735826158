// @ts-nocheck
import React from 'react'

import {
  SimpleResourceTypeEntity,
  useResourceTypeAdminFindMany,
  useResourceTypeFindMany,
} from '@/api'
import { KBSelect, KBSelectProps } from '@/components/atoms'
import { ALL_OPTIONS } from '@/components/utils'
import { isUserClient } from '@/utils/userEnvironment'

interface Option {
  value: number
  label: string
  model?: SimpleResourceTypeEntity
}

interface KBResourceTypeSelectProps extends KBSelectProps {
  /**
   * If true, the component will show an "All" option
   */
  includeAllOption?: boolean
}

export const KBResourceTypeSelect: React.FC<KBResourceTypeSelectProps> = (
  props
) => {
  const { ...restProps } = props
  const [options, setOptions] = useState<Option[]>([])

  const { data, isPending } = isUserClient()
    ? useResourceTypeFindMany({})
    : useResourceTypeAdminFindMany({})

  useEffect(() => {
    const _options = (data?.result || [])?.map(
      (resourceType: SimpleResourceTypeEntity) => ({
        value: resourceType.id!,
        label: resourceType.name!,
        model: resourceType,
      })
    )
    if (props.includeAllOption) {
      _options.unshift({
        value: ALL_OPTIONS,
        label: tr('all.resourceTypes'),
        model: undefined,
      })
    }
    setOptions(_options)
  }, [data])

  return (
    <KBSelect
      style={{ width: 200 }}
      {...restProps}
      loading={isPending}
      options={options}
      onChange={(value, option) => {
        if (!value || value === ALL_OPTIONS) {
          props.onChange?.(undefined, option)
          return
        }
        const resourceTypeId = parseInt(value)
        props.onChange?.(resourceTypeId, option)
      }}
    />
  )
}

export default KBResourceTypeSelect

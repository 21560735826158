import { KBFlex } from '@/components/atoms/KBFlex'
import { KBIcon } from '@/components/atoms/KBIcon'
import { Image } from 'antd'
import { FC } from 'react'

interface ImagePreviewProps {
  url: string
  previewWidth: number
  previewHeight: number
  onRemove?: () => void
}

const KBImagePreview: FC<ImagePreviewProps> = (props) => {
  const { url, previewWidth, previewHeight, onRemove } = props
  const [isPreviewVisible, setIsPreviewVisible] = useState(false)

  const handlePreview = (value: boolean) => {
    setIsPreviewVisible(value)
  }

  return (
    <KBFlex
      style={{
        display: 'inline-block',
        width: `${previewWidth}px`,
        height: `${previewHeight}px`,
        position: 'relative',
      }}
    >
      <Image
        src={url}
        alt="uploaded"
        preview={{
          visible: isPreviewVisible,
          // onVisibleChange: handlePreview,
          onVisibleChange: handlePreview,
        }}
        style={{
          width: `${previewWidth}px`,
          height: `${previewHeight}px`,
          objectFit: 'cover',
          border: 'none', // Remove border
          borderRadius: '6px',
        }}
      />
      {
        // FIXME: css
        <div
          className="upload-hover-icons"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${previewWidth}px`,
            height: `${previewHeight}px`,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
            transition: 'opacity 0.3s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <KBIcon
            name="eye"
            style={{ color: '#fff', fontSize: 24 }}
            onClick={() => {
              setIsPreviewVisible(true)
            }}
          />
          <KBIcon
            name="trash"
            style={{ color: '#fff', fontSize: 24, marginLeft: 8 }}
            onClick={() => {
              onRemove?.()
            }}
          />
        </div>
      }
    </KBFlex>
  )
}

export default KBImagePreview

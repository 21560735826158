const AuthFailure = () => {
  // get error from query params
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error') || 'unknown'
  return (
    <div>
      <h1>{tr('auth.failure.title')}</h1>
      <p>{tr('auth.failure.description', { error })}</p>
      <a href="/login">{tr('auth.failure.link')}</a>
    </div>
  )
}

export default AuthFailure

import { cn } from '@/utils/tailwind.helper'
import { Tag, TagProps } from 'antd'
import { forwardRef } from 'react'

export interface KBTagProps extends TagProps {
  size?: 'default' | 'small' | 'large'
}

const KBTag = forwardRef<HTMLSpanElement, KBTagProps>((props, ref) => {
  const { size = 'default', className, ...otherProps } = props
  return (
    <Tag
      ref={ref}
      className={cn(`kb-tag-size-${size}`, className)}
      {...otherProps}
    />
  )
})

KBTag.displayName = 'KBTag'

export { KBTag }

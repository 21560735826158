import {
  ReservationShowEntity,
  ResourceEntity,
  TReservationPrivacyRule,
} from '@/api'
import {
  KBCheckbox,
  KBFlex,
  KBForm,
  KBIcon,
  KBSpace,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import {
  FormValueProps,
  GroupEditType,
} from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { KBUserSelect } from '@/components/organisms'

/**
 * 选择主持人
 */
interface HostSelectProps {
  resourcesList: ResourceEntity[]
  reservationInfo?: ReservationShowEntity
  value?: {
    hostId?: number
    isPrivate?: boolean
  }
  errorsMessages?: string
  groupEditType?: GroupEditType
  onChange?: (value: { hostId?: number; isPrivate?: boolean }) => void
}

const HostAndPrivate: React.FC<HostSelectProps> = (props) => {
  const {
    value,
    onChange,
    resourcesList = [],
    reservationInfo,
    groupEditType,
  } = props

  const showPrivate = resourcesList.some(
    (i) =>
      (i.reservation_security_rule?.rules as TReservationPrivacyRule)
        ?.allow_private
  )

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return (
    <>
      <KBFlex align="start" gap={6}>
        <KBFlex vertical gap={8} className="tw-w-full">
          <KBUserSelect
            className="tw-w-full"
            value={value?.hostId}
            onChange={(userId: number) => {
              onChange?.({
                ...value,
                hostId: userId,
              })
            }}
          />
          {showPrivate && (
            <KBFlex align="center" gap={6}>
              <KBCheckbox
                checked={value?.isPrivate}
                disabled={disabled}
                onChange={(e) => {
                  onChange?.({
                    ...value,
                    isPrivate: e.target.checked,
                  })
                }}
              />
              {/* FIXME需要根据隐私级别设置tooltip内容，多资源级别冲突怎么展示 */}
              <KBTooltip title={tr('[intl]私密预定向非参会人隐藏预定信息')}>
                <KBSpace size={4} align="center">
                  <KBText>{tr('[intl]将此次预定设置为私密会议')}</KBText>
                  <KBIcon name="info" className=" tw-block" />
                </KBSpace>
              </KBTooltip>
            </KBFlex>
          )}
        </KBFlex>
      </KBFlex>
    </>
  )
}

/**
 * 选择预订人以及是否私密预定
 */
const HostSelect: React.FC<HostSelectProps> = (props) => {
  const {
    resourcesList,
    reservationInfo,
    value,
    errorsMessages,
    ...restProps
  } = props
  const [validateMessage, setValidateMessage] = useState('')
  /**
   * 主持人和私密预定校验
   */
  const validateHost = (host: FormValueProps['host']) => {
    if (!host?.hostId) {
      setValidateMessage(tr('[intl]请选择主持人'))
    } else {
      setValidateMessage('')
    }
    return Promise.resolve()
    // if (!host?.hostId) {
    //   return Promise.reject(new Error(tr('[intl]请选择主持人')))
    // } else if (host.isPrivate) {
    //   // 检测目前的资源是否允许选择私密预定
    //   const cannotSelectPrivateResources = resourcesList.filter(
    //     (i) =>
    //       !(i.reservation_security_rule?.rules as TReservationPrivacyRule)
    //         ?.allow_private
    //   )

    //   if (cannotSelectPrivateResources?.length) {
    //     return Promise.reject(
    //       new Error(
    //         tr(
    //           `${cannotSelectPrivateResources.map((i) => i.name).join('、')}${tr('[intl]不允许私密预定')}`
    //         )
    //       )
    //     )
    //   } else {
    //     return Promise.resolve()
    //   }
    // } else {
    //   return Promise.resolve()
    // }
  }

  const errors = useMemo(() => {
    if (validateMessage) {
      return validateMessage
    }
    return errorsMessages
  }, [validateMessage, errorsMessages])

  return (
    <KBForm.Item
      name="host"
      label={<KBIcon name="circle-user-round" size={24} />}
      dependencies={['resourceIds']}
      // rules={[
      //   {
      //     validator: (_, host) => validateHost(host),
      //   },
      // ]}
      rules={[
        {
          validator: (_, host) => validateHost(host),
        },
      ]}
      validateStatus={errors ? 'error' : undefined}
      help={errors}
    >
      <HostAndPrivate
        resourcesList={resourcesList}
        reservationInfo={reservationInfo}
        {...restProps}
      />
    </KBForm.Item>
  )
}

export default HostSelect

// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceCategoryAdminIndexQueryResponse, ResourceCategoryAdminIndexQueryParams, ResourceCategoryAdminIndex401 } from "../models/ResourceCategoryAdminIndex";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceCategoryAdminIndexClient = typeof client<ResourceCategoryAdminIndexQueryResponse, ResourceCategoryAdminIndex401, never>;
type ResourceCategoryAdminIndex = {
    data: ResourceCategoryAdminIndexQueryResponse;
    error: ResourceCategoryAdminIndex401;
    request: never;
    pathParams: never;
    queryParams: ResourceCategoryAdminIndexQueryParams;
    headerParams: never;
    response: ResourceCategoryAdminIndexQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceCategoryAdminIndexClient>[0]>;
        return: Awaited<ReturnType<ResourceCategoryAdminIndexClient>>;
    };
};
export const resourceCategoryAdminIndexQueryKey = (params?: ResourceCategoryAdminIndex["queryParams"]) => ["api", "v5", "admin", "resource-categories", ...(params ? [params] : [])] as const;
export type ResourceCategoryAdminIndexQueryKey = ReturnType<typeof resourceCategoryAdminIndexQueryKey>;
export function resourceCategoryAdminIndexQueryOptions(params?: ResourceCategoryAdminIndex["queryParams"], options: ResourceCategoryAdminIndex["client"]["parameters"] = {}) {
    const queryKey = resourceCategoryAdminIndexQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceCategoryAdminIndex["data"], ResourceCategoryAdminIndex["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-categories`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceCategoryEntity with query
 * @link /api/v5/admin/resource-categories
 */
export function useResourceCategoryAdminIndex<TData = ResourceCategoryAdminIndex["response"], TQueryData = ResourceCategoryAdminIndex["response"], TQueryKey extends QueryKey = ResourceCategoryAdminIndexQueryKey>(params?: ResourceCategoryAdminIndex["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceCategoryAdminIndex["response"], ResourceCategoryAdminIndex["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceCategoryAdminIndex["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceCategoryAdminIndex["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceCategoryAdminIndexQueryKey(params);
    const query = useQuery({
        ...resourceCategoryAdminIndexQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceCategoryAdminIndex["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceCategoryAdminIndexSuspenseQueryKey = (params?: ResourceCategoryAdminIndex["queryParams"]) => ["api", "v5", "admin", "resource-categories", ...(params ? [params] : [])] as const;
export type ResourceCategoryAdminIndexSuspenseQueryKey = ReturnType<typeof resourceCategoryAdminIndexSuspenseQueryKey>;
export function resourceCategoryAdminIndexSuspenseQueryOptions(params?: ResourceCategoryAdminIndex["queryParams"], options: ResourceCategoryAdminIndex["client"]["parameters"] = {}) {
    const queryKey = resourceCategoryAdminIndexSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceCategoryAdminIndex["data"], ResourceCategoryAdminIndex["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-categories`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many ResourceCategoryEntity with query
 * @link /api/v5/admin/resource-categories
 */
export function useResourceCategoryAdminIndexSuspense<TData = ResourceCategoryAdminIndex["response"], TQueryKey extends QueryKey = ResourceCategoryAdminIndexSuspenseQueryKey>(params?: ResourceCategoryAdminIndex["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceCategoryAdminIndex["response"], ResourceCategoryAdminIndex["error"], TData, TQueryKey>>;
    client?: ResourceCategoryAdminIndex["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceCategoryAdminIndex["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceCategoryAdminIndexSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceCategoryAdminIndexSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceCategoryAdminIndex["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
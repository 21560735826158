// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthFirstLoginMutationRequest, AuthFirstLoginMutationResponse } from "../models/AuthFirstLogin";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthFirstLoginClient = typeof client<AuthFirstLoginMutationResponse, never, AuthFirstLoginMutationRequest>;
type AuthFirstLogin = {
    data: AuthFirstLoginMutationResponse;
    error: never;
    request: AuthFirstLoginMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthFirstLoginMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthFirstLoginClient>[0]>;
        return: Awaited<ReturnType<AuthFirstLoginClient>>;
    };
};
/**
 * @summary 用户第一次登录，发送验证邮件，用于用户设置密码
 * @link /api/v5/auth/email/first-login
 */
export function useAuthFirstLogin(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthFirstLogin["response"], AuthFirstLogin["error"], {
        data?: AuthFirstLogin["request"];
    }>;
    client?: AuthFirstLogin["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthFirstLogin["data"], AuthFirstLogin["error"], AuthFirstLogin["request"]>({
                method: "post",
                url: `/api/v5/auth/email/first-login`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth", "email"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}
// @ts-nocheck
import React, { useEffect } from 'react'

import {
  SimpleResourceCategoryEntity,
  useResourceCategoryAdminIndex,
  useResourceCategoryFindMany,
} from '@/api'
import { KBSelect, KBSelectProps } from '@/components/atoms'
import { ALL_OPTIONS } from '@/components/utils'
import { MAX_PAGINATION_LIMIT } from '@/utils/constants'
import { isUserClient } from '@/utils/userEnvironment'

interface Option {
  value: string
  label: string
  model?: SimpleResourceCategoryEntity
}

interface KBResourceCategorySelectProps extends KBSelectProps {
  resourceTypeId?: number
  /**
   * If true, the component will show an "All" option
   */
  includeAllOption?: boolean
}

export const KBResourceCategorySelect: React.FC<
  KBResourceCategorySelectProps
> = (props) => {
  const { resourceTypeId, ...restProps } = props
  const [options, setOptions] = useState<Option[]>([])

  const { data, isPending } = isUserClient()
    ? useResourceCategoryFindMany({
        resource_type_id: resourceTypeId,
        limit: MAX_PAGINATION_LIMIT,
      })
    : useResourceCategoryAdminIndex({
        resource_type_id: resourceTypeId,
        limit: MAX_PAGINATION_LIMIT,
      })

  useEffect(() => {
    const _options = (data?.result || [])?.map(
      (resource: SimpleResourceCategoryEntity) => ({
        value: resource.id!, //String(resource.id!),
        label: resource.name!,
        model: resource,
      })
    )
    if (props.includeAllOption) {
      _options.unshift({
        value: ALL_OPTIONS,
        label: tr('all.resourceCategories'),
        model: undefined,
      })
    }
    setOptions(_options)
  }, [data])

  return (
    <KBSelect
      style={{ width: 200 }}
      {...restProps}
      loading={isPending}
      options={options}
      onChange={(value, option) => {
        if (!value || value === ALL_OPTIONS) {
          props.onChange?.(undefined, option)
          return
        }
        const resourceCategoryId = parseInt(value)
        props.onChange?.(resourceCategoryId, option)
      }}
    />
  )
}

export default KBResourceCategorySelect

// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceIndexQueryResponse, ResourceIndexQueryParams, ResourceIndex401 } from "../models/ResourceIndex";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceIndexClient = typeof client<ResourceIndexQueryResponse, ResourceIndex401, never>;
type ResourceIndex = {
    data: ResourceIndexQueryResponse;
    error: ResourceIndex401;
    request: never;
    pathParams: never;
    queryParams: ResourceIndexQueryParams;
    headerParams: never;
    response: ResourceIndexQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceIndexClient>[0]>;
        return: Awaited<ReturnType<ResourceIndexClient>>;
    };
};
export const resourceIndexQueryKey = (params?: ResourceIndex["queryParams"]) => ["api", "v5", "resources", ...(params ? [params] : [])] as const;
export type ResourceIndexQueryKey = ReturnType<typeof resourceIndexQueryKey>;
export function resourceIndexQueryOptions(params?: ResourceIndex["queryParams"], options: ResourceIndex["client"]["parameters"] = {}) {
    const queryKey = resourceIndexQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceIndex["data"], ResourceIndex["error"]>({
                method: "get",
                url: `/api/v5/resources`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取资源分页列表
 * @link /api/v5/resources
 */
export function useResourceIndex<TData = ResourceIndex["response"], TQueryData = ResourceIndex["response"], TQueryKey extends QueryKey = ResourceIndexQueryKey>(params?: ResourceIndex["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceIndex["response"], ResourceIndex["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceIndex["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceIndex["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceIndexQueryKey(params);
    const query = useQuery({
        ...resourceIndexQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceIndex["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceIndexSuspenseQueryKey = (params?: ResourceIndex["queryParams"]) => ["api", "v5", "resources", ...(params ? [params] : [])] as const;
export type ResourceIndexSuspenseQueryKey = ReturnType<typeof resourceIndexSuspenseQueryKey>;
export function resourceIndexSuspenseQueryOptions(params?: ResourceIndex["queryParams"], options: ResourceIndex["client"]["parameters"] = {}) {
    const queryKey = resourceIndexSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceIndex["data"], ResourceIndex["error"]>({
                method: "get",
                url: `/api/v5/resources`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取资源分页列表
 * @link /api/v5/resources
 */
export function useResourceIndexSuspense<TData = ResourceIndex["response"], TQueryKey extends QueryKey = ResourceIndexSuspenseQueryKey>(params?: ResourceIndex["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceIndex["response"], ResourceIndex["error"], TData, TQueryKey>>;
    client?: ResourceIndex["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceIndex["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceIndexSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceIndexSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceIndex["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}